import React, { useEffect, FC, useState, SyntheticEvent, useRef } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import { orders } from "../../../actions/orders";
import helpFunctions from "../../../tools/helpFunctions";
import { ordersItemType } from "../../../types/actions/user";
import { columnListType } from "../../../types/actions/user";
import { sortColumnType } from "../../../types/actions/generalCRUD";
import { columnListInitial } from "../../../redux/reducers/user";

// Components
import MainTemplate from "../templates/MainTemplate";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import Button from "@material-ui/core/Button";
import InputMask from "react-input-mask";
import CustomPagination from "../../organisms/CustomPagination";
import Paper from "@material-ui/core/Paper/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import Popper from "@material-ui/core/Popper/Popper";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

// Styles
import "./style.scss";

type ordersType = {
  orderColumnSettings: columnListType;
  page: number;
  maxPage: number;
  sortedColumn: sortColumnType;
  quantityPerPage: number;
};

const OrdersContainer: FC<any> = (props: ordersType) => {
  const history = useHistory();
  const [ordersList, setOrdersList] = useState<Array<ordersItemType>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
  const [columnList, setColumnList] = useState<columnListType>(columnListInitial);

  // to get all entities
  useEffect(() => {
    getAllOrders();
  }, []);

  // to update columns of table
  useEffect(() => {
    if (props.orderColumnSettings) {
      setColumnList({ ...columnListInitial, ...props.orderColumnSettings });
    }
  }, [props.orderColumnSettings]);

  const getAllOrders = async (
    page: number = props.page,
    sort: sortColumnType = props.sortedColumn,
    quantity = props.quantityPerPage,
  ) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("request/booking", page, sort, quantity);
    if (response.code === 200) {
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllOrders(maxPage, sort, quantity);
      orders.setOrdersPage(response.data.currentPageNumber, maxPage);
      setOrdersList(response.data.items);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const searchTextHandler = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setSearchText(value);
  };

  const searchHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!searchText) return false;
    setIsLoading(true);
    const response = await orders.searchOrder(searchText.replace(/- /g, ""));
    if (response.code === 200 && response.data) {
      orders.saveOrderData(response.data);
      history.push(`/orders/${response.data.id}`);
    } else {
      toastr.error("Error", response.message);
      setIsLoading(false);
    }
  };

  const defineThClassName = (columnName: string): string => {
    if (props.sortedColumn.name === columnName) {
      return props.sortedColumn.type === "asc" ? "sort-icons active-desc" : "sort-icons active-asc";
    }
    return "sort-icons";
  };

  const setCircleClassName = (status: number) => {
    return `th circle ${helpFunctions.defineTextStatus(status).toLowerCase().split(" ").join("")}`;
  };

  const sortOrdersHandler = (name: string) => {
    let type = "asc";
    if (props.sortedColumn.name === name) {
      type = props.sortedColumn.type === "asc" ? "desc" : "asc";
    }
    const sortData = {
      type: type,
      name: name,
    };
    orders.setSortedColumn(sortData);
    getAllOrders(props.page, sortData);
  };

  const buildColumnList = () => {
    const list = [];
    for (const key in columnList) {
      list.push({
        name: key.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase(),
        value: columnList[key],
        serverName: key,
      });
    }
    return list;
  };

  const changeColumnSettings = (value: boolean, name: string) => {
    const colSettingsData = { ...columnList };
    colSettingsData[name] = value;
    setColumnList(colSettingsData);
  };

  const resetColumnSettings = () => {
    setColumnList({ ...columnListInitial, ...props.orderColumnSettings });
    setIsSettingsActive(false);
  };

  const saveColumnSettings = async () => {
    const response = await orders.saveColumnSettings(columnList);
    if (response.code === 200) {
      toastr.success("Fields were saved successfully", "");
      getAllOrders();
    } else {
      toastr.error("Error", response.message);
    }
    setIsSettingsActive(false);
  };

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <MainTemplate>
      <div id="orders" className="page-container">
        <div id="top-part">
          <div className="left-part">
            <div className="title">Orders</div>
            <Button
              className={isSettingsActive ? "columns-button active" : "columns-button"}
              ref={anchorRef}
              aria-controls={isSettingsActive ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => {
                setIsSettingsActive(!isSettingsActive);
              }}
            >
              Column settings
            </Button>

            <Popper
              open={isSettingsActive}
              className="popper-wrapper"
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-start"
              modifiers={{
                flip: {
                  enabled: false,
                },
              }}
            >
              <Paper className="column-settings">
                <ClickAwayListener
                  onClickAway={() => {
                    setIsSettingsActive(false);
                  }}
                >
                  <div className="column-settings-wrap">
                    <p className="title">Choose fields that you need</p>
                    {buildColumnList().map((item, index) => {
                      return (
                        <div className="column-settings-item" key={index}>
                          <FormGroup row>
                            <Typography component="div">
                              <Grid component="label" container alignItems="center">
                                <Grid item>Off</Grid>
                                <Grid item>
                                  <Switch
                                    checked={item.value}
                                    onChange={(event) => changeColumnSettings(event.target.checked, item.serverName)}
                                  />
                                </Grid>
                                <Grid item>On</Grid>
                                <p className="name">{item.name}</p>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </div>
                      );
                    })}
                    <div className="footer">
                      <Button onClick={resetColumnSettings}>Cancel</Button>
                      <Button onClick={saveColumnSettings}>Save</Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
          <div className="search">
            <form onSubmit={(event) => searchHandler(event)}>
              <InputMask
                mask="aaaa - 9999 - 9999"
                maskChar=""
                placeholder="ABCD - 1234 - 5678"
                value={searchText}
                onChange={(event) => searchTextHandler(event)}
              />
            </form>
            <button className="magnifire" onClick={(event) => searchHandler(event)}>
              <div className={searchText ? "magnifire-img active" : "magnifire-img"}>&nbsp;</div>
            </button>
            <div className={searchText ? "clear" : "hidden-block"} onClick={() => setSearchText("")}>
              clear
            </div>
          </div>
        </div>
        <div className="table-thead">
          {columnList.id && (
            <div className="th sortable first" onClick={() => sortOrdersHandler("id")}>
              <div className={defineThClassName("id")}>&nbsp;</div>
              ID
            </div>
          )}
          {columnList.date && (
            <div className="th sortable" onClick={() => sortOrdersHandler("created")}>
              <div className={defineThClassName("created")}>&nbsp;</div>
              Date
            </div>
          )}
          {columnList.user && (
            <div className="th sortable" onClick={() => sortOrdersHandler("username")}>
              <div className={defineThClassName("username")}>&nbsp;</div>
              User
            </div>
          )}
          {columnList.company && (
            <div className="th sortable" onClick={() => sortOrdersHandler("companyName")}>
              <div className={defineThClassName("companyName")}>&nbsp;</div>
              Company
            </div>
          )}
          {columnList.trackingCode && <div className="th">Tracking Code</div>}
          {columnList.event && <div className="th event">Event</div>}
          {columnList.origin && <div className="th">Origin</div>}
          {columnList.destination && <div className="th">Destination</div>}
          {columnList.volume && (
            <div className="th sortable" onClick={() => sortOrdersHandler("shipment_volume")}>
              <div className={defineThClassName("shipment_volume")}>&nbsp;</div>
              Volume
            </div>
          )}
          {columnList.status && (
            <div className="th sortable" onClick={() => sortOrdersHandler("status")}>
              <div className={defineThClassName("status")}>&nbsp;</div>
              Status
            </div>
          )}
          {columnList.lastUpdate && (
            <div className="th sortable" onClick={() => sortOrdersHandler("event_data_update")}>
              <div className={defineThClassName("event_data_update")}>&nbsp;</div>
              Last update
            </div>
          )}
        </div>
        <ul className="table-content">
          {ordersList.map((item: ordersItemType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <Link to={`/orders/${item.id}`}>
                  {columnList.id && <div className="th first">{item.id}</div>}
                  {columnList.date && <div className="th">{helpFunctions.getDateFromUnix(item.created)}</div>}
                  {columnList.user && <div className="th user-name">{item.yourName}</div>}
                  {columnList.company && <div className="th">{item.companyName}</div>}
                  {columnList.trackingCode && <div className="th">{item.hashSum + item.customCode}</div>}
                  {columnList.event && <div className="th event">{item.lastEventName}</div>}
                  {columnList.origin && <div className="th">{item.cityName}</div>}
                  {columnList.destination && <div className="th">{item.cartageName}</div>}
                  {columnList.volume && <div className="th">{item.shipmentVolume}</div>}
                  {columnList.status && (
                    <div className={setCircleClassName(item.status)}>{helpFunctions.defineTextStatus(item.status)}</div>
                  )}
                  {columnList.lastUpdate && (
                    <div className="th">{helpFunctions.getDateFromUnix(item.lastEventTime)}</div>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
        <CustomPagination
          page={props.page}
          maxPage={props.maxPage}
          isLoaded={!isLoading}
          itemsNumber={ordersList.length}
          handleClickPage={(value) => getAllOrders(value)}
          handleClickQuantity={(quantity) => getAllOrders(undefined, undefined, quantity)}
        />
        {isLoading && <LoaderLocal />}
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderColumnSettings: state.user.orderColumnSettings,
    page: state.orders.page,
    maxPage: state.orders.maxPage,
    sortedColumn: state.orders.sortedColumn,
    quantityPerPage: state.app.quantityPerPage,
  };
};

export default connect(mapStateToProps)(withRouter(OrdersContainer));
