import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/app/App';
import { ThemeProvider } from "react-switch-theme";

//import * as serviceWorker from './serviceWorker';

const colors = {
    light: {},
    dark: {}
};

const activeMode = "light";
const offlineStorageKey = "themeStyle";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider
                colors={colors}
                activeMode={activeMode}
                offlineStorageKey={offlineStorageKey}
            >
                <App />
            </ThemeProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
