import React, { useEffect, FC, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { orders } from "../../../actions/orders";
import helpFunctions from "../../../tools/helpFunctions";
import { toastr } from "react-redux-toastr";
import { generalCRUD } from "../../../actions/generalCRUD";

import { orderDataType } from "../../../types/actions/orders";
import { orderDataInitial } from "../../../redux/reducers/orders";
import { eventsItemType, templateOptionType } from "../Events";
import { eventsItemInitial } from "../Events";

// Components
import LoaderLocal from "../../atoms/LoaderLocal";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import GeneralButton from "../../atoms/GeneralButton";
import DatePicker from "../../molecules/DatePicker";
import Button from "@material-ui/core/Button";
import ModalWindow from "../../molecules/ModalWindow";
import ThemeSwitcher from "../../molecules/themeSwitcher";
import Uploader from "../../molecules/Uploader";

// Styles
import "./style.scss";
import { defaultValidationProperties, validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";

let isLoadedFirst = true;

type setEventPropsType = {
  orderData: orderDataType;
};

export type emailTemplateType = {
  id: number | null;
  title: string;
};

export type documentType = {
  id: number | null;
  title: string;
};

export type modalPreviewType = {
  status: boolean;
  content: string;
};

const initialComment: FieldType = {
  ...defaultValidationProperties,
  maxLength: 255,
  nameForError: "Comment",
  dependencies: [{ depCase: "maxLength", attr: { maxLength: 255 } }],
};

type dateValidationType = {
  [key: string]: boolean;
};

export interface IFileType {
  file: Blob | null;
  meta?: IFileMetaType;
  validation?: boolean;
}

interface IFileMetaType {
  name: string;
  size: number;
}

const maxByteSize = 25;

const SetEvent: FC<any> = (props: setEventPropsType) => {
  const history = useHistory();
  const [orderData, setOrderData] = useState<orderDataType>(orderDataInitial);
  const [eventsList, setEventsList] = useState<[eventsItemType]>([eventsItemInitial]);
  const [nextEvent, setNextEvent] = useState<eventsItemType>(eventsItemInitial);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date(Date.now()));
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(new Date(Date.now()));
  const [comment, setComment] = useState<string>("");
  const [emailTemplate, setEmailTemplate] = useState<emailTemplateType>({
    id: null,
    title: "",
  });
  const [templateOptions, setTemplateOptions] = useState<Array<templateOptionType>>([{ id: "", title: "" }]);
  const [modalPreview, setModalPreview] = useState<modalPreviewType>({
    status: false,
    content: "",
  });
  const [documents, setDocuments] = useState<Array<IFileType>>([]);
  const [categoryList, setCategoryList] = useState<Array<any>>([]);
  const [documentsError, setDocumentsError] = useState<string>("");
  const [commentValidation, setCommentValidation] = useState<FieldType>(initialComment);
  const [datesValid, setDatesValid] = useState<dateValidationType>({});

  useEffect(() => {
    setIsLoading(true);
    const promiseOrder = getOrderInfo();
    const promiseEvents = getAllEvents();
    const promiseTemplates = getEmailTemplates();
    const promiseCategories = getCategoryList();
    Promise.all([promiseOrder, promiseEvents, promiseTemplates, promiseCategories]).then(
      (values) => {
        defineDataNextEvent(values[1].items, values[0]);
        isLoadedFirst = false;
        setIsLoading(false);
      },
      (reason) => {
        setIsLoading(false);
        toastr.error("Error", reason);
      },
    );

    return () => {
      isLoadedFirst = true;
    };
  }, []);

  // watch for loading documents and show error
  useEffect(() => {
    switch (documentsError) {
      case "minBytesSize":
        toastr.error("Error", "File cannot be empty");
        break;
      case "maxBytesSize":
        toastr.error("Error", "Maximum limit of weight is exceeded");
        break;
    }
    setDocumentsError("");
  }, [documentsError]);

  const getOrderInfo = async () => {
    const response = await orders.getOrderInfo(+helpFunctions.getParamIdFromUrl());
    if (response.code === 200) {
      setOrderData(response.data);
      return response.data.lastEventId;
    } else {
      document.location.href = "/orders";
      toastr.error("Error", response.message);
    }
  };

  const getAllEvents = async () => {
    const response = await generalCRUD.getAllData("request/event", null, null, 1000);
    if (response.code === 200) {
      setEventsList(response.data.items);
      return response.data;
    }
  };

  const getEmailTemplates = async () => {
    const response = await generalCRUD.getAllData("template/email", null, null, 1000, "&system=false");
    if (response.code === 200) {
      setTemplateOptions(response.data.items);
    }
  };

  const getCategoryList = async () => {
    const response = await generalCRUD.getSimpleList("document/category/get_all");
    if (response.code === 200) {
      setCategoryList(response.data.items);
    }
  };

  const handleDateChange = (date) => {
    if (date == "Invalid Date") {
      setDatesValid((prevState) => ({ ...prevState, dateIsValid: false }));
    } else {
      setDatesValid((prevState) => ({ ...prevState, dateIsValid: true }));
    }
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    if (time == "Invalid Date") {
      setDatesValid((prevState) => ({ ...prevState, timeIsValid: false }));
    } else {
      setDatesValid((prevState) => ({ ...prevState, timeIsValid: true }));
    }
    setSelectedTime(time);
  };

  const defineDataNextEvent = (data: [eventsItemType], id: number) => {
    data.forEach((item: eventsItemType, index: number) => {
      if (item.id === Number(id)) {
        if (isLoadedFirst && data.length !== index + 1) {
          const newData = {
            id: data[index + 1].emailTemplate ? (+data[index + 1].emailTemplate.id as number) : 0,
            title: data[index + 1].emailTemplate ? data[index + 1].emailTemplate.title : "",
          };
          setEmailTemplate(newData);
          return setNextEvent(data[index + 1]);
        }
        const newData = {
          id: item.emailTemplate ? (item.emailTemplate.id as number) : 0,
          title: item.emailTemplate ? item.emailTemplate.title : "",
        };
        setEmailTemplate(newData);
        setNextEvent(item);
      }
    });
  };

  const getDocumentsIndices = (data) => {
    const result = [];
    data.forEach((item) => {
      result.push(Number(item.problem_field.replace(/[^0-9]/g, "")));
    });
    return result;
  };

  const submitEventStatus = async () => {
    setIsLoading(true);

    const formData = new FormData();
    if (documents.length) {
      if (!emailTemplate.title) {
        toastr.error("Error", "Documents can be sent only as addition to the mail");
        setIsLoading(false);
        return;
      }
      documents.forEach((item) => {
        formData.append("files[]", item.file);
      });
    }

    const resultObject = {
      bookingRequestId: Number(orderData.id),
      eventId: nextEvent.id,
      date: helpFunctions.countTimeStamp(selectedDate, selectedTime).toString(),
      comment: comment,
      emailTemplateId: +emailTemplate.id ? emailTemplate.id : null,
      documents: [],
    };
    let result = true;
    for (const key in datesValid) {
      if (datesValid[key] === false) {
        result = false;
        break;
      }
    }

    if (validateAllFields([{ validate: commentValidation, setValidate: setCommentValidation }]) && result) {
      if (documents.length) {
        const result = await orders.uploadFiles(categoryList[0].id, formData);
        if (result.code === 200) {
          resultObject.documents = result.data.items;
          return sendRequestEventStatus(resultObject);
        } else if (result.code === 400) {
          const indices = getDocumentsIndices(result.data);
          const tempDocuments = documents;
          indices.forEach((itemIndex) => {
            tempDocuments[itemIndex].validation = false;
          });
          setDocuments(tempDocuments);
          toastr.error("Error", result.data[0].message);
        } else {
          toastr.error("Error", result.message);
        }
      } else {
        return sendRequestEventStatus(resultObject);
      }
    }
    setIsLoading(false);
  };

  const sendRequestEventStatus = async (resultObject) => {
    const response = await orders.setNextEventStatus(resultObject);
    if (response.code === 200) {
      toastr.success("New Event status was set successfully", "");
      history.push(`/orders/${orderData.id}`);
    } else {
      setIsLoading(false);
      toastr.error("Error", response.message);
    }
  };

  const cancelChangeStatus = () => {
    history.push(`/orders/${orderData.id}`);
  };

  const nextEventHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    defineDataNextEvent(eventsList, event.target.value as number);
  };

  const templateHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    let title = "";
    templateOptions.forEach((item) => {
      if (item.id === +event.target.value) return (title = item.title);
    });
    const newData = {
      id: +event.target.value as number,
      title: title,
    };
    setEmailTemplate(newData);
  };

  const openPreview = async () => {
    setModalPreview({
      status: true,
      content: "",
    });
    const response = await orders.getPreviewContent(emailTemplate.id, orderData.id);
    if (response.status === 200) {
      setModalPreview({
        status: true,
        content: response.data,
      });
    } else {
      toastr.error("Error", response.message);
    }
  };

  const removeDocument = (index: number) => {
    const newArr = [...documents];
    newArr.splice(index, 1);
    setDocuments(newArr);
  };

  return (
    <div id="set-event">
      <div className="content">
        <div className="half">
          <div className="content-item">
            <p className="title">Current event:</p>
            <p className="text">{orderData.lastEventName}</p>
          </div>
          <div className="content-item">
            <p className="title">Public status:</p>
            <p className="text">{orderData.lastEventPublicStatus}</p>
          </div>
          <div className="content-item">
            <p className="title text">Next event:</p>
            <FormControl className="general-select">
              <Select value={nextEvent.id} onChange={nextEventHandler}>
                {eventsList.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="content-item">
            <p className="title">Public status:</p>
            <p className="text">{nextEvent.publicStatus}</p>
          </div>
          <div className="content-item">
            <DatePicker
              title="Set time"
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              handleDateChange={handleDateChange}
              handleTimeChange={handleTimeChange}
            />
          </div>
        </div>
        <div className="half">
          <div className="content-item">
            <p className="title">Send mail:</p>
            <FormControl className="general-select">
              <Select value={emailTemplate.id ? emailTemplate.id : "0"} onChange={templateHandler}>
                <MenuItem className="no-value" value={"0"}>
                  Mail won&#39;t be sent
                </MenuItem>
                {templateOptions.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <p className="notion">Mail will be sent to customer`s mailbox that was entered in registration</p>
            {!!Number(emailTemplate.id) && (
              <Button className="preview-button" onClick={openPreview}>
                Preview
              </Button>
            )}
          </div>
          <div className="content-item doc">
            <p className="title">Add document to the mail:</p>
            <p className="notion">You can load next formats:</p>
            <p className="notion">*.pdf, *.doc, *.docx, *.xls, *.xlsx, *.jpg, *.jpeg, *.png, *.bmp and *.tiff</p>
            <p className="notion">Maximum total weight of the files cannot exceed 7 MB per submission</p>
            <Uploader
              files={documents}
              setter={setDocuments}
              deleteFile={removeDocument}
              setterError={setDocumentsError}
              maxByteSize={maxByteSize}
              disabled={!emailTemplate.id}
            />
          </div>
          <div className="content-item">
            <p className="title">Add comment:</p>
            <TextField
              helperText={commentValidation.error}
              error={commentValidation.valid === false}
              inputProps={{ maxLength: commentValidation.maxLength }}
              value={comment}
              classes={{ root: "comment" }}
              multiline
              rows={3}
              InputProps={{ disableUnderline: true }}
              rowsMax={6}
              placeholder="Your comment to event"
              onChange={(event) => setComment(event.target.value)}
              onBlur={(e) => validateField(e.target.value, commentValidation, setCommentValidation)}
            />
          </div>
        </div>
      </div>
      <div className="actions">
        <GeneralButton classes="cancel" handleClick={cancelChangeStatus}>
          Cancel
        </GeneralButton>
        <GeneralButton classes="submit" handleClick={submitEventStatus}>
          Set event
        </GeneralButton>
      </div>
      {isLoading && <LoaderLocal />}
      <ModalWindow
        classes="preview"
        open={modalPreview.status}
        handleClose={() =>
          setModalPreview({
            status: false,
            content: "",
          })
        }
        title="Preview"
      >
        {!modalPreview.content ? (
          <LoaderLocal />
        ) : (
          <iframe srcDoc={modalPreview.content} width={670} height={500} className="preview-frame">
            Your browser does not support inline frames!
          </iframe>
        )}
      </ModalWindow>
      <div className="hide-switcher">
        <ThemeSwitcher />
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(withRouter(SetEvent));
