import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import { eventsItemType } from "../Events";

// Components
import MainTemplate from "../templates/MainTemplate";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toastr } from "react-redux-toastr";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CustomPagination from "../../organisms/CustomPagination";

// Styles
import "./style.scss";

import { rootReducerType } from "../../../redux/reducers";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";

export const initialMail = {
  id: null,
  title: "",
  events: [] as Array<eventsItemType>,
  content: "",
  subject: "",
  system: false,
  updatedAt: "",
};

export const initialHtmlBlock = {
  id: null,
  title: "",
  content: "",
  identifier: "",
  updatedAt: "",
};

export const initialVariable = {
  id: null,
  name: "",
  identifier: "",
  description: "",
};

export type MailType = typeof initialMail;
export type HtmlBlockType = typeof initialHtmlBlock;
export type VariableType = typeof initialVariable;

let paginationSettingsObject = {
  mails: {
    page: 1,
    maxPage: 1,
  },
  htmlBlocks: {
    page: 1,
    maxPage: 1,
  },
  variables: {
    page: 1,
    maxPage: 1,
  },
};

const TemplatesContainer: FC = () => {
  const history = useHistory();
  const storeActiveTab = useSelector((state: rootReducerType) => state.app.activeTabTemplate);
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(storeActiveTab);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteMailId, setDeleteMailId] = useState<number>(0);
  const [copyMailId, setCopyMailId] = useState<number>(0);
  const [deleteHtmlBlockId, setDeleteHtmlBlockId] = useState<number>(0);
  const [mailList, setMailList] = useState<Array<MailType>>([]);
  const [htmlBlockList, setHtmlBlockList] = useState<Array<HtmlBlockType>>([]);
  const [variableList, setVariableList] = useState<Array<VariableType>>([]);
  const [anchorElMail, setAnchorElMail] = React.useState<Array<null | HTMLElement>>([null]);
  const [anchorElBlock, setAnchorElBlock] = React.useState<Array<null | HTMLElement>>([null]);
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [paginationSettings, setPaginationSettings] = useState(paginationSettingsObject);
  const [editVariableModal, setEditVariableModal] = useState(null);

  /*Validation*/
  const [descValid, setDescValid] = useState<FieldType>({
    ...maxLengthInstance(100, false),
    value: editVariableModal ? editVariableModal.data.description : "",
  });

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    data: Array<null | HTMLElement>,
    setter,
  ) => {
    const anch = [...data];
    anch[index] = event.currentTarget;
    setter(anch);
  };

  const handleAccordion = (id: number) => {
    if (id === expandedId) {
      return setExpandedId(null);
    }
    setExpandedId(id);
  };

  useEffect(() => {
    setIsLoading(true);
    const mailRes = getAllData("template/email", setMailList, 1, true);
    const htmlRes = getAllData("template/block", setHtmlBlockList, 1, true);
    const varRes = getAllData("template/variable", setVariableList, 1, true);
    Promise.all([mailRes, htmlRes, varRes]).then(() => {
      setPaginationSettings(paginationSettingsObject);
      setIsLoading(false);
    });

    return () => {
      dispatch({
        type: "ACTIVE_TAB_TEMPLATE",
        payload: {
          activeTab: 0,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (mailList.length) {
      const anchorArr = [];
      mailList.forEach((x) => {
        anchorArr.push(null);
      });
      setAnchorElMail(anchorArr);
    }
  }, [mailList]);

  useEffect(() => {
    if (htmlBlockList.length) {
      const anchorArr = [];
      htmlBlockList.forEach((x) => {
        anchorArr.push(null);
      });
      setAnchorElBlock(anchorArr);
    }
  }, [htmlBlockList]);

  const changeTabValue = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabValue(newValue);
  };

  const getAllData = async (
    url: string,
    callback: (data: any) => void,
    page: number,
    isFirst = false,
    quantity = quantityPerPage,
  ) => {
    const response = await generalCRUD.getAllData(url, page, undefined, quantity);
    if (response.code === 200) {
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllData(url, callback, maxPage, false, quantity);
      callback(response.data.items);
      let pagSettings;
      switch (url) {
        case "template/email":
          pagSettings = {
            mails: {
              page: response.data.currentPageNumber,
              maxPage: maxPage,
            },
          };
          if (isFirst)
            return (paginationSettingsObject = {
              ...paginationSettingsObject,
              ...pagSettings,
            });
          break;
        case "template/block":
          pagSettings = {
            htmlBlocks: {
              page: response.data.currentPageNumber,
              maxPage: maxPage,
            },
          };
          if (isFirst)
            return (paginationSettingsObject = {
              ...paginationSettingsObject,
              ...pagSettings,
            });
          break;
        case "template/variable":
          pagSettings = {
            variables: {
              page: response.data.currentPageNumber,
              maxPage: maxPage,
            },
          };
          if (isFirst)
            return (paginationSettingsObject = {
              ...paginationSettingsObject,
              ...pagSettings,
            });
          break;
      }
      setPaginationSettings({
        ...paginationSettings,
        ...pagSettings,
      });
      return response.data;
    } else {
      toastr.error("Error", response.message);
    }
  };

  const deleteMail = async () => {
    const id = deleteMailId;
    setDeleteMailId(0);
    const response = await generalCRUD.deleteData("template/email", id);
    if (response.code === 200) {
      toastr.success(`Mail was deleted successfully`, "");
      getAllData("template/email", setMailList, paginationSettings.mails.page);
    }
  };

  const deleteHtmlBlock = async () => {
    const id = deleteHtmlBlockId;
    setDeleteHtmlBlockId(0);
    const response = await generalCRUD.deleteData("template/block", id);
    if (response.code === 200) {
      toastr.success(`HTML-block was deleted successfully`, "");
      getAllData("template/block", setHtmlBlockList, paginationSettings.htmlBlocks.page);
    }
  };

  const openDeleteModal = (name: string, id: number, index: number, data: Array<null | HTMLElement>, setter) => {
    closeMenu(index, data, setter);
    name === "email" ? setDeleteMailId(id) : setDeleteHtmlBlockId(id);
  };

  const openCopyModal = (id: number, index: number, data: Array<null | HTMLElement>, setter) => {
    closeMenu(index, data, setter);
    setCopyMailId(id);
  };

  const buildExpandedList = (list: Array<eventsItemType>): React.ReactNode => {
    const newList = list.slice(1);
    return (
      <ul className="other-events">
        {newList.map((item, index) => {
          return (
            <li className="event-item" key={index}>
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  };

  const openEditVariableModal = (data: VariableType, index: number): void => {
    setEditVariableModal({
      data: data,
      index: index,
    });
  };

  const textChangeHandler = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      data: { ...editVariableModal.data, description: value },
      index: editVariableModal.index,
    };
    setEditVariableModal(newData);
  };

  const editVariable = async () => {
    if (validateAllFields([{ validate: descValid, setValidate: setDescValid }])) {
      const editData = { ...editVariableModal };
      setEditVariableModal(null);
      setIsLoading(true);
      const response = await generalCRUD.editConcreteData("template/variable", editData.data.id, editData.data);
      if (response.code === 200) {
        const newData: Array<VariableType> = [...variableList];
        newData[editData.index] = { ...editData.data };
        setVariableList(newData);
        toastr.success("Variable was edited successfully", "");
      } else {
        toastr.error("Error", response.message);
      }
      setIsLoading(false);
    }
  };

  const closeMenu = (index: number, data, setter) => {
    const anch = [...data];
    anch[index] = null;
    setter(anch);
  };

  const makeRequest = async (url, page, quantity, setter) => {
    let response = await generalCRUD.getAllData(url, page, undefined, quantity);
    if (response.code === 200) {
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) {
        response = await generalCRUD.getAllData(url, maxPage, undefined, quantity);
      }
      setter(response.data.items);
      return {
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      };
    } else {
      toastr.error("Error", response.message);
      return false;
    }
  };

  const changeQuantityPerPage = async (quantity: number) => {
    setIsLoading(true);
    const pagSettingsMail = makeRequest("template/email", paginationSettings.mails.page, quantity, setMailList);
    const pagSettingsHtml = makeRequest(
      "template/block",
      paginationSettings.htmlBlocks.page,
      quantity,
      setHtmlBlockList,
    );
    const pagSettingsVar = makeRequest(
      "template/variable",
      paginationSettings.variables.page,
      quantity,
      setVariableList,
    );
    Promise.all([pagSettingsMail, pagSettingsHtml, pagSettingsVar]).then((values) => {
      setPaginationSettings({
        ...paginationSettings,
        mails: values[0] ? { ...values[0] } : { ...paginationSettings.mails },
        htmlBlocks: values[1] ? { ...values[1] } : { ...paginationSettings.htmlBlocks },
        variables: values[2] ? { ...values[2] } : { ...paginationSettings.variables },
      });
      setIsLoading(false);
    });
  };

  const closeEdit = () => {
    setEditVariableModal(null);
    if (!descValid.valid) {
      setDescValid((prevState) => ({ ...prevState, valid: null, error: "" }));
    }
  };

  const copyMailTemplate = async () => {
    setIsLoading(true);
    const id = copyMailId;
    setCopyMailId(0);
    const response = await generalCRUD.getConcreteData("template/email", Number(id));
    if (response.code === 200) {
      const mailData = response.data;
      const resultObject = {
        title: mailData.title + " (copy)",
        content: mailData.content,
        subject: mailData.subject,
        events: [],
      };
      const responseCreating = await generalCRUD.addNewData("template/email", resultObject);
      if (responseCreating.code === 200) {
        getAllData("template/email", setMailList, paginationSettings.mails.page);
        toastr.success(`Copy of the template was created and appended to the end of the list`, "");
      } else {
        toastr.error("Error", response.message);
      }
    }
    setIsLoading(false);
  };

  const mailBox = (
    <>
      <div className="middle">
        <p className="name">
          Here you can create Email templates, connected to certain events, for further mailing to customers.
        </p>
        <Link to={"/create-mail"}>Add mail</Link>
      </div>
      <div className="content">
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th accordion-icon-wrap">&nbsp;</div>
            <div className="th">Connected events</div>
            <div className="th date">Updated</div>
          </div>

          <div className="th actions last">Action</div>
        </div>
        <ul className="table-content">
          {mailList.map((item: MailType, index: number) => {
            return (
              <li key={index} className={item.system ? "system" : ""}>
                <div
                  className={expandedId === item.id ? "table-content-item expanded" : "table-content-item not-expanded"}
                >
                  <div className="th first">{item.id}</div>
                  <div className="middle-part">
                    <div className="th">{item.title}</div>
                    <div className="th accordion-icon-wrap">
                      <div className="accordion-icon-cont">
                        {item.events.length && expandedId === item.id && (
                          <div className="top-content">{item.events[0].name}</div>
                        )}
                        {expandedId === item.id && buildExpandedList(item.events)}
                      </div>
                      {item.events.length > 1 && (
                        <div
                          className={expandedId === item.id ? "accordion-icon active" : "accordion-icon"}
                          onClick={() => handleAccordion(item.id)}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div className="th flex v-center">
                      <div className="top-content">{item.events.length ? item.events[0].name : ""}</div>
                      {expandedId === item.id && buildExpandedList(item.events)}
                    </div>
                    <div className="th date">{item.updatedAt.slice(0, 10)}</div>
                  </div>
                  <div className="th actions">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleOpenMenu(event, index, anchorElMail, setAnchorElMail)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorElMail[index]}
                      keepMounted
                      className="action-menu"
                      open={!!anchorElMail[index]}
                      onClose={() => closeMenu(index, anchorElMail, setAnchorElMail)}
                    >
                      <MenuItem onClick={() => history.push(`/edit-mail/${item.id}`)}>Edit</MenuItem>
                      {!item.system && (
                        <MenuItem
                          className="action-menu"
                          onClick={() => openCopyModal(item.id, index, anchorElMail, setAnchorElMail)}
                        >
                          Copy
                        </MenuItem>
                      )}
                      {!item.system && (
                        <MenuItem
                          className="delete"
                          onClick={() => openDeleteModal("email", item.id, index, anchorElMail, setAnchorElMail)}
                        >
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <CustomPagination
        page={paginationSettings.mails.page}
        maxPage={paginationSettings.mails.maxPage}
        isLoaded={!isLoading}
        itemsNumber={mailList.length}
        handleClickPage={(value) => getAllData("template/email", setMailList, value)}
        handleClickQuantity={(quantity) => changeQuantityPerPage(quantity)}
      />
    </>
  );

  const htmlBox = (
    <>
      <div className="middle">
        <p className="name">
          Here you can build, edit and delete mail construction units for further pasting into mails for shippers, to be
          sent depending on events.
        </p>
        <Link to={"/create-html-block"}>Add html-block</Link>
      </div>
      <div className="content">
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th">Identification</div>
            <div className="th">Updated</div>
          </div>
          <div className="th actions-title">Action</div>
        </div>
        <ul className="table-content">
          {htmlBlockList.map((item: HtmlBlockType, index: number) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="middle-part">
                  <div className="th">{item.title}</div>
                  <div className="th">{item.identifier}</div>
                  <div className="th">{item.updatedAt.slice(0, 10)}</div>
                </div>
                <div className="th last actions">
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleOpenMenu(event, index, anchorElBlock, setAnchorElBlock)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElBlock[index]}
                    keepMounted
                    className="action-menu"
                    open={!!anchorElBlock[index]}
                    onClose={() => closeMenu(index, anchorElBlock, setAnchorElBlock)}
                  >
                    <MenuItem onClick={() => history.push(`/edit-html-block/${item.id}`)}>Edit</MenuItem>
                    <MenuItem
                      className="delete"
                      onClick={() => openDeleteModal("", item.id, index, anchorElBlock, setAnchorElBlock)}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <CustomPagination
        page={paginationSettings.htmlBlocks.page}
        maxPage={paginationSettings.htmlBlocks.maxPage}
        isLoaded={!isLoading}
        itemsNumber={htmlBlockList.length}
        handleClickPage={(value) => getAllData("template/block", setHtmlBlockList, value)}
        handleClickQuantity={(quantity) => changeQuantityPerPage(quantity)}
      />
    </>
  );

  const variableBox = (
    <>
      <div className="middle">
        <p className="name">
          Here you have the list of variables, to be used for further pasting them into mail templates, auto-completing
          saved data into mails to shippers.
        </p>
      </div>
      <div className="content">
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th">Identification</div>
            <div className="th">Description</div>
          </div>
          <div className="th last variable">Action</div>
        </div>
        <ul className="table-content">
          {variableList.map((item: VariableType, index: number) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="middle-part">
                  <div className="th">{item.name}</div>
                  <div className="th">{item.identifier}</div>
                  <div className="th">{item.description}</div>
                </div>
                <div className="th last variable">
                  <span onClick={() => openEditVariableModal(item, index)}>Edit</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <CustomPagination
        page={paginationSettings.variables.page}
        maxPage={paginationSettings.variables.maxPage}
        isLoaded={!isLoading}
        itemsNumber={variableList.length}
        handleClickPage={(value) => getAllData("template/variable", setVariableList, value)}
        handleClickQuantity={(quantity) => changeQuantityPerPage(quantity)}
      />
    </>
  );

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Variable name</p>
          <p>{editVariableModal ? editVariableModal.data.name : ""}</p>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Identification</p>
          <p>{editVariableModal ? editVariableModal.data.identifier : ""}</p>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Description</p>
          <p>
            <TextField
              helperText={descValid.error}
              error={descValid.valid === false}
              value={editVariableModal ? editVariableModal.data.description : ""}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: descValid.maxLength } }}
              type="text"
              onChange={(event) => textChangeHandler(event)}
              onBlur={(e) => validateField(e.target.value, descValid, setDescValid)}
            />
          </p>
        </div>
      </div>
    </>
  );

  return (
    <MainTemplate>
      <div id="templates" className="page-container">
        <div className="top-part">
          <span className="title">Templates</span>
        </div>
        <div className="general-tab-wrap">
          <Tabs value={tabValue} onChange={changeTabValue}>
            <Tab label="Mails" />
            <Tab label="Html-blocks" />
            <Tab label="Variables" />
          </Tabs>
        </div>
        <div className="page-container">
          {tabValue === 0 && mailBox}
          {tabValue === 1 && htmlBox}
          {tabValue === 2 && variableBox}
        </div>
        <ModalWindow
          open={!!deleteMailId}
          isDeleting={true}
          handleClose={() => setDeleteMailId(0)}
          handleSubmit={deleteMail}
          title="Delete mail"
          description="Attention! After deleting of this template, there is no possibility to provide the preview of mails, based on deleted template, in the Events history."
        >
          &nbsp;
        </ModalWindow>
        <ModalWindow
          open={!!deleteHtmlBlockId}
          isDeleting={true}
          handleClose={() => setDeleteHtmlBlockId(0)}
          handleSubmit={deleteHtmlBlock}
          title="Delete HTML-block"
          description="You can delete HTML-block"
        >
          &nbsp;
        </ModalWindow>
        <ModalWindow
          open={!!editVariableModal}
          handleClose={closeEdit}
          handleSubmit={editVariable}
          title="Edit variable"
          classes="template-modal"
          description="You can edit Variable description"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={!!copyMailId}
          isDeleting={true}
          handleClose={() => setCopyMailId(0)}
          handleSubmit={copyMailTemplate}
          title="Copy mail template"
          description="You can create a copy of the email template"
        >
          &nbsp;
        </ModalWindow>
        {isLoading && <LoaderLocal />}
      </div>
    </MainTemplate>
  );
};

export default TemplatesContainer;
