import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import { useSelector } from "react-redux";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";
import { validateAllFields, validateField } from "../../../validator/Fields";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import CustomPagination from "../../organisms/CustomPagination";
import TextField from "@material-ui/core/TextField";

// Styles
import "./style.scss";

import { rootReducerType } from "../../../redux/reducers";
import { FieldType } from "../../../validator/types";

export type vesselsItemType = {
  id: number;
  imoNumber: string;
  name: string;
  [key: string]: string | number;
};

export const vesselsItemInitial = {
  id: 1,
  imoNumber: "",
  name: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const VesselsContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [vesselsList, setVesselsList] = useState<Array<vesselsItemType>>([]);
  const [vesselsItem, setVesselsItem] = useState<vesselsItemType>(vesselsItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(150) });
  const [imoNumberValid, setImoNumberValid] = useState<FieldType>({ ...maxLengthInstance(50) });

  // to get all entities
  useEffect(() => {
    getAllVessels(1);
  }, []);

  const openEditModal = (data: vesselsItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setVesselsItem(data);
  };

  const allFields = [
    { validate: { ...nameValid, value: vesselsItem.name }, setValidate: setNameValid },
    { validate: { ...imoNumberValid, value: vesselsItem.imoNumber }, setValidate: setImoNumberValid },
  ];

  const closeModal = (): void => {
    setVesselsItem(vesselsItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const getAllVessels = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("vessel", page, undefined, quantity);
    if (response.code === 200) {
      setVesselsList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllVessels(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    let valid = true;
    for (const key in vesselsItem) {
      if (key === "id") continue;
      if (!vesselsItem[key]) {
        valid = false;
        break;
      }
    }
    if (valid) {
      setIsLoading(true);
      closeModal();
      let response;
      if (forEdit) {
        response = await generalCRUD.editConcreteData("vessel", vesselsItem.id, vesselsItem);
      } else {
        response = await generalCRUD.addNewData("vessel", vesselsItem);
      }
      if (response.code === 200) {
        handleSuccess();
        return setIsLoading(false);
      }
      setIsLoading(false);
      return toastr.error(
        "Error",
        response.message === "Entity error." ? "Vessel with this IMO Number already exists" : response.error,
      );
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllVessels(paginationSettings.page);
      toastr.success("New Vessel was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<vesselsItemType> = [...vesselsList];
      newData[index] = { ...vesselsItem };
      setVesselsList(newData);
      toastr.success("Vessel was edited successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, true);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, field: string, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...vesselsItem,
      [field]: value,
    };
    setVesselsItem(newData);
  };
  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Name</p>
          <p>
            <TextField
              helperText={nameValid.error}
              error={nameValid.valid === false}
              value={vesselsItem.name}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
              type="text"
              placeholder="Name"
              onChange={(event) => textChangeHandler(event, "name")}
              onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
            />
          </p>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">IMO Number</p>
          <p>
            <TextField
              helperText={imoNumberValid.error}
              error={imoNumberValid.valid === false}
              value={vesselsItem.imoNumber}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: imoNumberValid.maxLength } }}
              type="text"
              placeholder="IMO Number"
              onChange={(event) => textChangeHandler(event, "imoNumber")}
              onBlur={(e) => validateField(e.target.value, imoNumberValid, setImoNumberValid)}
            />
          </p>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="vessels" className="page-container">
        <TopPartPage title="vessels" buttonTitle="add new vessel" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">IMO Number</div>
            <div className="th">Name</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {vesselsList.map((item: vesselsItemType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="middle-part">
                  <div className="th">{item.imoNumber}</div>
                  <div className="th">{item.name}</div>
                </div>
                <div className="th last">
                  <span onClick={() => openEditModal(item, index)}>Edit</span>
                </div>
              </li>
            );
          })}
        </ul>
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={vesselsList.length}
          handleClickPage={(value) => getAllVessels(value)}
          handleClickQuantity={(quantity) => getAllVessels(paginationSettings.page, quantity)}
        />
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add new vessel"
          classes="vessels-modal"
          description="You can add new Vessel"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit vessel"
          classes="vessels-modal"
          description="You can edit Vessel"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(VesselsContainer);
