import React, { FC, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomPagination from "../../organisms/CustomPagination";

// Styles
import "./style.scss";

import { rootReducerType } from "../../../redux/reducers";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance, requireInstance } from "../../../validator/Fields/instantLCLQuote";

type cartagesItemType = {
  id: number;
  name: string;
  category: {
    createdAt: string;
    id: number;
    name: string;
    updatedAt: string;
  } | null;
  categoryId: number | null;
  dest: string;
  status: boolean;
  exclusion: boolean;
  [key: number]: number;
  amazon: boolean;
};

const cartagesItemInitial = {
  id: 0,
  name: "",
  category: null,
  categoryId: null,
  dest: "",
  status: true,
  exclusion: false,
  amazon: false,
};

type editModalType = {
  status: boolean;
  index: number;
};

const CartagesContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [cartagesList, setCartagesList] = useState<Array<cartagesItemType>>([]);
  const [categoryList, setCategoryList] = useState<Array<any>>([]);
  const [cartagesItem, setCartagesItem] = useState<cartagesItemType>(cartagesItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  /*Validation*/
  const [categoryIdValid, setCategoryIdValid] = useState<FieldType>({ ...requireInstance });
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(150) });
  const [destinationValid, setDestinationValid] = useState<FieldType>({ ...maxLengthInstance(10) });

  useEffect(() => {
    getAllCategory();
    getAllCartages(paginationSettings.page);
  }, []);

  const openEditModal = (data: cartagesItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setCartagesItem({ ...data, categoryId: data.category ? data.category.id : null });
  };

  const allFields = [
    { validate: { ...categoryIdValid, value: cartagesItem.categoryId }, setValidate: setCategoryIdValid },
    { validate: { ...nameValid, value: cartagesItem.name }, setValidate: setNameValid },
    { validate: { ...destinationValid, value: cartagesItem.dest }, setValidate: setDestinationValid },
  ];

  const closeModal = (): void => {
    setCartagesItem(cartagesItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const getAllCartages = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("value/cartage", page, undefined, quantity);
    if (response.code === 200) {
      setCartagesList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllCartages(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const getAllCategory = async () => {
    const response = await generalCRUD.getAllData("admin/value/cartage-cartegory", undefined, undefined, undefined);
    if (response.code === 200) {
      setCategoryList(response.data.items);
    }
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    const valid = true;
    /*for (const key in cartagesItem) {
      if (key === "id" || key === "status" || key === "exclusion") continue;
      if (!cartagesItem[key]) {
        console.log("asd");
        valid = false;
        break;
      }
    }*/

    if (valid) {
      setIsLoading(true);
      closeModal();
      let response;

      const request = {
        categoryId: cartagesItem.categoryId,
        name: cartagesItem.name,
        dest: cartagesItem.dest,
        exclusion: cartagesItem.exclusion,
        status: cartagesItem.status,
      };
      if (forEdit) {
        response = await generalCRUD.editConcreteData("value/cartage", cartagesItem.id, request);
      } else {
        response = await generalCRUD.addNewData("value/cartage", request);
      }
      if (response.code === 200) {
        // handleSuccess();
        await getAllCartages(paginationSettings.page);
        return setIsLoading(false);
      }
      setIsLoading(false);
      return toastr.error("Error", response.message);
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllCartages(paginationSettings.page);
      toastr.success("New Cartage was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<cartagesItemType> = [...cartagesList];
      newData[index] = { ...cartagesItem };
      setCartagesList(newData);
      toastr.success("Your Cartage was edited successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, true);
    }
  };

  const textChangeHandler = (event: React.ChangeEvent<{ value: unknown }>, field: string, num?: boolean | string) => {
    const value = event.target.value as string;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...cartagesItem,
      [field]: value,
    };

    if (field === "categoryId") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, categoryIdValid, setCategoryIdValid);
    }

    setCartagesItem(newData);
  };

  const switchStatus = (value: boolean): void => {
    setCartagesItem({ ...cartagesItem, status: value });
  };

  const switchAmazon = (value: boolean): void => {
    setCartagesItem({ ...cartagesItem, amazon: value });
  };

  const handleToggleAmazon = (amazon: boolean): void => {
    setCartagesItem({ ...cartagesItem, amazon: !amazon });
  };

  const handleToggleVisibility = (status: boolean): void => {
    setCartagesItem({ ...cartagesItem, status: !status });
  };

  const switchExclusion = (value: boolean): void => {
    setCartagesItem({ ...cartagesItem, exclusion: value });
  };

  const handleToggleExclusion = (exclusion: boolean): void => {
    setCartagesItem({ ...cartagesItem, exclusion: !exclusion });
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Cartage Category</p>
          <div>
            <FormControl className="general-select">
              <Select
                value={cartagesItem.categoryId ? cartagesItem.categoryId : "0"}
                error={categoryIdValid.valid === false}
                onChange={(event) => textChangeHandler(event, "categoryId")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem className="no-value" value="0" disabled>
                  Select category
                </MenuItem>
                {categoryList &&
                  categoryList.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {categoryIdValid.valid === false && <div className="freight-err-text 123">This field cannot be empty</div>}
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Cartage Name</p>
          <TextField
            helperText={nameValid.error}
            error={nameValid.valid === false}
            value={cartagesItem.name}
            InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
            type="text"
            placeholder="Name"
            onChange={(event) => textChangeHandler(event, "name", false)}
            onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
          />
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Cartage Destination</p>
          <TextField
            helperText={destinationValid.error}
            error={destinationValid.valid === false}
            value={cartagesItem.dest}
            InputProps={{ disableUnderline: true, inputProps: { maxLength: destinationValid.maxLength } }}
            type="text"
            placeholder="Destination"
            onChange={(event) => textChangeHandler(event, "dest", false)}
            onBlur={(e) => validateField(e.target.value, destinationValid, setDestinationValid)}
          />
        </div>
      </div>
      <div className="flex-wrapper">
        <div className="unit-wrap">
          <div className="unit unit-center">
            <p className="sub-title">Cartage exclusion</p>
            <p className="switcher-exclusion">
              <span className="hover-cursor" onClick={() => switchExclusion(false)}>
                No
              </span>
              <Switch
                checked={cartagesItem.exclusion}
                onClick={() => handleToggleExclusion(cartagesItem.exclusion)}
                name={"switcher-visibility-" + cartagesItem.id}
                style={{ color: "white" }}
              />
              <span className="hover-cursor" onClick={() => switchExclusion(true)}>
                Yes
              </span>
            </p>
          </div>
          <div className="unit unit-center">
            <p className="sub-title">Visibility</p>
            <p className="switcher-visibility">
              <span className="hover-cursor" onClick={() => switchStatus(false)}>
                Off
              </span>
              <Switch
                checked={cartagesItem.status}
                onClick={() => handleToggleVisibility(cartagesItem.status)}
                name="switcher-visibility"
                style={{ color: "white" }}
              />
              <span className="hover-cursor" onClick={() => switchStatus(true)}>
                On
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="cartages" className="page-container">
        <TopPartPage title="Cartages" buttonTitle="add new Cartage" handleClick={() => setIsAddModalOpen(true)} />
        <table className="cartege-table">
          <thead className="table-header">
            <tr>
              <th className="cartege-id">ID</th>
              <th className="cartege-name">Name</th>
              <th className="cartege-category">Category</th>
              <th className="cartege-value">Value</th>
              <th className="cartege-exclusion">Exclusion</th>
              <th className="cartege-visibility">Visibility</th>
              <th className="cartege-action">Action</th>
            </tr>
          </thead>
          <tbody className="table-body-wrapper">
            {cartagesList &&
              cartagesList.map((item: cartagesItemType, index) => {
                return (
                  <tr key={index}>
                    <td className="cartege-id">
                      <span>{item.id}</span>
                    </td>
                    <td className="cartege-name">
                      <span>{item.name}</span>
                    </td>
                    <td className="cartege-category">
                      <span>{item.category ? item.category.name : ""}</span>
                    </td>
                    <td className="cartege-value">
                      <span>{item.dest}</span>
                    </td>
                    <td className="cartege-exclusion">{item.exclusion ? "Yes" : "No"}</td>
                    <td className="cartege-visibility">{item.status ? "On" : "Off"}</td>
                    <td className="cartege-action" onClick={() => openEditModal(item, index)}>
                      <span>Edit</span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {isLoading && <LoaderLocal />}
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={cartagesList.length}
          handleClickPage={(value) => getAllCartages(value)}
          handleClickQuantity={(quantity) => getAllCartages(paginationSettings.page, quantity)}
        />
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add cartage"
          classes="cartages-modal"
          description="You can add new Cartage"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit cartage"
          classes="cartages-modal"
          description="You can edit your Cartage"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(withRouter(CartagesContainer));
