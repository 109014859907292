import React, { useState, FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { orders } from "../../../../actions/orders";
import { rootReducerType } from "../../../../redux/reducers";
import helpFunctions from "../../../../tools/helpFunctions";
import { eventsHistoryType } from "../../../containers/OrdersConcrete";
import { modalPreviewType } from "../../../containers/SetEvent";

// Components
import GeneralButton from "../../../atoms/GeneralButton";
import { toastr } from "react-redux-toastr";
import ModalWindow from "../../../molecules/ModalWindow";
import LoaderLocal from "../../../atoms/LoaderLocal";
import ExtensionBox from "../../../atoms/ExtensionBox";

// Styles
import "./style.scss";

type propsEventStatusType = {
  data: Array<eventsHistoryType>;
};

const EventStatus: FC<any> = (props: propsEventStatusType) => {
  const storeOrderData = useSelector((state: rootReducerType) => state.orders.orderData);
  const eventsData = props.data;
  const [modalPreview, setModalPreview] = useState<modalPreviewType>({
    status: false,
    content: "",
  });
  const [resendModal, setResendModal] = useState<boolean>(false);

  const setEventAddress = "/set-event/" + storeOrderData.id;

  const resendRequest = async () => {
    setResendModal(false);
    const response = await orders.resendRequest(storeOrderData.id);
    if (response.code === 200) {
      toastr.success("Request info was resent successfully", "");
    } else {
      toastr.error("Error", response.message);
    }
  };

  const openPreview = async (id: number) => {
    setModalPreview({
      status: true,
      content: "",
    });
    const response = await orders.getPreviewContent(id, storeOrderData.id);
    if (response.status === 200) {
      setModalPreview({
        status: true,
        content: response.data,
      });
    } else {
      toastr.error("Error", response.message);
    }
  };

  return (
    <div id="event-status">
      <div className="base-wrap cur-status">
        <p className="title">Current event status</p>
        <div className="middle">
          <p className="name">
            Current event: <span>{storeOrderData.lastEventName}</span>
          </p>
          <div className="action-box">
            <div className="request-info-buttons">
              <GeneralButton classes="action" handleClick={() => setResendModal(true)}>
                Resend request info
              </GeneralButton>
              <button className="request-info-preview" onClick={() => openPreview(5)}>
                Click for preview
              </button>
            </div>
            <Link to={setEventAddress}>Set next event status</Link>
          </div>
        </div>
      </div>
      <div className="base-wrap">
        <p className="title">Events history:</p>
        {eventsData ? (
          eventsData.map((item: eventsHistoryType, index: number) => {
            const isLargeCard =
              (!!item.emailTemplate && !!item.emailTemplate.id) ||
              !!item.comment ||
              item.deletedEmailTemplate ||
              !!item.documents.length;
            const isUserEvent = item.user.role[0] === "ROLE_USER";
            return (
              <div className="history-box" key={index}>
                <div className="history-main">
                  <p className="history-date">
                    <span>{helpFunctions.getDateFromUnix(item.date)}</span>
                    {helpFunctions.getTimeFromUnix(item.date)}
                  </p>
                  <p className="history-name">
                    <span className="role">{item.user.humanRole}:</span>
                    {item.event.name}
                  </p>
                </div>
                {item.event.publicStatus.length ? (
                  <div className="public-status-wrapper">
                    <span className="public-status">Public status</span>
                    <span className="public-status-text">{item.event.publicStatus}</span>
                  </div>
                ) : null}
                {(isLargeCard || isUserEvent) && (
                  <div className="history-additional">
                    <div
                      className={item.deletedEmailTemplate || isUserEvent ? "add-part add-part-disable" : "add-part"}
                    >
                      {item.emailTemplate && item.emailTemplate.id && !isUserEvent ? (
                        <div className="attachment" onClick={() => openPreview(item.emailTemplate.id)}>
                          <div className="envelop">&nbsp;</div>
                          <p>Click for preview</p>
                        </div>
                      ) : item.deletedEmailTemplate || isUserEvent ? (
                        <div
                          className="attachment"
                          title={
                            isUserEvent
                              ? "This event has user's documents"
                              : "Template, which the mail was based on, has been deleted."
                          }
                        >
                          <div className={isUserEvent ? "doc-box" : "envelop"}>&nbsp;</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="cont-part">
                      <div className="content">
                        <div className="comment-part">
                          {item.comment ? (
                            <div className="comment">
                              <p className="comment-title">Comment:</p>
                              <p>{item.comment}</p>
                            </div>
                          ) : (
                            <p className="no-comment">No comments</p>
                          )}
                        </div>
                        {!!item.documents.length && (
                          <div className="document-wrap">
                            <p className="comment-title">Added files:</p>
                            <ul>
                              {item.documents.map((doc, i) => (
                                <li key={i} className="document-item">
                                  <ExtensionBox fileName={doc.name} colour={isUserEvent ? 2 : 1} />
                                  <span className="document-name">{doc.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="border">&nbsp;</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p>No entries yet</p>
        )}
      </div>
      <ModalWindow
        classes="preview"
        open={modalPreview.status}
        handleClose={() =>
          setModalPreview({
            status: false,
            content: "",
          })
        }
        title="Preview"
      >
        {!modalPreview.content ? (
          <LoaderLocal />
        ) : (
          <iframe srcDoc={modalPreview.content} width={670} height={500} className="preview-frame">
            Your browser does not support inline frames!
          </iframe>
        )}
      </ModalWindow>
      <ModalWindow
        open={resendModal}
        isDeleting={true}
        handleClose={() => setResendModal(false)}
        handleSubmit={resendRequest}
        title="Resend request info"
        description="Attention! Booking request will be sent to the customer again"
      >
        &nbsp;
      </ModalWindow>
    </div>
  );
};

export default EventStatus;
