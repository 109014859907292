import React, { useEffect, FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import helpFunctions from "../../../tools/helpFunctions";
import { generalCRUD } from "../../../actions/generalCRUD";
import { app } from "../../../actions/app";
import { initialHtmlBlock, initialVariable, HtmlBlockType, VariableType } from "../TemplatesPage";
import withStyles from "@material-ui/core/styles/withStyles";

import { modalPreviewType } from "../SetEvent";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";

// Components
import { toastr } from "react-redux-toastr";
import LoaderLocal from "../../atoms/LoaderLocal";
import GeneralButton from "../../atoms/GeneralButton";
import WEditor from "../../molecules/WEditor";
import { Tooltip } from "@material-ui/core";
import ModalWindow from "../../molecules/ModalWindow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ThemeSwitcher from "../../molecules/themeSwitcher";
import TextField from "@material-ui/core/TextField";

// Styles
import "./style.scss";

const HtmlBlock: FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [htmlBlockData, setHtmlBlockData] = useState<HtmlBlockType>(initialHtmlBlock);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variableList, setVariableList] = useState<Array<VariableType>>([initialVariable]);
  const [htmlBlockList, setHtmlBlockList] = useState<Array<HtmlBlockType>>([initialHtmlBlock]);
  const [exitModal, setExitModal] = useState<string | null>(null);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [modalPreview, setModalPreview] = useState<modalPreviewType>({
    status: false,
    content: "",
  });

  /*Validation*/
  const [htmlTitleValid, setHtmlTitleValid] = useState<FieldType>({
    ...maxLengthInstance(150),
    value: htmlBlockData.title,
  });
  const [contentValid, setContentValid] = useState<boolean>(null);

  const htmlBlockId = helpFunctions.getParamIdFromUrl();

  useEffect(() => {
    getAddTools("template/variable", setVariableList);
    getAddTools("template/block", setHtmlBlockList);

    if (htmlBlockId) {
      getHtmlBlockData();
    }

    return () => {
      window.removeEventListener("popstate", showExitModalBackAction);
    };
  }, []);

  useEffect(() => {
    if (isEdited) {
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.addEventListener("popstate", showExitModalBackAction, false);
    }
  }, [isEdited]);

  const getAddTools = async (url: string, callback) => {
    const response = await generalCRUD.getAllData(url, null, null, 1000);
    if (response.code === 200) {
      callback(response.data.items);
      return response.data;
    } else {
      toastr.error("Error", response.message);
    }
  };

  const getHtmlBlockData = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getConcreteData("template/block", Number(htmlBlockId));
    if (response.code === 200) {
      setHtmlBlockData(response.data);
      setHtmlTitleValid({ ...maxLengthInstance(150), value: response.data.title });
      setIsLoading(false);
    } else {
      history.push(`/templates`);
      toastr.error("Error", response.message);
    }
  };

  const inputTextHandler = (value: string, field: string) => {
    setHtmlBlockData({
      ...htmlBlockData,
      [field]: value,
    });
    if (!isEdited) setIsEdited(true);
    if (field === "content") {
      setContentValid(!!value.length);
    }
  };

  const cancelCreationHtmlBlock = () => {
    if (isEdited) return setExitModal("cancel");
    confirmExit();
  };

  const showExitModalBackAction = () => {
    setExitModal("back");
  };

  const confirmExit = () => {
    setActiveTab();
    if (exitModal === "back") {
      if (window.history.state.name) {
        history.goBack();
      }
      return history.goBack();
    }
    history.push("/templates");
  };

  const setActiveTab = () => {
    dispatch({
      type: "ACTIVE_TAB_TEMPLATE",
      payload: {
        activeTab: 1,
      },
    });
  };

  const openPreview = () => {
    if (!htmlBlockData.content) return toastr.error("Error", "Html-block cannot be empty");
    app.openLivePreview(setModalPreview, htmlBlockData.content);
  };

  const submitHtmlBlock = async () => {
    setIsLoading(true);
    const resultObject = {
      title: htmlBlockData.title,
      content: htmlBlockData.content,
    };
    if (!resultObject.content) {
      setContentValid(false);
    } else {
      setContentValid(true);
    }

    if (validateAllFields([{ validate: htmlTitleValid, setValidate: setHtmlTitleValid }]) && contentValid) {
      let response;
      if (!htmlBlockId) {
        response = await generalCRUD.addNewData("template/block", resultObject);
      } else {
        response = await generalCRUD.editConcreteData("template/block", htmlBlockData.id, resultObject);
      }
      if (response.code === 200) {
        setActiveTab();
        !htmlBlockId
          ? toastr.success("New html-block was created successfully", "")
          : toastr.success("Html-block was edited successfully", "");
        history.push(`/templates`);
      } else {
        toastr.error("Error", response.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const VarTooltip = withStyles(() => ({
    tooltip: {
      padding: 7,
      fontSize: 13,
      fontFamily: "OpenSans",
      backgroundColor: "rgba(97, 97, 97, 1)",
    },
  }))(Tooltip);

  const variablesBox = (
    <div className="var-wrap">
      <div className="var-head">Available variables</div>
      <div className="var-content">
        <div className="var-title-wrap">
          <div className="var-title">Name</div>
          <div className="var-title">Identifier</div>
        </div>
        <ul className="var-list">
          {variableList.map((item, index) => {
            return (
              <li key={index}>
                <VarTooltip title={item.description || ""} placement="left" interactive>
                  <div className="var-name">{item.name}</div>
                </VarTooltip>
                <div className="var-value">{`{{ ${item.identifier} }}`}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  const htmlBlockBox = (
    <div className="var-wrap">
      <div className="var-head">Available html-blocks</div>
      <div className="var-content">
        <div className="var-title-wrap">
          <div className="var-title">Name</div>
          <div className="var-title">Identifier</div>
        </div>
        <ul className="var-list">
          {htmlBlockList.map((item, index) => {
            return (
              <li key={index}>
                <div className="var-name">{item.title}</div>
                <div className="var-value">{`{% '${item.identifier}' %}`}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  return (
    <div id="create-html-block">
      <div className="hide-switcher">
        <ThemeSwitcher />
      </div>
      <form onSubmit={(event) => event.preventDefault()} className="content">
        <div className="content-item top-title">html-block constructor</div>
        <div className="content-item">
          <div className="w100">
            <p className="title">Set html-block title:</p>
            <TextField
              helperText={htmlTitleValid.error}
              error={htmlTitleValid.valid === false}
              value={htmlBlockData.title}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: htmlTitleValid.maxLength } }}
              type="text"
              className="base-input"
              placeholder="Html-block title"
              onChange={(event) => inputTextHandler(event.target.value, "title")}
              onBlur={(e) => validateField(e.target.value, htmlTitleValid, setHtmlTitleValid)}
            />
          </div>
        </div>
        <div className="content-item">
          <div className="editor">
            <p className="title">Make html-block:</p>
            <div className={contentValid === false ? "editor-err" : "editor-wrapper"}>
              <WEditor
                content={htmlBlockData.content}
                height={920}
                uploadImage={app.uploadImage}
                handleEditorChange={(value) => inputTextHandler(value, "content")}
              />
            </div>
            <div className={contentValid === false ? "editor-show-err editor-err-text" : "editor-err-text"}>
              This block cannot be empty
            </div>
          </div>
          <div className="additional">
            <p className="title">Copy blocks you need:</p>
            <div className="var-tab-wrap">
              <Tabs
                value={tabValue}
                onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
                  setTabValue(newValue);
                }}
              >
                <Tab label="Variables" />
                <Tab label="Html blocks" />
              </Tabs>
            </div>
            <div>
              {tabValue === 0 && variablesBox}
              {tabValue === 1 && htmlBlockBox}
            </div>
            <div className="var-wrap tools">
              <div className="var-head tool">To insert &apos;if&apos; operator</div>
              <div className="body">
                <span>{`{% if variable %}`}</span>
                <span className="text">your content</span>
                <span>{`{% endif %}`}</span>
              </div>
              <div className="body">
                <span>{`
                  {% if variable %}`}</span>
                <span className="text">your content</span>
                <span>{`{% else %}`}</span>
                <span className="text">your content</span>
                <span>{`{% endif %}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <GeneralButton classes="cancel" handleClick={cancelCreationHtmlBlock}>
            Cancel
          </GeneralButton>
          <GeneralButton classes="preview" handleClick={openPreview}>
            Preview
          </GeneralButton>
          <GeneralButton classes="submit" type="submit" handleClick={submitHtmlBlock}>
            Submit
          </GeneralButton>
        </div>
      </form>
      {isLoading && <LoaderLocal />}
      <ModalWindow
        open={!!exitModal}
        handleClose={() => setExitModal(null)}
        handleSubmit={confirmExit}
        title="Attention!"
        description="Leaving this page with lead to losing of the created content."
        submitTitle="leave"
        closeTitle="stay here"
        isDeleting={true}
      />
      <ModalWindow
        classes="preview"
        open={modalPreview.status}
        handleClose={() =>
          setModalPreview({
            status: false,
            content: "",
          })
        }
        title="Preview"
      >
        {!modalPreview.content ? (
          <LoaderLocal />
        ) : (
          <iframe srcDoc={modalPreview.content} width={670} height={500} className="preview-frame">
            Your browser does not support inline frames!
          </iframe>
        )}
      </ModalWindow>
    </div>
  );
};

export default HtmlBlock;
