import React, { FC, SyntheticEvent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";
import helpFunctions from "../../../../tools/helpFunctions";

import { FieldType } from "../../../../validator/types";
import { maxLengthInstance } from "../../../../validator/Fields/instantLCLQuote";
import { validateAllFields, validateField } from "../../../../validator/Fields";
import { emailField } from "../../../../validator/Fields/quoteRequest";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

// Styles
import "./style.scss";

type orderDetailsType = {
  orderData: orderDataType;
};

const Contact: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(50), value: orderData.yourName });
  const [companyNameValid, setCompanyNameValid] = useState<FieldType>({
    ...maxLengthInstance(100),
    value: orderData.companyName,
  });
  const [companyAddrValid, setCompanyAddrValid] = useState<FieldType>({
    ...maxLengthInstance(100),
    value: orderData.companyAddress,
  });
  const [emailValid, setEmailValid] = useState<FieldType>({ ...emailField, value: orderData.email });
  const [phoneValid, setPhoneValid] = useState<FieldType>({ ...maxLengthInstance(25), value: orderData.phone });
  const [addInfoValid, setAddInfoValid] = useState<FieldType>({
    ...maxLengthInstance(100, false),
    value: orderData.additionalInfo,
  });

  const dispatch = useDispatch();

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const allFields = [
    { validate: nameValid, setValidate: setNameValid },
    { validate: companyNameValid, setValidate: setCompanyNameValid },
    { validate: companyAddrValid, setValidate: setCompanyAddrValid },
    { validate: emailValid, setValidate: setEmailValid },
    { validate: phoneValid, setValidate: setPhoneValid },
    { validate: addInfoValid, setValidate: setAddInfoValid },
  ];

  const cancelHandler = () => {
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "" }));
      }
    });
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields(allFields)) {
      const newData = {
        ...orderData,
        phone: orderData.phone.replace("+", ""),
      };
      sendRequest(newData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id: any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1) : setNotify(0);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="contact">
      <div className="tab-header">
        <div className="tab-header-title">CONTACT</div>
        <div onClick={editHendler} className={!lineEditing ? "show" : "hide"}>
          Edit
        </div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">Name:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={nameValid.error}
                  error={nameValid.valid === false}
                  value={orderData.yourName}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "yourName")}
                  onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
                />
              ) : (
                <div className="value">{orderData.yourName}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Company name:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={companyNameValid.error}
                  error={companyNameValid.valid === false}
                  value={orderData.companyName}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "companyName")}
                  onBlur={(e) => validateField(e.target.value, companyNameValid, setCompanyNameValid)}
                />
              ) : (
                <div className="value">{orderData.companyName}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Company address:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={companyAddrValid.error}
                  error={companyAddrValid.valid === false}
                  value={orderData.companyAddress}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "companyAddress")}
                  onBlur={(e) => validateField(e.target.value, companyAddrValid, setCompanyAddrValid)}
                />
              ) : (
                <div className="value">{orderData.companyAddress}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">E-mail:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={emailValid.error}
                  error={emailValid.valid === false}
                  value={orderData.email}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "email")}
                  onBlur={(e) => validateField(e.target.value, emailValid, setEmailValid)}
                />
              ) : (
                <div className="value">{orderData.email}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Phone:</div>
            <div className="description-summary">
              {lineEditing ? (
                <NumberFormat
                  customInput={TextField}
                  prefix={"+"}
                  helperText={phoneValid.error}
                  error={phoneValid.valid === false}
                  value={orderData.phone}
                  inputProps={{ maxLength: phoneValid.maxLength, disableUnderline: true }}
                  placeholder="e.g. (604) 785 - 3176"
                  allowNegative={false}
                  isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, false) : true)}
                  onChange={(event) => textChangeHandler(event, "phone")}
                  onBlur={(e) => validateField(e.target.value, phoneValid, setPhoneValid)}
                />
              ) : (
                <div className="value">
                  {orderData.phone.substr(0, 1) === "+" ? "" : "+"}
                  {orderData.phone}
                </div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Additional information:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={addInfoValid.error}
                  error={addInfoValid.valid === false}
                  value={orderData.additionalInfo}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "additionalInfo")}
                  onBlur={(e) => validateField(e.target.value, addInfoValid, setAddInfoValid)}
                />
              ) : (
                <div className="value">{orderData.additionalInfo}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
            control={
              <Checkbox checked={notify === 1} onClick={() => handleToggleNotify(notify)} className="checkbox-notify" />
            }
            label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => cancelHandler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(Contact);
