import React, { useState, useEffect, FC, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { app } from "../../../actions/app";
import { auth } from "../../../actions/auth";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import Logo from "../../../assets/images/logo.svg";
import Phone from "../../../assets/images/phone.svg";
import PhoneDark from "../../../assets/images/phone-dark.svg";
import Envelop from "../../../assets/images/envelop.svg";
import EnvelopDark from "../../../assets/images/envelopDark.svg";
import Pen from "../../../assets/images/pen.svg";
import Exit from "../../../assets/images/exit.svg";
import Close from "../../../assets/images/close.svg";
import CloseDark from "../../../assets/images/closeDark.svg";
import Profile from "../../../assets/images/profile.svg";
import ProfileDark from "../../../assets/images/profileDark.svg";
import ProfileAdmin from "../../../assets/images/profile-admin.svg";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

// Styles
import "./style.scss";
import ThemeSwitcher from "../../molecules/themeSwitcher";
import Drawer from "@material-ui/core/Drawer";

type headerType = {
  app: any;
  user: any;
};

const Header: FC<headerType> = (props: headerType) => {
  const [open, setOpen] = useState(false);

  // to save url first part
  useEffect(() => {
    const activeLink = helpFunctions.getActiveLinkFromUrl();
    app.setActiveLink(activeLink ? activeLink : "orders");
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const editAdmin = () => {
    console.log("Edit");
  };

  const buildBackLink = () => {
    let linkData = {
      title: "",
      link: "",
    };
    if (Number(helpFunctions.getParamIdFromUrl())) {
      switch (helpFunctions.getActiveLinkFromUrl()) {
        case "orders":
          linkData = {
            title: "Orders",
            link: "/orders",
          };
          break;
        case "clients":
          linkData = {
            title: "Clients",
            link: "/clients",
          };
          break;
        case "csr":
          linkData = {
            title: "CSR",
            link: "/csr",
          };
          break;
        case "new-order":
          linkData = {
            title: "Client",
            link: "/clients/" + helpFunctions.getParamIdFromUrl(),
          };
          break;
      }
      return (
        <Link to={linkData.link} className="back-link">
          Back to {linkData.title}
        </Link>
      );
    }
    return "";
  };

  return (
    <div id="header">
      <Link to="/" className={props.app.isOpenMenu ? "logo" : "logo closed-menu"}>
        <img src={Logo} alt="logo" />
        <span>0.9.2</span>
      </Link>
      {buildBackLink()}

      <div className="controll-wrapper">
        <ThemeSwitcher />
        <Button
          className="profile"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <img src={ProfileAdmin} alt="profile-admin" />
          <span>{props.user.username}</span>
        </Button>
      </div>

      <Popper
        open={open}
        className="popper-wrapper"
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className="profile-menu">
                  <div className="top-part">
                    <div className="name">
                      <img src={helpFunctions.isDarkTheme() ? ProfileDark : Profile} alt="profile" />
                      <span>{props.user.username}</span>
                    </div>
                    <div className="actions">
                      {/*<Button onClick={editAdmin}>
                        <img src={Pen} alt="pen" />
                        <span>edit</span>
                      </Button>*/}
                      <Button className="close" onClick={handleClose}>
                        <img src={helpFunctions.isDarkTheme() ? CloseDark : Close} alt="close" />
                      </Button>
                    </div>
                  </div>
                  <div className="phone">
                    <img src={helpFunctions.isDarkTheme() ? PhoneDark : Phone} alt="phone" />
                    <span>{props.user.phone}</span>
                  </div>
                  <div className="email">
                    <img src={helpFunctions.isDarkTheme() ? EnvelopDark : Envelop} alt="email" />
                    <span>{props.user.email}</span>
                  </div>
                  <Button className="exit" onClick={() => auth.logout()}>
                    <img src={Exit} alt="exit" />
                    <span>log out</span>
                  </Button>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Header);
