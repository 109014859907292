import React, { useState, useEffect, useRef, FC, SyntheticEvent } from "react";
import { withRouter } from "react-router-dom";

// Components
import MainTemplate from "../templates/MainTemplate";

// Styles
import "./style.scss";
import { generalCRUD } from "../../../actions/generalCRUD";
import { toastr } from "react-redux-toastr";
import helpFunctions from "../../../tools/helpFunctions";
import TopPartPage from "../../molecules/TopPartPage";
import LoaderLocal from "../../atoms/LoaderLocal";
import { Button } from "@material-ui/core";

type constantsItemType = {
  id: string;
  name: string;
  value: string;
  [key: string]: string;
};

const constantsItemInitial = {
  id: "",
  name: "",
  value: "",
};

const ConstantsContainer: FC = () => {
  const [constantsList, setConstantsList] = useState<Array<constantsItemType>>([]);
  const [constantsItem, setConstantsItem] = useState<constantsItemType>(constantsItemInitial);
  const [lineEditing, setLineEditing] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // to get all entities
  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    if (lineEditing || lineEditing === 0) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [lineEditing]);
  const getAllConstants = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("value/constant");
    if (response.code === 200) {
      setConstantsList(response.data);
    }
    setIsLoading(false);
  };
  const editEntity = async (index: number) => {
    let valid = true;
    for (const key in constantsItem) {
      if (key === "id") continue;
      if (!constantsItem[key]) {
        valid = false;
        break;
      }
    }
    if (valid) {
      setIsLoading(true);
      const response = await generalCRUD.editConcreteData("value/constant", constantsItem.id, constantsItem);
      if (response.code === 200) {
        const newData: Array<constantsItemType> = [...constantsList];
        newData[index] = { ...constantsItem };
        setConstantsList(newData);
        setLineEditing(null);
        toastr.success("Your Event was edited successfully", "");
        return setIsLoading(false);
      }
      setIsLoading(false);
      return toastr.error("Error", response.message);
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };
  const textChangeHandler = (event: SyntheticEvent, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...constantsItem,
      value: value.replace(/[,]/g, "."),
    };
    setConstantsItem(newData);
  };
  const prepareToEdit = (data: constantsItemType, index: number) => {
    setConstantsItem(data);
    setLineEditing(index);
  };
  const cancelEdit = () => {
    setConstantsItem(constantsItemInitial);
    setLineEditing(null);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MainTemplate>
      <div id="constants">
        <TopPartPage title="Constants" />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th">Value</div>
          </div>
          <div className="th last action-title">Action</div>
        </div>
        <ul className="table-content">
          {constantsList.map((item: constantsItemType, index) => {
            return (
              <li className={lineEditing === index ? "table-content-item active" : "table-content-item"} key={index}>
                <div className="th first">{item.id}</div>
                <div className="th">{item.name}</div>
                <div className="th">
                  {lineEditing === index ? (
                    <input
                      type="text"
                      className="input-value"
                      ref={inputRef}
                      value={constantsItem.value}
                      onChange={(event) => textChangeHandler(event, "dec")}
                    />
                  ) : (
                    <div className="">{item.value}</div>
                  )}
                </div>
                <div className="th last">
                  <div className="action-wrap">
                    <div className="prepare-edit">
                      <div onClick={() => prepareToEdit(item, index)}>Edit</div>
                    </div>
                    <div className="edit-buttons">
                      <Button onClick={cancelEdit}>Cancel</Button>
                      <Button onClick={() => editEntity(index)}>Save</Button>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {isLoading && <LoaderLocal />}
      </div>
    </MainTemplate>
  );
};

export default withRouter(ConstantsContainer);
