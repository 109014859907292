/*
  Action on api
  https://github.com/public-apis/public-apis
*/
import axios from "axios";
import { CONSTS } from "../config/objectConst";

import { appApiType } from "../types/api/app";

export const app: appApiType = {
  uploadImage: async (token, data) => {
    return await axios({
      url: CONSTS.API.url + "images/upload",
      method: "post",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getEditorImages: async (token) => {
    return await axios({
      url: CONSTS.API.url + "images/editor_list",
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  changeArticlesOrder: async (token, data) => {
    return await axios({
      url: CONSTS.API.url + "article/position",
      method: "post",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getLivePreviewContent: async (token, data) => {
    return await axios({
      url: CONSTS.API.url + "template/email/live_preview",
      method: "post",
      headers: {
        "user-token": token,
      },
      data: {
        content: data,
      },
    });
  },
};
