/*
  Action on api
  https://github.com/public-apis/public-apis
*/
import axios from "axios";
import { CONSTS } from "../config/objectConst";

import { generalCRUDApiType } from "../types/api/generalCRUD";
import helpFunctions from "../tools/helpFunctions";

export const generalCRUD: generalCRUDApiType = {
  getAllData: async (path, token, page, sort, quantity, rest) => {
    const pageParam = page ? `page=${page}` : "";
    const sortParam = sort && sort.name ? `order%5B${sort.name}%5D=${sort.type}` : "";
    const quantityParam = quantity ? `per_page=${quantity}` : "";
    const restParam = rest ? rest : "";
    return await axios({
      url:
        CONSTS.API.url +
        `${path}/get_all${helpFunctions.buildResultParamsForApi(pageParam, sortParam, quantityParam)}${restParam}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  getConcreteData: async (path, token, id) => {
    return await axios({
      url: CONSTS.API.url + `${path}/get/${id}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  addNewData: async (path, token, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}/add`,
      method: "post",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  addNewClient: async (path, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}`,
      method: "post",
      data: data,
    });
  },
  editConcreteData: async (path, token, id, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}/edit/${id}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  editDeliveryData: async (path, token, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}/save`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  deleteData: async (path, token, id) => {
    return await axios({
      url: CONSTS.API.url + `${path}/delete/${id}`,
      method: "delete",
      headers: {
        "user-token": token,
      },
    });
  },
  editUserData: async (path, token, data, id) => {
    return await axios({
      url: CONSTS.API.url + `${path}${id}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getSimpleList: async (path, token) => {
    return await axios({
      url: CONSTS.API.url + `${path}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  getUserBookings: async (path, token, id) => {
    return await axios({
      url: CONSTS.API.url + `${path}/${id}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  changePassword: async (path, token, id, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}${id}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getFormulaResult: async (path, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}`,
      method: "post",
      data: data,
    });
  },
  editLocationItem: async (path, token, data) => {
    return await axios({
      url: CONSTS.API.url + `${path}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
};
