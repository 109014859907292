import React, { useState, useEffect, FC } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";

import { generalCRUD as generalCRUDApi } from "../../../api/generalCRUD";

import { FieldType } from "../../../validator/types";
import { requireInstance } from "../../../validator/Fields/instantLCLQuote";
import { validateAllFields, validateField } from "../../../validator/Fields";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// Styles
import "./style.scss";
import { useSelector } from "react-redux";
import { rootReducerType } from "../../../redux/reducers";
import { options } from "../../../actions/options";
import CustomPagination from "../../organisms/CustomPagination";
import { Switch } from "@material-ui/core";

const locationItemInitial = {
  cityId: "",
  depotId: "",
  visibility: false,
  precarFixed: "",
  precarVar: "",
  precarVarMin: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const LocationsContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [locationtList, setlocationtList] = useState<Array<any>>([]);
  const [locationItem, setlocationItem] = useState<any>(locationItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [citiesList, setCitiesList] = useState<Array<any>>([]);
  const [depotsList, setDepotsList] = useState<Array<any>>([]);

  /*Validation*/
  const [cityIdValid, setCityIdValid] = useState<FieldType>({ ...requireInstance });
  const [depotIdValid, setDepotIdValid] = useState<FieldType>({ ...requireInstance });
  const [precarFixedValid, setPrecarFixedValid] = useState<FieldType>({ ...requireInstance });
  const [precarVarValid, setPrecarVarValid] = useState<FieldType>({ ...requireInstance });
  const [precarVarMinValid, setPrecarVarMinValid] = useState<FieldType>({ ...requireInstance });

  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  // to get all entities
  useEffect(() => {
    getAllLocations(paginationSettings.page);
    getAllCities();
    getAllDepots();
    return () => options.setFilterParams({});
  }, []);

  const openEditModal = (data: any, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setlocationItem({ ...data, cityId: data.city.id, depotId: data.depot.id });
  };

  const allFields = [
    { validate: { ...cityIdValid, value: locationItem?.cityId }, setValidate: setCityIdValid },
    { validate: { ...depotIdValid, value: locationItem?.depotId }, setValidate: setDepotIdValid },
  ];

  const closeModal = (): void => {
    setlocationItem(locationItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const getAllCities = async () => {
    const response = await generalCRUD.getAllData("city", 1, null, 999);
    if (response.code === 200) {
      setCitiesList(response.data.items);
    }
  };

  const getAllDepots = async () => {
    const response = await generalCRUD.getAllData("value/depot", 1, null, 999);
    if (response.code === 200) {
      setDepotsList(response.data.items);
    }
  };

  const getAllLocations = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("admin/pick-up-location", page, undefined, quantity);
    if (response.code === 200) {
      setlocationtList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllLocations(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const switchStatus = (value: boolean): void => {
    setlocationItem({ ...locationItem, visibility: value });
  };

  const handleToggle = (status: boolean): void => {
    setlocationItem({ ...locationItem, visibility: !status });
  };

  const sendRequest = async (handleSuccess: (item) => void, forEdit?: boolean) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    closeModal();
    let response;
    const request = {
      cityId: locationItem.cityId,
      depotId: locationItem.depotId,
      visibility: locationItem.visibility,
      precarFixed: Math.round(parseFloat(locationItem.precarFixed)),
      precarVar: Math.round(parseFloat(locationItem.precarVar)),
      precarVarMin: Math.round(parseFloat(locationItem.precarVarMin)),
    };

    try {
      if (forEdit) {
        response = await generalCRUDApi.editLocationItem(
          `admin/pick-up-location/${locationItem.id}/edit`,
          token,
          request,
        );
      } else {
        response = await generalCRUD.addNewData("admin/pick-up-location", request);
      }
      console.log(response);
      if (response.status === 200 || response.code === 200) {
        getAllLocations(paginationSettings.page);
        //toastr.success("New Location was added successfully", "");
        //handleSuccess(response.data.data);
        return setIsLoading(false);
      }
      if (response.status === "error") {
        toastr.error("Error", helpFunctions.buildErrorString(response));
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastr.error("Error", response?.message);
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllLocations(paginationSettings.page);
      toastr.success("New Freight was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = (item) => {
      const newData: Array<any> = [...locationtList];
      newData[index] = {
        ...item,
      };
      setlocationtList(newData);
      toastr.success("Your Freight was edited successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest((item) => handleSuccess(item), true);
    }
  };

  const textChangeHandler = (event: React.ChangeEvent<{ value: unknown }>, field: string, num?: boolean | string) => {
    const value = event.target.value as string;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;

    const newData = {
      ...locationItem,
      [field]: value,
    };

    if (field === "cityId") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, cityIdValid, setCityIdValid);
    }

    if (field === "depotId") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, depotIdValid, setDepotIdValid);
    }

    setlocationItem(newData);
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">City</p>
          <div>
            <FormControl className="general-select">
              <Select
                value={locationItem.cityId ? locationItem.cityId : "0"}
                error={cityIdValid.valid === false}
                onChange={(event) => textChangeHandler(event, "cityId")}
              >
                <MenuItem className="no-value" value="0" disabled>
                  Select city
                </MenuItem>
                {citiesList &&
                  citiesList.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {cityIdValid.valid === false && <div className="freight-err-text">This field cannot be empty</div>}
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Link to Depot</p>
          <div>
            <FormControl className="general-select">
              <Select
                value={locationItem.depotId ? locationItem.depotId : "0"}
                error={depotIdValid.valid === false}
                onChange={(event) => textChangeHandler(event, "depotId")}
              >
                <MenuItem className="no-value" value="0" disabled>
                  Select depot
                </MenuItem>
                {depotsList &&
                  depotsList.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {depotIdValid.valid === false && <div className="freight-err-text">This field cannot be empty</div>}
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">PRECAR_FIXED</p>
          <div>
            <TextField
              helperText={precarFixedValid.error}
              error={precarFixedValid.valid === false}
              value={locationItem.precarFixed}
              type="number"
              placeholder="PRECAR_FIXED"
              onChange={(event) => textChangeHandler(event, "precarFixed", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">PRECAR_VAR</p>
          <div>
            <TextField
              helperText={precarVarValid.error}
              error={precarVarValid.valid === false}
              value={locationItem.precarVar}
              type="number"
              placeholder="PRECAR_VAR"
              onChange={(event) => textChangeHandler(event, "precarVar", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">PRECAR_VAR_MIN</p>
          <div>
            <TextField
              helperText={precarVarMinValid.error}
              error={precarVarMinValid.valid === false}
              value={locationItem.precarVarMin}
              type="number"
              placeholder="PRECAR_VAR_MIN"
              onChange={(event) => textChangeHandler(event, "precarVarMin", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Visibility</p>
          <p className="switcher-visibility">
            <span className="hover-cursor" onClick={() => switchStatus(false)}>
              Off
            </span>

            <Switch
              checked={locationItem?.visibility}
              name={"switcher-visibility-" + locationItem.id}
              style={{ color: "white" }}
              onClick={() => handleToggle(locationItem.visibility)}
            />
            <span className="hover-cursor" onClick={() => switchStatus(true)}>
              On
            </span>
          </p>
        </div>
      </div>
    </>
  );

  return (
    <MainTemplate>
      <div id="locations" className="page-container">
        <TopPartPage
          title="pick-up locations"
          buttonTitle="ADD NEW PICK-UP LOCATION"
          handleClick={() => setIsAddModalOpen(true)}
        />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th">Depot</div>
            <div className="th">PRECAR_FIXED</div>
            <div className="th">PRECAR_VAR</div>
            <div className="th">PRECAR_VAR_MIN</div>
            <div className="th">Visibility</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {locationtList &&
            locationtList.map((item: any, index) => {
              return (
                <li className="table-content-item" key={index}>
                  <div className="th first">{item.id}</div>
                  <div className="middle-part">
                    <div className="th">{item.city?.name}</div>
                    <div className="th normal">{item.depot?.name}</div>
                    <div className="th">{item.precarFixed}</div>
                    <div className="th">{item.precarVar}</div>
                    <div className="th">{item.precarVarMin}</div>
                    <div className="th">{item.visibility ? "On" : "Off"}</div>
                  </div>
                  <div className="th last">
                    <span onClick={() => openEditModal(item, index)}>Edit</span>
                  </div>
                </li>
              );
            })}
        </ul>
        {isLoading && <LoaderLocal />}
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={locationtList.length}
          handleClickPage={(value) => getAllLocations(value)}
          handleClickQuantity={(quantity) => getAllLocations(paginationSettings.page, quantity)}
        />
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add new pick-up location"
          classes="freight-modal"
          description="Here you can add new Pick-up Location"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit new pick-up location"
          classes="freight-modal"
          description="Here you can edit Pick-up Location"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(LocationsContainer);
