import React, { useState, FC, useEffect, useCallback } from "react";
import helpFunctions from "../../../../tools/helpFunctions";
import AnimateHeight from "react-animate-height";
import { TDocument, TDocuments } from "../../../containers/OrdersConcrete";
import ExtensionBox from "../../../atoms/ExtensionBox";

// Components

// Styles
import "./style.scss";

type TDocExchangePropsType = {
  documentsExchange: TDocuments;
};

const DocExchange: FC<any> = (props: TDocExchangePropsType) => {
  const [adminHeight, setAdminHeight] = useState<"auto" | 0>("auto");
  const [userHeight, setUserHeight] = useState<"auto" | 0>("auto");
  const [csrHeight, setCsrHeight] = useState<"auto" | 0>("auto");

  const hideHandler = useCallback((setHeight) => {
    setHeight((prevHeight) => {
      return prevHeight === 0 ? "auto" : 0;
    });
  }, []);

  return (
    <div id="doc-exchange">
      {props.documentsExchange?.user.length ? (
        <div className="doc-exchange__user doc-exchange__wrapper">
          <div className="tab-header">
            <div
              className={`tab-header-title${userHeight ? " active" : ""}`}
              onClick={() => hideHandler(setUserHeight)}
            >
              User`s documents
              <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M4 4.88281L4.26909e-07 -0.000458463L8 -0.000457764L4 4.88281Z" fill="#F8A64C" />
              </svg>
            </div>
          </div>
          <div className="doc-exchange__content">
            <AnimateHeight duration={500} height={userHeight}>
              {props.documentsExchange.user.map((item: TDocument) => (
                <div className="doc-exchange__item" key={item.id}>
                  <p className="history-date">
                    <span>{helpFunctions.getDateFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}</span>
                    {helpFunctions.getTimeFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}
                  </p>
                  <ExtensionBox fileName={item.name} colour={2} />
                  <span className="document-name">{item.name}</span>
                </div>
              ))}
            </AnimateHeight>
          </div>
        </div>
      ) : null}
      {props.documentsExchange?.admin.length ? (
        <div className="doc-exchange__admin doc-exchange__wrapper">
          <div className="tab-header">
            <div
              className={`tab-header-title${adminHeight ? " active" : ""}`}
              onClick={() => hideHandler(setAdminHeight)}
            >
              Admin’s documents
              <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M4 4.88281L4.26909e-07 -0.000458463L8 -0.000457764L4 4.88281Z" />
              </svg>
            </div>
          </div>
          <div className="doc-exchange__content">
            <AnimateHeight duration={500} height={adminHeight}>
              {props.documentsExchange.admin.map((item: TDocument) => (
                <div className="doc-exchange__item" key={item.id}>
                  <p className="history-date">
                    <span>{helpFunctions.getDateFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}</span>
                    {helpFunctions.getTimeFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}
                  </p>
                  <ExtensionBox fileName={item.name} colour={1} />
                  <span className="document-name">{item.name}</span>
                </div>
              ))}
            </AnimateHeight>
          </div>
        </div>
      ) : null}
      {props.documentsExchange?.csr.length ? (
        <div className="doc-exchange__csr doc-exchange__wrapper">
          <div className="tab-header">
            <div className={`tab-header-title${csrHeight ? " active" : ""}`} onClick={() => hideHandler(setCsrHeight)}>
              CSR’s documents
              <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M4 4.88281L4.26909e-07 -0.000458463L8 -0.000457764L4 4.88281Z" />
              </svg>
            </div>
          </div>
          <div className="doc-exchange__content">
            <AnimateHeight duration={500} height={csrHeight}>
              {props.documentsExchange.csr.map((item: TDocument) => (
                <div className="doc-exchange__item" key={item.id}>
                  <p className="history-date">
                    <span>{helpFunctions.getDateFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}</span>
                    {helpFunctions.getTimeFromUnix(Math.round(Date.parse(item.createdAt) / 1000))}
                  </p>
                  <ExtensionBox fileName={item.name} colour={1} />
                  <span className="document-name">{item.name}</span>
                </div>
              ))}
            </AnimateHeight>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DocExchange;
