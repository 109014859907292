import React, { useEffect, FC } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Components
import MainTemplate from "../templates/MainTemplate";

// Styles
import "./style.scss";

const CsrContainer: FC = props => {
  return (
    <MainTemplate>
      <div id="csr">Csr</div>
    </MainTemplate>
  );
};

const mapStateToProps = function(state: any) {
  return {
    app: state.app
  };
};

export default connect(mapStateToProps)(withRouter(CsrContainer));
