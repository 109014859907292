/*
 *
 * app reducer
 *
 */
const initialState = {
  isLogged: false,
  isLoadedPage: false,
  isValidToken: true,
  isOpenMenu: true,
  activeLink: "orders",
  activeTabTemplate: 0,
  activeTabBlog: 0,
  quantityPerPage: 10,
  quantityPagTemplate: [10, 50, 100, 200],
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_IN":
      return Object.assign({}, state, {
        isLogged: action.payload.isLogged,
      });

    case "LOADED_PAGE":
      return Object.assign({}, state, {
        isLoadedPage: action.payload.isLoadedPage,
      });

    case "VALID_TOKEN":
      return Object.assign({}, state, {
        isValidToken: action.payload.isValidToken,
      });

    case "OPEN_MENU":
      return Object.assign({}, state, {
        isOpenMenu: action.payload.isOpenMenu,
      });

    case "ACTIVE_LINK":
      return Object.assign({}, state, {
        activeLink: action.payload.activeLink,
      });

    case "ACTIVE_TAB_TEMPLATE":
      return Object.assign({}, state, {
        activeTabTemplate: action.payload.activeTab,
      });

    case "ACTIVE_TAB_BLOG":
      return Object.assign({}, state, {
        activeTabBlog: action.payload.activeTab,
      });

    case "QUANTITY_PER_PAGE":
      return Object.assign({}, state, {
        quantityPerPage: action.payload.quantityPerPage,
      });

    case "QUANTITY_PAG_TEMPLATE":
      return Object.assign({}, state, {
        quantityPagTemplate: action.payload.quantityPagTemplate,
      });

    case "THEME_SWITCHER":
      return Object.assign({}, state, {
        currentTheme: action.payload.currentTheme,
      });

    default:
      return state;
  }
};

export default app;
