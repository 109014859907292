import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import TextField from "@material-ui/core/TextField";
// import Menu from "@material-ui/core/Menu/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import { requireInstance } from "../../../validator/Fields/instantLCLQuote";

// Styles
import "./style.scss";
import { Autocomplete } from "@material-ui/lab";
import { FormControl, MenuItem, Select } from "@material-ui/core";

export type hintsItemType = {
  id: number;
  title: string;
  content: string;
  [key: string]: string | number;
};

export const hintsItemInitial = {
  id: 1,
  title: "",
  content: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const hintOptions = [
  { id: 0, name: "None", value: 0 },
  { id: 1, name: "Supplier pays all origin fees (FOB)", value: 1 },
  { id: 2, name: "Drop-Off by Supplier", value: 2 },
  { id: 3, name: "Pick-Up from Supplier", value: 3 },
];

const HintsContainer: FC = () => {
  const [hintsList, setHintsList] = useState<Array<hintsItemType>>([]);
  const [hintsItem, setHintsItem] = useState<hintsItemType>(hintsItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorElHint, setAnchorElHint] = React.useState<Array<null | HTMLElement>>([null]);
  const [deleteHintId, setDeleteHintId] = useState<number>(0);

  /*Validation*/
  const [hintsOrderIdValid, setHintsOrderIdValid] = useState<FieldType>({ ...requireInstance });
  const [titleValid, setTitleValid] = useState<FieldType>({ ...maxLengthInstance(100), value: hintsItem.title });
  const [contentValid, setContentValid] = useState<FieldType>({
    ...maxLengthInstance(65000),
    value: hintsItem.content,
  });

  // to get all entities
  useEffect(() => {
    getAllHints();
  }, []);

  const openEditModal = (data: hintsItemType, index: number): void => {
    handleActionMenu(null, index);
    setEditModal({
      status: true,
      index: index,
    });
    setHintsItem(data);
  };

  const allFields = [
    { validate: titleValid, setValidate: setTitleValid },
    { validate: contentValid, setValidate: setContentValid },
  ];

  const closeModal = (): void => {
    setHintsItem(hintsItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
    });
  };

  const getAllHints = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("hint"); // replace to right URL
    if (response.code === 200) {
      setHintsList(response.data.items);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    setIsLoading(true);
    closeModal();
    let response;
    if (forEdit) {
      response = await generalCRUD.editConcreteData("hint", hintsItem.id, {
        title: hintsItem.title,
        content: hintsItem.content,
        shipmentTradeTerms: hintsItem.shipmentTradeTerms === 0 ? null : hintsItem.shipmentTradeTerms,
      }); // replace to right URL
    } else {
      response = await generalCRUD.addNewData("hint", { title: hintsItem.title, content: hintsItem.content }); // replace to right URL
    }

    if (response.code === 200) {
      handleSuccess();
      setIsLoading(false);
      return response;
    }
    setIsLoading(false);
    return toastr.error("Error", response.message);
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllHints();
      toastr.success("New Hint was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<hintsItemType> = [...hintsList];
      newData[index] = { ...hintsItem };
      setHintsList(newData);
      toastr.success("Your Hint was edited successfully", "");
    };
    sendRequest(handleSuccess, true);
  };

  const textChangeHandler = (event: any, field: string, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...hintsItem,
      [field]: value,
    };

    if (field === "shipmentTradeTerms") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, hintsOrderIdValid, setHintsOrderIdValid);
    }

    setHintsItem(newData);
  };

  const openDeleteModal = (id: number, index: number) => {
    handleActionMenu(null, index);
    setDeleteHintId(id);
  };

  const handleActionMenu = (value, index: number) => {
    const anch = [...anchorElHint];
    anch[index] = value;
    setAnchorElHint(anch);
  };

  const deleteHint = async () => {
    const id = deleteHintId;
    setDeleteHintId(0);
    const response = await generalCRUD.deleteData("hint", id);
    if (response.code === 200) {
      toastr.success(`Address was deleted successfully`, "");
      const newDataList: Array<hintsItemType> = hintsList.filter((item: hintsItemType) => {
        return item.id !== id;
      });
      setHintsList(newDataList);
    }
  };
  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Title</p>
          <div>
            <TextField
              helperText={titleValid.error}
              error={titleValid.valid === false}
              value={hintsItem.title}
              className="base-input"
              InputProps={{ disableUnderline: true, inputProps: { maxLength: titleValid.maxLength } }}
              type="text"
              placeholder="Title of your hint"
              onChange={(event) => textChangeHandler(event, "title")}
              onBlur={(e) => validateField(e.target.value, titleValid, setTitleValid)}
            />
          </div>
        </div>
      </div>
      {hintsItem.type === "form" && (
        <div className="unit-wrap">
          <div className="unit">
            <p className="sub-title">Shipment Trade Terms</p>
            <div>
              <FormControl className="general-select">
                <Select
                  value={hintsItem.shipmentTradeTerms ? hintsItem.shipmentTradeTerms : "0"}
                  error={hintsOrderIdValid.valid === false}
                  onChange={(event) => textChangeHandler(event, "shipmentTradeTerms")}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem className="no-value" value="0" disabled>
                    Select order hint
                  </MenuItem>
                  {hintOptions &&
                    hintOptions.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {/*hintsOrderIdValid.valid === false && <div className="freight-err-text">This field cannot be empty</div>*/}
            </div>
          </div>
        </div>
      )}
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Content</p>
          <div>
            <TextField
              helperText={contentValid.error}
              error={contentValid.valid === false}
              value={hintsItem.content}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: contentValid.maxLength } }}
              type="text"
              multiline
              rows={3}
              rowsMax={6}
              placeholder="Your content to hint"
              className="base-input"
              onChange={(event) => textChangeHandler(event, "content")}
              onBlur={(e) => validateField(e.target.value, contentValid, setContentValid)}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <MainTemplate>
      <div id="hints" className="page-container">
        <TopPartPage title="Hints" />
        {/*<TopPartPage title="Hints" buttonTitle="add new hint" handleClick={() => setIsAddModalOpen(true)} />*/}
        <p className="top-part-subtitle">Here you can edit hints for clients, used on the website</p>
        <div className="table-thead">
          {/*<div className="th first">ID</div>*/}
          <div className="middle-part">
            <div className="th title">Title</div>
            <div className="th content">Content</div>
            <div className="th">Shipment Trade Terms</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {hintsList &&
            hintsList.map((item: hintsItemType, index) => {
              return (
                <li className="table-content-item" key={index}>
                  {/*<div className="th first">{item.id}</div>*/}
                  <div className="th title">{item.title}</div>
                  <div className="th content">{item.content}</div>
                  <div className="th">
                    {item.shipmentTradeTerms ? hintOptions[Number(item.shipmentTradeTerms)].name : ""}
                  </div>
                  <div className="th last">
                    <span onClick={() => openEditModal(item, index)}>Edit</span>
                  </div>
                  {/*<div className="th actions last">
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleActionMenu(event.currentTarget, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElHint[index]}
                    keepMounted
                    className="action-menu"
                    open={!!anchorElHint[index]}
                    onClose={() => handleActionMenu(null, index)}
                  >
                    <MenuItem onClick={() => openEditModal(item, index)}>Edit</MenuItem>
                    <MenuItem className="delete" onClick={() => openDeleteModal(item.id, index)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>*/}
                </li>
              );
            })}
        </ul>
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          classes="event-modal hint-textarea"
          title="Add hint"
          description="You can add a content for hint here"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          classes="event-modal hint-textarea"
          title="Edit hint"
          description="You can edit a content for hint here"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={!!deleteHintId}
          isDeleting={true}
          handleClose={() => setDeleteHintId(0)}
          handleSubmit={deleteHint}
          title="Delete an hint"
          description="You can delete hint"
        >
          &nbsp;
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(HintsContainer);
