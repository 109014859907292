import React, { useEffect, FC, useState } from "react";
import { useHistory } from "react-router-dom";
import helpFunctions from "../../../tools/helpFunctions";
import { generalCRUD } from "../../../actions/generalCRUD";
import { app } from "../../../actions/app";
import {
  initialHtmlBlock,
  initialMail,
  initialVariable,
  HtmlBlockType,
  MailType,
  VariableType,
} from "../TemplatesPage";
import withStyles from "@material-ui/core/styles/withStyles";

import { modalPreviewType } from "../SetEvent";

// Components
import { toastr } from "react-redux-toastr";
import LoaderLocal from "../../atoms/LoaderLocal";
import GeneralButton from "../../atoms/GeneralButton";
import WEditor from "../../molecules/WEditor";
import { Tooltip } from "@material-ui/core";
import ModalWindow from "../../molecules/ModalWindow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ThemeSwitcher from "../../molecules/themeSwitcher";

// Styles
import "./style.scss";
import {FieldType} from "../../../validator/types";
import {maxLengthInstance} from "../../../validator/Fields/instantLCLQuote";
import TextField from "@material-ui/core/TextField";
import {validateAllFields, validateField} from "../../../validator/Fields";

const Mail: FC<any> = () => {
  const history = useHistory();
  const [mailData, setMailData] = useState<MailType>(initialMail);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variableList, setVariableList] = useState<Array<VariableType>>([initialVariable]);
  const [htmlBlockList, setHtmlBlockList] = useState<Array<HtmlBlockType>>([initialHtmlBlock]);
  const [exitModal, setExitModal] = useState<string | null>(null);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [modalPreview, setModalPreview] = useState<modalPreviewType>({
    status: false,
    content: "",
  });

  /*Validation*/
  const [mailTitleValid, setMailTitleValid] = useState<FieldType>({...maxLengthInstance(150), value: mailData.title})
  const [subjectValid, setSubjectValid] = useState<FieldType>({...maxLengthInstance(255), value: mailData.subject})
  const [contentValid, setContentValid] = useState<boolean>(null)
  const allFields = [
    {validate: {...mailTitleValid, value: mailData.title}, setValidate: setMailTitleValid},
    {validate: {...subjectValid, value: mailData.subject}, setValidate: setSubjectValid},
  ]

  const mailId = helpFunctions.getParamIdFromUrl();

  useEffect(() => {
    getAddTools("template/variable", setVariableList);
    getAddTools("template/block", setHtmlBlockList);

    if (mailId) {
      getMailData();
    }

    return () => {
      window.removeEventListener("popstate", showExitModalBackAction);
    };
  }, []);

  useEffect(() => {
    if (isEdited) {
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.addEventListener("popstate", showExitModalBackAction, false);
    }
  }, [isEdited]);

  const getAddTools = async (url: string, callback) => {
    const response = await generalCRUD.getAllData(url, null, null, 1000);
    if (response.code === 200) {
      callback(response.data.items);
      return response.data;
    } else {
      toastr.error("Error", response.message);
    }
  };

  const getMailData = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getConcreteData("template/email", Number(mailId));
    if (response.code === 200) {
      setMailData(response.data);
      setIsLoading(false);
    } else {
      history.push(`/templates`);
      toastr.error("Error", response.message);
    }
  };

  const inputTextHandler = (value: string, field: string) => {
    setMailData({
      ...mailData,
      [field]: value,
    });
    if (!isEdited) setIsEdited(true);
    if (field === "content") {
      if (value.length) {
        setContentValid(true)
      } else {
        setContentValid(false)
      }
    }
  };

  const cancelCreationMail = () => {
    if (isEdited) return setExitModal("cancel");
    confirmExit();
  };

  const showExitModalBackAction = () => {
    setExitModal("back");
  };

  const confirmExit = () => {
    if (exitModal === "back") {
      if (window.history.state.name) {
        history.goBack();
      }
      return history.goBack();
    }
    history.push("/templates");
  };

  const openPreview = () => {
    if (!mailData.content) return toastr.error("Error", "Mail cannot be empty");
    app.openLivePreview(setModalPreview, mailData.content);
  };

  const submitMail = async () => {
    setIsLoading(true);
    const resultObject = {
      title: mailData.title,
      content: mailData.content,
      subject: mailData.subject,
    };
    if (!resultObject.content) {
      setContentValid(false)
    } else {
      setContentValid(true)
    }

    if (validateAllFields(allFields) && contentValid) {
      let response;
      if (!mailId) {
        response = await generalCRUD.addNewData("template/email", resultObject);
      } else {
        response = await generalCRUD.editConcreteData("template/email", mailData.id, resultObject);
      }
      if (response.code === 200) {
        !mailId
            ? toastr.success("New mail was created successfully", "")
            : toastr.success("Mail was edited successfully", "");
        history.push(`/templates`);
      } else {
        toastr.error("Error", response.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const VarTooltip = withStyles(() => ({
    tooltip: {
      padding: 7,
      fontSize: 13,
      fontFamily: "OpenSans",
      backgroundColor: "#313131",
    },
  }))(Tooltip);

  const variablesBox = (
    <div className="var-wrap">
      <div className="var-head">Available variables</div>
      <div className="var-content">
        <div className="var-title-wrap">
          <div className="var-title">Name</div>
          <div className="var-title">Identifier</div>
        </div>
        <ul className="var-list">
          {variableList.map((item, index) => {
            return (
              <li key={index}>
                <VarTooltip title={item.description || ""} placement="left" interactive>
                  <div className="var-name">{item.name}</div>
                </VarTooltip>
                <div className="var-value">{`{{ ${item.identifier} }}`}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  const htmlBlockBox = (
    <div className="var-wrap">
      <div className="var-head">Available html-blocks</div>
      <div className="var-content">
        <div className="var-title-wrap">
          <div className="var-title">Name</div>
          <div className="var-title">Identifier</div>
        </div>
        <ul className="var-list">
          {htmlBlockList.map((item, index) => {
            return (
              <li key={index}>
                <div className="var-name">{item.title}</div>
                <div className="var-value">{`{% '${item.identifier}' %}`}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  return (
    <div id="create-mail">
      <div className="hide-switcher"><ThemeSwitcher/></div>
      <form onSubmit={(event) => event.preventDefault()} className="content">
        <div className="content-item top-title">mail constructor</div>
        <div className="content-item inputs">
          <div>
            <p className="title">Set mail title:</p>
            <TextField
              helperText={mailTitleValid.error}
              error={mailTitleValid.valid === false}
              value={mailData.title}
              className="base-input"
              placeholder="Mail title"
              InputProps={{ disableUnderline: true, inputProps: {maxLength: mailTitleValid.maxLength} }}
              type="text"
              onChange={(event) => inputTextHandler(event.target.value, "title")}
              onBlur={(e) => validateField(e.target.value, mailTitleValid, setMailTitleValid)}
            />
          </div>
          <div>
            <p className="title">Add subject:</p>
            <TextField
              helperText={subjectValid.error}
              error={subjectValid.valid === false}
              value={mailData.subject}
              placeholder="Mail subject"
              className="base-input"
              InputProps={{ disableUnderline: true, inputProps: {maxLength: subjectValid.maxLength} }}
              type="text"
              onChange={(event) => inputTextHandler(event.target.value, "subject")}
              onBlur={(e) => validateField(e.target.value, subjectValid, setSubjectValid)}
            />
          </div>
        </div>
        <div className="content-item">
          <div className="editor">
            <p className="title">Make mail:</p>
            <div className={contentValid === false ? 'editor-err': 'editor-wrapper'}>
              <WEditor
                content={mailData.content}
                height={920}
                uploadImage={app.uploadImage}
                handleEditorChange={(value) => inputTextHandler(value, "content")}
              />
            </div>
            <div className={contentValid === false ? 'editor-show-err editor-err-text': 'editor-err-text'}>This block cannot be empty</div>
          </div>
          <div className="additional">
            <p className="title">Copy blocks you need:</p>
            <div className="var-tab-wrap">
              <Tabs
                value={tabValue}
                onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
                  setTabValue(newValue);
                }}
              >
                <Tab label="Variables" />
                <Tab label="Html blocks" />
              </Tabs>
            </div>
            <div>
              {tabValue === 0 && variablesBox}
              {tabValue === 1 && htmlBlockBox}
            </div>
            <div className="var-wrap tools">
              <div className="var-head tool">To insert &apos;if&apos; operator</div>
              <div className="body">
                <span>{`{% if variable %}`}</span>
                <span className="text">your content</span>
                <span>{`{% endif %}`}</span>
              </div>
              <div className="body">
                <span>{`
                  {% if variable %}`}</span>
                <span className="text">your content</span>
                <span>{`{% else %}`}</span>
                <span className="text">your content</span>
                <span>{`{% endif %}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <GeneralButton classes="cancel" handleClick={cancelCreationMail}>
            Cancel
          </GeneralButton>
          <GeneralButton classes="preview" handleClick={openPreview}>
            Preview
          </GeneralButton>
          <GeneralButton classes="submit" type="submit" handleClick={submitMail}>
            Submit
          </GeneralButton>
        </div>
      </form>
      {isLoading && <LoaderLocal />}
      <ModalWindow
        open={!!exitModal}
        handleClose={() => setExitModal(null)}
        handleSubmit={confirmExit}
        title="Attention!"
        description="Leaving this page with lead to losing of the created content."
        submitTitle="leave"
        closeTitle="stay here"
        isDeleting={true}
      />
      <ModalWindow
        classes="preview"
        open={modalPreview.status}
        handleClose={() =>
          setModalPreview({
            status: false,
            content: "",
          })
        }
        title="Preview"
      >
        {!modalPreview.content ? (
          <LoaderLocal />
        ) : (
          <iframe srcDoc={modalPreview.content} width={670} height={500} className="preview-frame">
            Your browser does not support inline frames!
          </iframe>
        )}
      </ModalWindow>
    </div>
  );
};

export default Mail;
