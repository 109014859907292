import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";

// Components
import { orderDataType } from "../../../../types/actions/orders";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { toastr } from "react-redux-toastr";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Styles
import "./style.scss";
import TextField from "@material-ui/core/TextField";
import {
  defaultValidationProperties,
  validateAllFields,
  validateField,
  validateFloatNumber,
} from "../../../../validator/Fields";
import { FieldType } from "../../../../validator/types";
import { type } from "os";
import { regularInstance } from "../../../../validator/Fields/instantLCLQuote";
import helpFunctions from "../../../../tools/helpFunctions";
import { ListSubheader } from "@material-ui/core";

type orderDetailsType = {
  orderData: orderDataType;
};

type depotsItemType = {
  id: number;
  name: string;
  tier: number;
  status: boolean;
};

const depotsItemInitial = {
  id: 0,
  name: "",
  tier: 0,
  status: true,
};

type portsItemType = {
  code: string;
  createdAt: string;
  id: number;
  name: string;
  status: boolean;
  updatedAt: string;
};

const portsItemInitial = {
  code: "",
  createdAt: "",
  id: 1,
  status: false,
  name: "",
  updatedAt: "",
};

export const requiredField: FieldType = {
  ...defaultValidationProperties,
  dependencies: [{ depCase: "required" }],
};

const QuoteSummary: FC<any> = (props: orderDetailsType) => {
  const [loadPage, setLoadPage] = useState<any>(false);
  const [depotsList, setDepotsList] = useState<[depotsItemType]>([depotsItemInitial]);
  const [portList, setPortList] = useState<[portsItemType]>([portsItemInitial]);
  const dispatch = useDispatch();

  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);
  const { shipmentWeight, estimatedTransportation, shipmentVolume } = props.orderData;
  // const [weightValidation, setWeightValidation] = useState<FieldType>({...regularInstance('^[a-zA-Z1-9-]*$', 3, 'Invalid field. You can only use letter, number and dashes', true), value: shipmentWeight});

  const [radioArray1, setRadioArray1] = useState<any>([]);
  const [radioArray2, setRadioArray2] = useState<any>([]);
  const [radioArray3, setRadioArray3] = useState<any>([]);
  const [radioArray4, setRadioArray4] = useState<any>([]);
  const [radioArrayLoad, setRadioArrayLoad] = useState<boolean>(true);

  const [weightValidation, setWeightValidation] = useState<FieldType>({
    ...requiredField,
    value: shipmentWeight,
    defaultValue: shipmentWeight,
  });
  const [costValidation, setCostValidation] = useState<FieldType>({
    ...requiredField,
    value: estimatedTransportation,
    defaultValue: estimatedTransportation,
  });
  const [volumeValidation, setVolumeValidation] = useState<FieldType>({
    ...requiredField,
    value: shipmentVolume,
    defaultValue: shipmentVolume,
  });

  useEffect(() => {
    setLoadPage(true);
    getRadioArrays();
    //getDepotsList(1, 1000);
    getAllCartages(1, 1000);
  }, []);

  const getRadioArrays = async () => {
    try {
      await Promise.all([getDepotsList(1, 1000), getAllPickUpLocation(), getAllOriginType1(), getAllOriginType2()]);
      setRadioArrayLoad(false);
    } catch (e) {}
  };

  useEffect(() => {
    //setCalculatorData({ ...calculatorData, depotId: null });
    switch (orderData.shipmentTradeTerms) {
      case 3:
        setDepotsList(radioArray1);
        break;
      case 2:
        setDepotsList(radioArray2);
        break;
      case 1:
        setDepotsList(radioArray3);
        break;
      case 0:
        setDepotsList(radioArray4);
        break;

      default:
        break;
    }
  }, [orderData.shipmentTradeTerms, radioArrayLoad]);

  useEffect(() => {
    if (loadPage) {
      setOrderData((prevState: any) => ({
        ...prevState,
        shipmentOriginPort: null,
      }));
    }
  }, [orderData.shipmentTradeTerms]);

  useEffect(() => {
    if (loadPage) {
      setOrderData((prevState: any) => ({
        ...prevState,
        shipmentOriginPort: prevState.shipmentOriginPort === null ? depotsList[0]?.id : prevState.shipmentOriginPort,
      }));
    }
  }, [depotsList]);

  useEffect(() => {
    orderData.shipmentTradeTerms && radioArray1 && setDepotsList(radioArray1);
  }, [radioArray1]);

  useEffect(() => {
    //const id = getDefaultSelectId(depotList);
    /*if (depotsList) {
      setOrderData((prevState: any) => ({
        ...prevState,
        shipmentOriginPort: depotsList[0]?.id ? depotsList[0]?.id : "",
      }));
    }*/
    //return setCalculatorData((prevState) => ({ ...prevState, depotId: id }));
  }, [depotsList.length]);

  /* ALL SELECT ACTIONS */
  const getAllPickUpLocation = async () => {
    const response = await generalCRUD.getAllData("admin/pick-up-location", undefined, null, undefined);
    if (response.code === 200) {
      setRadioArray1(
        response.data.items.map((item) => {
          return { id: item.id, name: item.city.name, code: item.city.code };
        }),
      );
    }
  };

  const getAllOriginType1 = async () => {
    const response = await generalCRUD.getAllData(
      `admin/value/origin/get_all?shipment_trade_terms=2`,
      undefined,
      null,
      undefined,
    );
    if (response.code === 200) {
      setRadioArray2(response.data.items[0].depot);
    }
  };

  const getAllOriginType2 = async () => {
    const response = await generalCRUD.getAllData(
      `admin/value/origin/get_all?shipment_trade_terms=1`,
      undefined,
      null,
      undefined,
    );
    if (response.code === 200) {
      setRadioArray3(response.data.items[0].depot);
    }
  };

  const getDepotsList = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("value/depot", page, null, quantity);
    if (response.code === 200) {
      setRadioArray4(response.data.items);
    }
  };

  const getAllCartages = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("value/cartage-cartegory", page, null, quantity);
    if (response.code === 200) {
      setPortList(response.data.items);
    }
  };

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const fields = [
    { validate: weightValidation, setValidate: setWeightValidation },
    { validate: costValidation, setValidate: setCostValidation },
    { validate: volumeValidation, setValidate: setVolumeValidation },
  ];

  const canselHendler = () => {
    fields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({
          ...prevState,
          value: field.validate.defaultValue,
          valid: null,
          error: "",
        }));
      }
    });
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields(fields)) {
      sendRequest(orderData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id: any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const selectChangeandler = (event: React.ChangeEvent<{ value: unknown }>, key: string) => {
    const value = event.target.value as string;
    const newData = {
      ...orderData,
      [key]: value,
    };

    setOrderData(newData);
  };

  const textChangeHandler = (event: SyntheticEvent, key: string, num) => {
    let value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    if (value.includes("-") || value.includes("+")) return false;
    if (key === "shipmentWeight" || key === "shipmentVolume") {
      value = validateFloatNumber(value, 3);
    }
    if (key === "estimatedTransportation" || key === "shipmentValue") {
      value = validateFloatNumber(value, 2);
    }
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1) : setNotify(0);
  };

  const parseShipmentTradeTerms = (index: number) => {
    switch (index) {
      case 0:
        return "Other";
      case 3:
        return "Pick-Up from Supplier";
      case 2:
        return "Drop-Off by Supplier";
      case 1:
        return "Supplier pays all origin fees (FOB)";
      default:
        return "";
    }
  };

  const renderSelectGroup = (element: any) => {
    if (element.cartages) {
      const items = element.cartages.map((p) => {
        return (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        );
      });
      return [<ListSubheader key={element.id}>{element.name}</ListSubheader>, items];
    }
  };

  const renderSelectName = (element: any) => {
    let name = "";
    element.cartages &&
      element.cartages.map((p) => {
        if (orderData.shipmentDestination === p.id) {
          name = p.name;
        }
      });

    return name;
  };
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="quote-summary">
      <div className="tab-header">
        <div className="tab-header-title">QUOTE SUMMARY</div>
        <div onClick={editHendler} className={!lineEditing ? "show" : "hide"}>
          Edit
        </div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">Transportation Mode:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl>
                  <Select
                    value={orderData.transportationMode}
                    onChange={(event) => selectChangeandler(event, "transportationMode")}
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem value={0}>LCL Less than Container Load</MenuItem>
                    <MenuItem value={1}>FCL Full Container Load</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div className="description-summary">
                  {orderData.transportationMode === 0 ? "LCL Less than Container Load" : "FCL Full Container Load"}
                </div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment Destination:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl className="select-wrapper">
                  <Select
                    disableUnderline
                    value={orderData.shipmentDestination ? String(orderData.shipmentDestination) : ""}
                    onChange={(event) => selectChangeandler(event, "shipmentDestination")}
                  >
                    {portList && portList?.map((element) => renderSelectGroup(element))}
                  </Select>
                </FormControl>
              ) : (
                <div className="value">{portList && portList?.map((element) => renderSelectName(element))}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment Origin:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl>
                  <Select
                    value={orderData.shipmentOriginPort}
                    onChange={(event) => selectChangeandler(event, "shipmentOriginPort")}
                    displayEmpty
                    disableUnderline
                  >
                    {depotsList.map((item: depotsItemType, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <div className="value">
                  {depotsList.map((item: depotsItemType) => {
                    return orderData.shipmentOriginPort === item.id ? item.name : null;
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment Weight:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={weightValidation.error}
                  error={weightValidation.valid === false}
                  value={orderData.shipmentWeight}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  onChange={(event) => textChangeHandler(event, "shipmentWeight", "dec")}
                  onBlur={(e) => validateField(e.target.value, weightValidation, setWeightValidation)}
                />
              ) : (
                <div className="value">{orderData.shipmentWeight}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Customs Brokerage:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl>
                  <Select
                    value={orderData.customBrokerage ? 1 : 0}
                    onChange={(event) => selectChangeandler(event, "customBrokerage")}
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div className="value">{orderData.customBrokerage ? "Yes" : "No"}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">Shipment Trade Terms:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl>
                  <Select
                    value={orderData.shipmentTradeTerms}
                    onChange={(event) => selectChangeandler(event, "shipmentTradeTerms")}
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem value={3}>Pick-Up from Supplier</MenuItem>
                    <MenuItem value={2}>Drop-Off by Supplier</MenuItem>
                    <MenuItem value={1}>Supplier pays all origin fees (FOB)</MenuItem>
                    <MenuItem value={0}>Other</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div className="value">{parseShipmentTradeTerms(orderData.shipmentTradeTerms)}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Estimated Transportation Cost:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={costValidation.error}
                  error={costValidation.valid === false}
                  value={orderData.estimatedTransportation}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true }}
                  onChange={(event) => textChangeHandler(event, "estimatedTransportation", "dec")}
                  onBlur={(e) => validateField(e.target.value, costValidation, setCostValidation)}
                />
              ) : (
                <div className="value">{orderData.estimatedTransportation}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment Volume:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={volumeValidation.error}
                  error={volumeValidation.valid === false}
                  value={orderData.shipmentVolume}
                  InputProps={{ disableUnderline: true }}
                  ref={inputRef}
                  onChange={(event) => textChangeHandler(event, "shipmentVolume", "dec")}
                  onBlur={(e) => validateField(e.target.value, volumeValidation, setVolumeValidation)}
                />
              ) : (
                <div className="value">{orderData.shipmentVolume}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Cargo Insurance:</div>
            <div className="description-summary">
              {lineEditing ? (
                <FormControl>
                  <Select
                    value={orderData.cargoInsurance ? 1 : 0}
                    onChange={(event) => selectChangeandler(event, "cargoInsurance")}
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div className="description-summary">{orderData.cargoInsurance ? "Yes" : "No"}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment Value:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  value={orderData.shipmentValue}
                  InputProps={{ disableUnderline: true }}
                  ref={inputRef}
                  onChange={(event) => textChangeHandler(event, "shipmentValue", "dec")}
                />
              ) : (
                <div className="value">{orderData.shipmentValue}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
            control={
              <Checkbox checked={notify === 1} onClick={() => handleToggleNotify(notify)} className="checkbox-notify" />
            }
            label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(QuoteSummary);
