import React, { useState, FC, useEffect, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { orderDataType } from "../../../../types/actions/orders";
import { useHistory } from "react-router-dom";

// Components
import helpFunctions from "../../../../tools/helpFunctions";
import { generalCRUD } from "../../../../actions/generalCRUD";
import { toastr } from "react-redux-toastr";
import Button from "@material-ui/core/Button";

// Styles
import "./style.scss";
import TextField from "@material-ui/core/TextField";
import {
  defaultValidationProperties,
  validateAllFields,
  validateField,
  validateFloatNumber,
} from "../../../../validator/Fields";
import { FieldType } from "../../../../validator/types";
import {
  emailField,
  nameField,
  originPortField,
  phoneField,
  fieldCommon1,
  commentField,
  fieldCommon2,
} from "../../../../validator/Fields/quoteRequest";
import NumberFormat from "react-number-format";

type QuoteRequestPropsType = {
  orderData: orderDataType;
};

type quoteItemType = {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  origin: string;
  destination: string;
  destinationGoods: string;
  volume: string;
  weight: string;
  transportationMode: number;
  userId: number;
  [key: number]: number;
};

const quoteItemInitial = {
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
  origin: "",
  destination: "",
  destinationGoods: "",
  volume: "",
  weight: "",
  transportationMode: 1,
  userId: parseInt(helpFunctions.getParamIdFromUrl(), 10),
};

const QuoteRequest: FC<any> = (props: QuoteRequestPropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [quoteItem, setQuoteItem] = useState<quoteItemType>(quoteItemInitial);
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
  }, []);

  const [nameValidation, setNameValidation] = useState<FieldType>({ ...nameField });
  const [portValidation, setPortValidation] = useState<FieldType>({ ...originPortField });
  const [emailValidation, setEmailValidation] = useState<FieldType>({ ...emailField });
  const [phoneValidation, setPhoneValidation] = useState<FieldType>({ ...phoneField });
  const [destinationValidation, setDestinationValidation] = useState<FieldType>({ ...fieldCommon2 });
  const [volumeValidation, setVolumeValidation] = useState<FieldType>({ ...fieldCommon1 });
  const [weightValidation, setWeightValidation] = useState<FieldType>({ ...fieldCommon1 });
  const [commentValidation, setCommentValidation] = useState<FieldType>({ ...commentField });
  const [descriptionValidation, setDescriptionValidation] = useState<FieldType>({ ...fieldCommon2 });

  const getUserInfo = async () => {
    const response = await generalCRUD.getConcreteData("user", parseInt(helpFunctions.getParamIdFromUrl(), 10));
    if (response.code === 200) {
      setQuoteItem({
        ...quoteItem,
        name: response.data.username,
        email: response.data.email,
        phoneNumber: response.data.phone,
        origin: response.data.companyName,
      });
    }
  };

  const textChangeHandler = (event: SyntheticEvent, field: string) => {
    let value: any = (event.target as HTMLInputElement).value;

    if (field === "volume" || field === "weight") {
      value = validateFloatNumber(value, 3);
    }
    const newData = {
      ...quoteItem,
      [field]: value,
    };

    setQuoteItem(newData);
  };

  const sendRequest = async () => {
    setIsLoading(true);
    setDisableButton(true)
    const response = await generalCRUD.addNewData(
      "request/quote",
      JSON.stringify({
        ...quoteItem,
        volume: parseFloat(quoteItem.volume),
        weight: parseFloat(quoteItem.weight),
      }),
    );
    if (response.code === 200) {
      toastr.success("Quote Request was added successfully", "");
      setIsLoading(false);
      setDisableButton(false)
      setQuoteItem(quoteItemInitial);
      history.goBack();
    }
  };

  const submitHandler = async (event: SyntheticEvent) => {
    setQuoteItem({ ...quoteItem, userId: parseInt(helpFunctions.getParamIdFromUrl(), 10) });
    const fields = [
      { validate: { ...nameValidation, value: quoteItem.name }, setValidate: setNameValidation },
      { validate: { ...portValidation, value: quoteItem.origin }, setValidate: setPortValidation },
      { validate: { ...emailValidation, value: quoteItem.email }, setValidate: setEmailValidation },
      { validate: { ...phoneValidation, value: quoteItem.phoneNumber }, setValidate: setPhoneValidation },
      { validate: { ...destinationValidation, value: quoteItem.destination }, setValidate: setDestinationValidation },
      { validate: { ...volumeValidation, value: quoteItem.volume }, setValidate: setVolumeValidation },
      { validate: { ...weightValidation, value: quoteItem.weight }, setValidate: setWeightValidation },
      { validate: { ...commentValidation, value: quoteItem.message }, setValidate: setCommentValidation },
      {
        validate: { ...descriptionValidation, value: quoteItem.destinationGoods },
        setValidate: setDescriptionValidation,
      },
    ];
    validateAllFields(fields) && sendRequest();
  };

  return (
    <div id="quote-request">
      <div className="flex">
        <div className="flex-item-50">
          <div className="title-item">
            Your Name <span className="required">*</span>
          </div>
          <TextField
            helperText={nameValidation.error}
            error={nameValidation.valid === false}
            value={quoteItem.name}
            inputProps={{ maxLength: nameValidation.maxLength }}
            type="text"
            className="base-input"
            placeholder="e.g. Tonny G. Stark"
            onChange={(event) => textChangeHandler(event, "name")}
            onBlur={(e) => validateField(e.target.value, nameValidation, setNameValidation)}
          />
        </div>
        <div className="flex-item-50">
          <div className="title-item">Shipment Origin Port</div>
          <TextField
            helperText={portValidation.error}
            error={portValidation.valid === false}
            value={quoteItem.origin}
            type="text"
            inputProps={{ maxLength: portValidation.maxLength }}
            className="base-input"
            placeholder="e.g. Shanghai"
            onChange={(event) => textChangeHandler(event, "origin")}
            onBlur={(e) => validateField(e.target.value, portValidation, setPortValidation)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex-item-50">
          <div className="title-item">
            E-mail <span className="required">*</span>
          </div>
          <TextField
            helperText={emailValidation.error}
            error={emailValidation.valid === false}
            value={quoteItem.email}
            type="text"
            inputProps={{ maxLength: emailValidation.maxLength }}
            className="base-input"
            placeholder="e.g. tonny@avengers.stark"
            onChange={(event) => textChangeHandler(event, "email")}
            onBlur={(e) => validateField(e.target.value, emailValidation, setEmailValidation)}
          />
        </div>
        <div className="flex-item-50">
          <div className="title-item">
            Phone <span className="required">*</span>
          </div>
          <NumberFormat
            customInput={TextField}
            prefix={"+"}
            helperText={phoneValidation.error}
            error={phoneValidation.valid === false}
            value={quoteItem.phoneNumber}
            className="base-input"
            inputProps={{ maxLength: phoneValidation.maxLength }}
            placeholder="e.g. + 86 10 6532 9985"
            onChange={(event) => textChangeHandler(event, "phoneNumber")}
            onBlur={(e) => validateField(e.target.value, phoneValidation, setPhoneValidation)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex-item-100">
          <div className="title-item">Destination</div>
          <TextField
            helperText={destinationValidation.error}
            error={destinationValidation.valid === false}
            value={quoteItem.destination}
            type="text"
            inputProps={{ maxLength: destinationValidation.maxLength }}
            className="base-input"
            onChange={(event) => textChangeHandler(event, "destination")}
            onBlur={(e) => validateField(e.target.value, destinationValidation, setDestinationValidation)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex-item-50">
          <div className="title-item">Shipment Volume (CBM)</div>
          <NumberFormat
            customInput={TextField}
            helperText={volumeValidation.error}
            error={volumeValidation.valid === false}
            value={quoteItem.volume}
            className="base-input base-input-without-arrow"
            placeholder="e. g. 4.525"
            allowNegative={false}
            isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, "dec") : true)}
            onChange={(event) => textChangeHandler(event, "volume")}
            onBlur={(e) => validateField(e.target.value, volumeValidation, setVolumeValidation)}
          />
        </div>
        <div className="flex-item-50">
          <div className="title-item">Shipment Weight (KG)</div>
          <NumberFormat
            customInput={TextField}
            helperText={weightValidation.error}
            error={weightValidation.valid === false}
            value={quoteItem.weight}
            className="base-input base-input-without-arrow"
            placeholder="e. g. 900"
            allowNegative={false}
            isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, "dec") : true)}
            onChange={(event) => textChangeHandler(event, "weight")}
            onBlur={(e) => validateField(e.target.value, weightValidation, setWeightValidation)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex-item-100">
          <div className="title-item">
            Comment or Message <span className="required">*</span>
          </div>
          <TextField
            helperText={commentValidation.error}
            error={commentValidation.valid === false}
            value={quoteItem.message}
            type="text"
            placeholder="e.g. Enter your information"
            inputProps={{ maxLength: commentValidation.maxLength }}
            className="base-input"
            onChange={(event) => textChangeHandler(event, "message")}
            onBlur={(e) => validateField(e.target.value, commentValidation, setCommentValidation)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex-item-100">
          <div className="title-item">Description of Goods</div>
          <TextField
            helperText={descriptionValidation.error}
            error={descriptionValidation.valid === false}
            value={quoteItem.destinationGoods}
            type="text"
            inputProps={{ maxLength: descriptionValidation.maxLength }}
            className="base-input"
            onChange={(event) => textChangeHandler(event, "destinationGoods")}
            onBlur={(e) => validateField(e.target.value, descriptionValidation, setDescriptionValidation)}
          />
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        value="send"
        className={disableButton ? "main-btn disable" : "main-btn"}
        disabled={disableButton}
        onClick={(event) => submitHandler(event)}
      >
        Send request
      </Button>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(QuoteRequest);
