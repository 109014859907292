import store from "../redux/store";

import {
    clientsActionType
} from '../types/actions/clients';

export const clients: clientsActionType = {
    setClientsPage(page, maxPage) {
        store.dispatch({
            type: "CLIENTS_PAGE",
            payload: {
                page: page,
                maxPage: maxPage
            }
        });
    },
    setSortedColumn(data) {
        store.dispatch({
            type: "SORTED_CLIENTS_COLUMN",
            payload: {
                sortedColumn: data
            }
        });
    }
};
