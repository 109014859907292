import React, { FC } from "react";
import { toastr } from "react-redux-toastr";
import { Editor } from "@tinymce/tinymce-react";
import { responseType } from "../../../types/actions/auth";

type WEditorPropsType = {
  content: string;
  height?: number;
  uploadImage: (data: FormData) => Promise<responseType<any>>;
  handleEditorChange: () => void;
};

const WEditor: FC<any> = (props: WEditorPropsType) => {
  return (
    <Editor
      apiKey="i79ntdfdeg9ed5w2hdbtfrcpmbjvulhxp0hk3r0s3d96hdhy"
      initialValue={props.content}
      value={props.content}
      init={{
        height: props.height ? props.height : 700,
        //menubar: false,
        plugins: [
          "advlist autolink lists link image",
          "charmap print anchor link help",
          "searchreplace visualblocks code",
          "insertdatetime media table paste",
          "wordcount",
        ],
        menubar: "view",
        toolbar:
          "undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | link \
          bullist numlist outdent indent | help | \
          forecolor backcolor image | \
          table insertdatetime",
        images_reuse_filename: true,
        image_advtab: true,
        a11y_advanced_options: true,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: function (cb) {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          // @ts-ignore
          input.onchange = async function (event: React.ChangeEvent<HTMLInputElement>) {
            const file = event.target.files[0];
            const fileName = event.target.files[0].name;
            const formData = new FormData();
            formData.append(`image`, file, fileName);
            const response = await props.uploadImage(formData);
            if (response.code === 200) {
              cb(response.data.original, { title: fileName });
            } else {
              toastr.error("Error", response.message);
            }
          };

          input.click();
        },
      }}
      onEditorChange={props.handleEditorChange}
    />
  );
};

export default WEditor;
