import { combineReducers } from "redux";
import app from "./app";
import user from "./user";
import orders from "./orders";
import clients from "./clients";
import { reducer as toastrReducer } from "react-redux-toastr";

export type rootReducerType = {
  app: any;
  user: any;
  toastr: any;
  orders: any;
  clients: any;
};

export const rootReducer = combineReducers({
  app,
  user,
  toastr: toastrReducer,
  orders,
  clients,
});