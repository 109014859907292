import React, { useState, FC } from "react";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// Styles
import "./style.scss";
import { useHistory } from "react-router-dom";

type quotesDataType = {
  id: string;
  created: number;
  hashSum: string;
  customCode: string;
  shipmentVolume: number;
  status: number;
  companyNameShipper: string;
  amazonRefId: string;
  shipmentId: string;
};

type quotesPropsType = {
  data: [quotesDataType];
};

type OrderType = "asc" | "desc";

type columnListItemType = {
  id: string;
  title: string;
  sortable: boolean;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columnList = [
  {
    id: "id",
    title: "ID",
    sortable: true,
  },
  {
    id: "created",
    title: "Date",
    sortable: true,
  },
  {
    id: "number",
    title: "Shipment number",
    sortable: false,
  },
  {
    id: "companyNameShipper",
    title: "Supplier",
    sortable: true,
  },
  {
    id: "shipmentVolume",
    title: "Volume",
    sortable: true,
  },
  {
    id: "fba",
    title: "# FBA",
    sortable: false,
  },
  {
    id: "status",
    title: "Status",
    sortable: true,
  },
];

const QuotesTable: FC<any> = (props: quotesPropsType) => {
  const [order, setOrder] = useState<OrderType>("desc");
  const [orderBy, setOrderBy] = useState<string>("id");

  const handleRequestSort = (property: string, isSortable: boolean) => {
    if (!isSortable) return false;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const defineThClassName = (columnName: string): string => {
    if (orderBy === columnName) {
      return order === "asc" ? "sort-icons active-desc" : "sort-icons active-asc";
    }
    return "sort-icons";
  };

  const history = useHistory();

  return (
    <div id="quotes-table">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnList.map((item: columnListItemType, index: number) => {
                return (
                  <TableCell
                    key={index}
                    sortDirection={orderBy === item.id ? order : false}
                    className={item.sortable ? "sortable" : ""}
                    onClick={() => handleRequestSort(item.id, item.sortable)}
                  >
                    <div className="th-content">
                      {item.sortable && <div className={defineThClassName(item.id)}>&nbsp;</div>}
                      {item.title}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(props.data, getComparator(order, orderBy)).map((row, index) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={index}
                  className={`status-${row.status} row-style`}
                  onClick={() => history.push(`/orders/${row.id}`)}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{helpFunctions.getDateFromUnix(row.created)}</TableCell>
                  <TableCell>
                    {row.hashSum}
                    {row.customCode}
                  </TableCell>
                  <TableCell>{row.companyNameShipper}</TableCell>
                  <TableCell>{row.shipmentVolume}</TableCell>
                  <TableCell>{row.shipmentId}</TableCell>
                  <TableCell className={`circle-status status-${row.status}`}>
                    {helpFunctions.defineTextStatus(row.status)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QuotesTable;
