import { FieldType } from "../types";
import React from "react";

export const defaultValidationProperties = {
  value: "",
  valid: null,
  touched: false,
  error: "",
};

type propsTypes = {
  secondPass?: FieldType;
  setSecondPass?: any;
  anotherPass?: any;
};

export const validateFloatNumber = (value, signsAfterComa) => {
  const floatNumber = value.split(".");

  if (floatNumber[1]) {
    if (floatNumber[1].length >= signsAfterComa) {
      return floatNumber[0] + "." + floatNumber[1].slice(0, signsAfterComa);
    } else {
      return floatNumber[0] + "." + floatNumber[1];
    }
  }

  return value;
};

export const validateField = (
  value: any,
  field: FieldType,
  setValidationResult: React.Dispatch<React.SetStateAction<FieldType>>,
  props?: propsTypes,
) => {
  const regExEmail = /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,6}$/.test(value);
  let result = {};
  field.dependencies.forEach((item) => {
    if (Object.keys(result).length === 0) {
      switch (item.depCase) {
        case "required":
          if (!value) {
            result = {
              value: value,
              valid: false,
              error: "This field cannot be empty",
              touched: true,
            };
          }
          break;
        case "email":
          if (!regExEmail) {
            result = {
              value: value,
              valid: false,
              error: "Please, enter valid email address",
              touched: true,
            };
          }
          break;
        case "maxLength":
          if (value && value.length > item.attr.maxLength) {
            result = {
              value: value,
              valid: false,
              error: `${field.nameForError ?? "This field"} must be no more than ${item.attr.maxLength} characters`,
              touched: true,
            };
          }
          break;
        case "minLength":
          if (value && value.length < item.attr.minLength) {
            result = {
              value: value,
              valid: false,
              error: `${field.nameForError ?? "This field"} must be over ${item.attr.minLength} characters.`,
              touched: true,
            };
          }
          break;
        case "regular":
          if (!new RegExp(item.attr.regEx).test(value)) {
            result = {
              value: value,
              valid: false,
              error: item.attr.customError,
              touched: true,
            };
          }
          break;
        case "passwords":
          if (item.attr.isEdit) {
            if (value !== props.anotherPass && (value || props.anotherPass)) {
              if (value && props.anotherPass) {
                result = {
                  value: value,
                  valid: false,
                  error: "Password do not match",
                  touched: true,
                };
                props.setSecondPass((prevState) => ({
                  ...prevState,
                  valid: false,
                  error: "Password do not match",
                  touched: true,
                  value: props.anotherPass,
                }));
              } else if (value && props.secondPass.touched) {
                props.setSecondPass((prevState) => ({
                  ...prevState,
                  valid: false,
                  error: "This field cannot be empty",
                  touched: true,
                  value: props.anotherPass,
                }));
              } else if (props.anotherPass) {
                result = {
                  value: value,
                  valid: false,
                  error: "This field cannot be empty",
                  touched: true,
                };
              }
            } else {
              result = {
                value: value,
                valid: true,
                error: "",
                touched: true,
              };
              props.setSecondPass((prevState) => ({
                ...prevState,
                valid: true,
                touched: true,
                error: "",
                value: props.anotherPass,
              }));
            }
          } else {
            if (value && value !== props.anotherPass) {
              if (value && props.anotherPass) {
                result = {
                  value: value,
                  valid: false,
                  error: "Password do not match",
                  touched: true,
                };
                props.setSecondPass((prevState) => ({
                  ...prevState,
                  valid: false,
                  error: "Password do not match",
                  touched: true,
                  value: props.anotherPass,
                }));
              }
            } else {
              result = {
                value: value,
                valid: true,
                error: "",
                touched: true,
              };
              props.setSecondPass((prevState) => ({
                ...prevState,
                valid: true,
                touched: true,
                error: "",
                value: props.anotherPass,
              }));
            }
          }
          break;
      }
    }
  });

  if (Object.keys(result).length === 0) {
    result = {
      value: value,
      valid: true,
      error: "",
      touched: true,
    };
  }

  setValidationResult((prevState) => {
    return {
      ...prevState,
      ...result,
    };
  });

  return result;
};

export const validateAllFields = (fields): boolean => {
  return !fields
    .map((field) => {
      if (field.props) {
        return validateField(field.validate.value, field.validate, field.setValidate, field.props);
      } else {
        return validateField(field.validate.value, field.validate, field.setValidate);
      }
    })
    .some((item) => item.valid !== true);
};
