/*
  Action on api
  https://github.com/public-apis/public-apis
*/
import axios from "axios";
import { CONSTS } from "../config/objectConst";

import { authApiType } from "../types/api/auth";

export const auth: authApiType = {
  // to login
  login: async (data) => {
    return await axios({
      url: CONSTS.API.url + "login",
      method: "post",
      headers: {
        login: data.login,
        password: data.password,
        "remember-me": data.rememberMe,
      },
    });
  },
  logout: async (token) => {
    return await axios({
      url: CONSTS.API.url + "/logout",
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: token,
      },
    });
  },
  forgotPassword: async (data) => {
    return await axios({
      url: CONSTS.API.url + "app/forgot-password",
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    });
  },
};
