/*
  Action on api
  https://github.com/public-apis/public-apis
*/
import axios from "axios";
import { CONSTS } from "../config/objectConst";

import { userApiType } from "../types/api/user";
import { shipperInfo } from "../components/organisms/BookingRequests/InstantLCLQuote";

export const user: userApiType = {
  getMyData: async (token) => {
    return await axios({
      url: CONSTS.API.url + "user/personal/get",
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  getMyCompanies: async (id) => {
    const token = localStorage.getItem("token");
    return await axios({
      url: `${CONSTS.API.url}user/${id}/shipper_information/get_all`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  saveClientCompany: async (id, data: shipperInfo) => {
    const token = localStorage.getItem("token");
    return await axios({
      url: `${CONSTS.API.url}user/${id}/shipper_information`,
      method: "post",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  editClientCompany: async (id, data: shipperInfo, shipperInfoId) => {
    const token = localStorage.getItem("token");
    return await axios({
      url: `${CONSTS.API.url}user/${id}/shipper_information/edit/${shipperInfoId}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  checkToken: async (token) => {
    return await axios({
      url: CONSTS.API.url + "user/check_token",
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
};
