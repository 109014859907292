const helpFunctions = {
  getActiveLinkFromUrl: () => {
    const pathArr = document.location.pathname.split("/");
    return pathArr[1];
  },
  getParamIdFromUrl: () => {
    const pathArr = document.location.pathname.split("/");
    return pathArr[2];
  },
  isNumber(number, type) {
    return type === "dec" ? /^\.?\d+\.?(\d{1,3})?$/.test(number) : /^\d+$/.test(number);
  },
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  getDateFromUnix(unixTimeStamp, separator = " ") {
    if (unixTimeStamp) {
      const a = new Date(unixTimeStamp * 1000);
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const year = a.getFullYear();
      const month = months[a.getMonth()];
      const date = a.getDate();
      return `${month}${separator}${date}${separator}${year}`;
    }
    return "-";
  },
  getTimeFromUnix(unixTimeStamp, separator = ":") {
    if (unixTimeStamp) {
      const a = new Date(unixTimeStamp * 1000);
      const hours = a.getHours();
      let minutes = a.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return `${hours}${separator}${minutes}`;
    }
    return "-";
  },
  buildResultParamsForApi(page, sort, quantity) {
    let result = "";
    if (page || sort || quantity) {
      result = "?";
    } else {
      return result;
    }
    const params = [page, sort, quantity];
    params.forEach((item) => {
      if (item) {
        result += `${item}&`;
      }
    });
    return result.substring(0, result.length - 1);
  },
  defineTextStatus(status) {
    switch (status) {
      case 1:
        return "Cancelled";
      case 2:
        return "Completed";
      default:
        return "In progress";
    }
  },
  countTimeStamp(selectedDate, selectedTime) {
    // from date and time (Fri Sep 11 2020 00:00:00 GMT+0300 (Восточная Европа, летнее время))
    let dateResult = 0;
    if (selectedDate && selectedTime) {
      const date = selectedDate.toString().split(" ");
      date[4] = "00:00:00";
      const newDate = new Date(date.join(" "));
      dateResult = Math.round(newDate.getTime() / 1000);
      const timeUnix = selectedTime.getHours() * 60 * 60 + selectedTime.getMinutes() * 60;
      dateResult += timeUnix;
    }
    return dateResult;
  },
  countDateStamp(selectedDate) {
    let dateResult = 0;
    if (selectedDate) {
      const date = selectedDate.toString().split(" ");
      date[4] = "00:00:00";
      const newDate = new Date(date.join(" "));
      dateResult = Math.round(newDate.getTime() / 1000);
    }
    return dateResult;
  },
  isDarkTheme() {
    return localStorage.getItem("themeStyle") === "dark";
  },
  getFileExtension(fileName) {
    const arrName = fileName.split(".");
    return arrName[arrName.length - 1];
  },

  buildErrorString(error) {
    let message = `${error?.message ? error?.message : "Server error."} ${error.data ? error.data[0]?.message : ""}`;
    return message;
  },
};

export default helpFunctions;
