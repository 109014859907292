
export const columnListInitial = {
  id: true,
  date: true,
  user: true,
  company: true,
  trackingCode: true,
  event: true,
  origin: true,
  destination: true,
  volume: true,
  status: true,
  lastUpdate: true,
};
/*
 *
 * user reducer
 *
 */
const initialState = {
  id: null,
  username: null,
  eternal_token: null,
  user_token: null,
  phone: null,
  role: [],
  email: null,
  companyName: null,
  companyAddress: null,
  canadianBusinessNumber: null,
  confirmed: false,
  orderColumnSettings: columnListInitial,
};

const user = (state = initialState, action) => {
  switch (action.type) {

    case "MY_DATA":
      return Object.assign({}, state, {
        id: action.payload.myData.id,
        username: action.payload.myData.username,
        eternal_token: action.payload.myData.eternal_token,
        user_token: action.payload.myData.user_token,
        phone: action.payload.myData.phone,
        role: action.payload.myData.role,
        email: action.payload.myData.email,
        companyName: action.payload.myData.companyName,
        companyAddress: action.payload.myData.companyAddress,
        canadianBusinessNumber: action.payload.myData.canadianBusinessNumber,
        confirmed: action.payload.myData.confirmed,
        orderColumnSettings: action.payload.myData.orderColumnSettings
      });

    case "COLUMN_SETTINGS":
      return Object.assign({}, state, {
        orderColumnSettings: action.payload.orderColumnSettings
      });

    default:
      return state;
  }
};

export default user;
