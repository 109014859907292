import store from "../redux/store";
import { orders as ordersApi } from "../api/orders";
import { user } from "./user";

import { ordersActionType } from "../types/actions/orders";

export const orders: ordersActionType = {
  setOrdersPage(page, maxPage) {
    store.dispatch({
      type: "ORDERS_PAGE",
      payload: {
        page: page,
        maxPage: maxPage,
      },
    });
  },
  async searchOrder(code) {
    const codeParams = code.trim().replace(" ", "%20");
    try {
      const response = await ordersApi.searchOrder(codeParams);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  saveOrderData(data) {
    store.dispatch({
      type: "ORDER_DATA",
      payload: {
        orderData: data,
      },
    });
  },
  setSortedColumn(data) {
    store.dispatch({
      type: "SORTED_COLUMN",
      payload: {
        sortedColumn: data,
      },
    });
  },
  async saveColumnSettings(data) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.saveColumnSettings(data, token);
      if (response.status === 200) {
        user.setOrderColumnSettings(data);
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getOrderInfo(id) {
    try {
      const response = await ordersApi.getOrderInfo(id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async editOrderInfo(id, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.editOrderInfo(id, token, data);
      console.log(response);
      if (response.status === 200) {
        this.saveOrderData(response.data.data);
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getInstantQuotes(id) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.getInstantQuotes(id, token);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async setNextEventStatus(data) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.setNextEventStatus(token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async resendRequest(id) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.resendRequest(id, token);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getPreviewContent(id, booking_request_id) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.getPreviewContent(id, booking_request_id, token);
      if (response) {
        return response;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async uploadFiles(id, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.uploadFiles(id, token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getDocExchange(id) {
    const token = localStorage.getItem("token");
    try {
      const response = await ordersApi.getDocExchange(id, token);
      if (response) {
        return response;
      }
    } catch (e) {
      return e.response.data;
    }
  },
};
