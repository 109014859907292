import React, {FC, useEffect, useState} from "react";

// Styles
import "./style.scss";
import Pagination from "../../../../node_modules/@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "../../../redux/reducers";

type PaginationType = {
  classes?: string;
  page: number;
  maxPage: number;
  isLoaded?: boolean;
  itemsNumber?: number;
  handleClickPage: (value: number) => void;
  handleClickQuantity: (quantity: number) => void;
};

const quantityValues = [10, 50, 100, 200];

const CustomPagination: FC<PaginationType> = (props: PaginationType) => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const quantityPagTemplate = useSelector((state: rootReducerType) => state.app.quantityPagTemplate);
  useEffect(() => {
    if (props.isLoaded) {
      const arr = quantityValues.filter((item, index) => {
        return quantityValues[index - 1] < quantityPerPage * props.maxPage || item === quantityPerPage || index === 0;
      });
      dispatch({
        type: "QUANTITY_PAG_TEMPLATE",
        payload: {
          quantityPagTemplate: arr,
        },
      });
    }
  }, [props.isLoaded]);
  const dispatch = useDispatch();
  let classes = "pag-wrap ";
  if (props.classes) {
    classes += props.classes;
  }
  if (props.itemsNumber <= quantityValues[0] && props.maxPage <= 1) {
    classes = "hidden-block";
  }

  const handleClickQuantity = (quantity: number) => {
    if (quantity !== quantityPerPage) {
      props.handleClickQuantity(quantity);
      dispatch({
        type: "QUANTITY_PER_PAGE",
        payload: {
          quantityPerPage: quantity,
        },
      });
    }
  };

  return (
    <div className={classes}>
      <div className="pag-quantity">
        Show:
        {quantityPagTemplate.map((item, index) => {
          return (
            <span
              key={item}
              className={quantityPerPage === item ? "active" : ""}
              onClick={() => handleClickQuantity(item)}
            >
              {item}
              {index !== quantityPagTemplate.length - 1 ? ", " : ""}
            </span>
          );
        })}
        lines
      </div>
      {props.maxPage > 1 && (
        <Pagination
          count={props.maxPage}
          page={props.page}
          shape="rounded"
          onChange={(event, value) => props.handleClickPage(value)}
        />
      )}
    </div>
  );
};

export default CustomPagination;
