import {FieldType} from "../types";
import {defaultValidationProperties} from "./index";

export const nameField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 50,
    dependencies: [{depCase: 'required'}, {depCase: 'maxLength', attr: {maxLength: 50}}],
}

export const originPortField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 150,
    dependencies: [{depCase: 'maxLength', attr: {maxLength: 150}}],
}

export const emailField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 100,
    nameForError: 'Email',
    dependencies: [{depCase: 'required'}, {depCase: 'email'}, {depCase: 'maxLength', attr: {maxLength: 100}}],
}

export const phoneField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 25,
    nameForError: 'Phone',
    dependencies: [{depCase: 'required'}, {depCase: 'maxLength', attr: {maxLength: 25}}],
}

export const fieldCommon2: FieldType = {
    ...defaultValidationProperties,
    maxLength: 150,
    dependencies: [{depCase: 'maxLength', attr: {maxLength: 150}}],
}

export const commentField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 150,
    dependencies: [{depCase: 'required'}, {depCase: 'maxLength', attr: {maxLength: 150}}],
}

export const fieldCommon1: FieldType = {
    ...defaultValidationProperties,
    dependencies: [{depCase: 'float', attr: {signsAfterComa: 3}}],
}


