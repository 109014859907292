import React, { FC, SyntheticEvent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Styles
import "./style.scss";
import { FieldType } from "../../../../validator/types";
import { maxLengthInstance, regularInstance } from "../../../../validator/Fields/instantLCLQuote";
import { validateAllFields, validateField } from "../../../../validator/Fields";
import TextField from "@material-ui/core/TextField";

type orderDetailsType = {
  orderData: orderDataType;
};

const AmazonFbaBooking: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);

  /*Validate*/
  const [amazonRefIdValid, setAmazonRefIdValid] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 25, "Invalid field. You can only use letter, number", true),
    value: orderData.amazonRefId,
  });
  const [shipmentIdValid, setShipmentIdValid] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 25, "Invalid field. You can only use letter, number", true),
    value: orderData.shipmentId,
  });
  /*const [merchantNameValid, setMerchantNameValid] = useState<FieldType>({
    ...maxLengthInstance(100),
    value: orderData.merchantName,
  });*/

  const dispatch = useDispatch();

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const allFields = [
    { validate: amazonRefIdValid, setValidate: setAmazonRefIdValid },
    { validate: shipmentIdValid, setValidate: setShipmentIdValid },
  ];

  const canselHendler = () => {
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "" }));
      }
    });
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields(allFields)) {
      sendRequest(orderData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id: any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1) : setNotify(0);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="amazon-booking">
      <div className="tab-header">
        <div className="tab-header-title">Amazon FBA Booking</div>
        <div onClick={editHendler} className={!lineEditing ? "show" : "hide"}>
          Edit
        </div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">Amazon Ref ID:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={amazonRefIdValid.error}
                  error={amazonRefIdValid.valid === false}
                  value={orderData.amazonRefId}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: { maxLength: amazonRefIdValid.maxLength } }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "amazonRefId")}
                  onBlur={(e) => validateField(e.target.value, amazonRefIdValid, setAmazonRefIdValid)}
                />
              ) : (
                <div className="value">{orderData.amazonRefId}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Shipment ID (PO):</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={shipmentIdValid.error}
                  error={shipmentIdValid.valid === false}
                  value={orderData.shipmentId}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: { maxLength: shipmentIdValid.maxLength } }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "shipmentId")}
                  onBlur={(e) => validateField(e.target.value, shipmentIdValid, setShipmentIdValid)}
                />
              ) : (
                <div className="value">{orderData.shipmentId}</div>
              )}
            </div>
          </div>
          {/*
            <div className="tab-item">
              <div className="title-summary">Merchant name:</div>
              <div className="description-summary">
                {lineEditing ? (
                  <TextField
                    helperText={merchantNameValid.error}
                    error={merchantNameValid.valid === false}
                    value={orderData.merchantName}
                    ref={inputRef}
                    InputProps={{ disableUnderline: true, inputProps: {maxLength: merchantNameValid.maxLength} }}
                    type="text"
                    onChange={(event) => textChangeHandler(event, "merchantName")}
                    onBlur={(e) => validateField(e.target.value, merchantNameValid, setMerchantNameValid)}
                  />
                ) : (
                  <div className="value">{orderData.merchantName}</div>
                )}
              </div>
            </div>
          */}
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
            control={
              <Checkbox checked={notify === 1} onClick={() => handleToggleNotify(notify)} className="checkbox-notify" />
            }
            label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(AmazonFbaBooking);
