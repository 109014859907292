import React, { FC } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

// Components
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

// Styles
import "./style.scss";

type DatePickerType = {
  title: string;
  selectedDate: Date | null;
  selectedTime: Date | null;
  handleDateChange: (date: Date | null) => void;
  handleTimeChange: (time: Date | null) => void;
};

const DatePicker: FC<DatePickerType> = (props: DatePickerType) => {
  return (
    <div id="date-picker" className="flex">
      <span className="title">{props.title}: </span>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            variant="inline"
            className="picker date"
            margin="normal"
            format="MM/dd/yyyy"
            value={props.selectedDate}
            onChange={props.handleDateChange}
            InputProps={{ disableUnderline: true }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardTimePicker
            variant="inline"
            className="picker time"
            margin="normal"
            value={props.selectedTime}
            onChange={props.handleTimeChange}
            InputProps={{ disableUnderline: true }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
