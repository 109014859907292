import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import { clients } from "../../../actions/clients";
import helpFunctions from "../../../tools/helpFunctions";
import { sortColumnType } from "../../../types/actions/generalCRUD";
import { Md5 } from "ts-md5/dist/md5";

// Components
import {apiUrlPath} from "../../../config/objectConst";
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import CustomPagination from "../../organisms/CustomPagination";
import { defaultValidationProperties, validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";
import { emailField, phoneField } from "../../../validator/Fields/quoteRequest";
import NumberFormat from "react-number-format";

// Styles
import "./style.scss";
import DotsMenu from "../../../assets/images/dots-menu.svg";

type clientType = {
  page: number;
  maxPage: number;
  sortedColumn: sortColumnType;
  quantityPerPage: number;
};

type clientItemType = {
  canadianBusinessNumber: string;
  companyAddress: string;
  companyName: string;
  email: string;
  id: number;
  phone: string;
  username: string;
  password: string;
  confirmed: boolean;
  [key: number]: number;
};

const clientItemInitial = {
  canadianBusinessNumber: "",
  companyAddress: "",
  companyName: "",
  email: "",
  id: 0,
  phone: "",
  password: "",
  username: "",
  confirmed: false,
};

type editModalType = {
  status: boolean;
  index: number;
};

export type allField = {
  validate: FieldType;
  setValidate: React.Dispatch<React.SetStateAction<FieldType>>;
  props?: {
    secondPass: FieldType;
    setSecondPass: React.Dispatch<React.SetStateAction<FieldType>>;
    anotherPass: any;
  };
};

const ClientsContainer: FC<any> = (props: clientType) => {
  type passwordType = {
    value: string;
  };
  const passwordInitial = {
    value: "",
  };

  const passwordField: FieldType = {
    ...defaultValidationProperties,
    maxLength: 50,
    dependencies: [
      { depCase: "required" },
      { depCase: "minLength", attr: { minLength: 8 } },
      { depCase: "maxLength", attr: { maxLength: 150 } },
      { depCase: "passwords", attr: { isEdit: false } },
    ],
  };

  const passwordFieldForEdit: FieldType = {
    ...defaultValidationProperties,
    maxLength: 50,
    dependencies: [
      { depCase: "minLength", attr: { minLength: 8 } },
      { depCase: "maxLength", attr: { maxLength: 150 } },
      { depCase: "passwords", attr: { isEdit: true } },
    ],
  };

  const [clientList, setClientList] = useState<Array<clientItemType>>([]);
  const [clientItem, setClientItem] = useState<clientItemType>(clientItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [deleteModal, setDeleteModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regPassword, setRegPassword] = useState<passwordType>(passwordInitial);
  const [repeatPassword, setRepeatPassword] = useState<passwordType>(passwordInitial);

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(50) });
  const [companyNameValid, setCompanyNameValid] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [emailValid, setEmailValid] = useState<FieldType>({ ...emailField });
  const [phoneValid, setPhoneValid] = useState<FieldType>({ ...phoneField });
  const [addressValid, setAddressValid] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [businessNumberValid, setBusinessNumberValid] = useState<FieldType>({ ...maxLengthInstance(15, false) });
  const [firstPasswordValid, setFirstPasswordValid] = useState<FieldType>({ ...passwordField });
  const [secondPasswordValid, setSecondPasswordValid] = useState<FieldType>({ ...passwordField });

  const allFields: Array<allField> = [
    { validate: { ...nameValid, value: clientItem.username }, setValidate: setNameValid },
    { validate: { ...companyNameValid, value: clientItem.companyName || "" }, setValidate: setCompanyNameValid },
    { validate: { ...emailValid, value: clientItem.email }, setValidate: setEmailValid },
    { validate: { ...phoneValid, value: clientItem.phone }, setValidate: setPhoneValid },
    { validate: { ...addressValid, value: clientItem.companyAddress || "" }, setValidate: setAddressValid },
    {
      validate: { ...businessNumberValid, value: clientItem.canadianBusinessNumber || "" },
      setValidate: setBusinessNumberValid,
    },
    {
      validate: { ...firstPasswordValid, value: regPassword.value || "" },
      setValidate: setFirstPasswordValid,
      props: {
        secondPass: secondPasswordValid,
        setSecondPass: setSecondPasswordValid,
        anotherPass: repeatPassword.value,
      },
    },
    {
      validate: { ...secondPasswordValid, value: repeatPassword.value || "" },
      setValidate: setSecondPasswordValid,
      props: {
        secondPass: firstPasswordValid,
        setSecondPass: setFirstPasswordValid,
        anotherPass: regPassword.value,
      },
    },
  ];

  useEffect(() => {
    if (isAddModalOpen) {
      setFirstPasswordValid(passwordField);
      setSecondPasswordValid(passwordField);
    }
    if (editModal.status) {
      setFirstPasswordValid(passwordFieldForEdit);
      setSecondPasswordValid(passwordFieldForEdit);
    }
  }, [editModal, isAddModalOpen]);

  const changeInputPassHandler = (value, name, setName) => {
    setName({ value: value });
  };

  // to get all entities
  useEffect(() => {
    getAllClients();
  }, []);

  const getAllClients = async (
    page: number = props.page,
    sort: sortColumnType = props.sortedColumn,
    quantity = props.quantityPerPage,
  ) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("admin/user", page, sort, quantity);
    if (response.code === 200) {
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllClients(maxPage, sort, quantity);
      clients.setClientsPage(response.data.currentPageNumber, maxPage);
      setClientList(response.data.items);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const defineThClassName = (columnName: string): string => {
    if (props.sortedColumn.name === columnName) {
      return props.sortedColumn.type === "asc" ? "sort-icons active-desc" : "sort-icons active-asc";
    }
    return "sort-icons";
  };

  const sortOrdersHandler = (name: string) => {
    let type = "asc";
    if (props.sortedColumn.name === name) {
      type = props.sortedColumn.type === "asc" ? "desc" : "asc";
    }
    const sortData = {
      type: type,
      name: name,
    };
    clients.setSortedColumn(sortData);
    getAllClients(props.page, sortData);
  };

  const openEditModal = (data: clientItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setClientItem(data);
  };

  const openDeleteModal = (data: clientItemType, index: number): void => {
    setDeleteModal({
      status: true,
      index: index,
    });
    setClientItem(data);
  };


  const viewClient = (data: clientItemType, index: number): void => {
    // console.log(data);
    document.cookie = "user_id=" + data.id + ";domain=.coloader.inboxgear.com;max-age=3600;";
    document.cookie = "user_name=" + data.username + ";domain=.coloader.inboxgear.com;max-age=3600;";
    document.cookie = "user_phone=" + data.phone + ";domain=.coloader.inboxgear.com;max-age=3600;";
    document.cookie = "user_token=" + data['user_token'] + ";domain=.coloader.inboxgear.com;max-age=3600;";
    document.cookie = "user_email=" + data.email + ";domain=.coloader.inboxgear.com;max-age=3600;";
    document.cookie = "confirmed=" + data.confirmed + ";domain=.coloader.inboxgear.com;max-age=3600;";

  
    var l = {apiUrlPath};
    var f = l['apiUrlPath'];
    var logout = false;
    var first_try = false;
    var second_try = false;
    
    $.ajax({
      url: "https://api.coloader.inboxgear.com/api/logout",
      method: 'POST',
      async: false,
      timeout: 1000,
      headers: { 'user-token': data['user_token'], },
      success: function() {
        logout = true;
      },
      error() {
        console.log('error');
      },
    })
    function ajax_first_try(){
      $.ajax({
        url: "https://"+f+"/api/user/get/"+data.id,
        method: 'GET',
        async: false,
        timeout: 1000,
        headers: { 'user-token': data['user_token'], },
        success: function() {
          first_try = true;
        },
        error() {
          console.log('error');
        },
      })
    }
    function ajax_second_try() {
      $.ajax({
        url: "https://"+f+"/api/user/get/"+data.id,
        method: 'GET',
        async: false,
        headers: { 'user-token': data['user_token'], },
        success: function() {
          second_try = true;
        },
        error() {
          console.log('error');
        },
      })
    }
    ajax_first_try();
    if (first_try) {
      window.open('https://coloader.inboxgear.com/', '_blank')
    } else {
      setTimeout(() => ajax_second_try(), 2000);
    }
    if (second_try) {
      window.open('https://coloader.inboxgear.com/', '_blank')
    }
    // setTimeout(() => window.open('http://coloader.zone:3000/', '_blank'), 700);
    // const http = new XMLHttpRequest();
    // // http.withCredentials = true;

    // function send_task() {
    //   console.log('send');
    //   http.open("GET", "https://"+f+"/api/user/get/"+data.id);
    //   // http.setRequestHeader("user_token", data['user_token']);
    //   http.send();
    // }
    // send_task();
    // setTimeout(send_task, 300);
    // setTimeout(send_task, 600);
    // setTimeout(() => window.open('http://coloader.zone:3000/', '_blank'), 700);
  };

  const closeModal = (): void => {
    setClientItem(clientItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    setDeleteModal({
      ...deleteModal,
      status: false,
    });
    setRegPassword(passwordInitial);
    setRepeatPassword(passwordInitial);
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const sendRequest = async (handleSuccess: () => void, data: clientItemType, forEdit?: boolean) => {
    setIsLoading(true);
    let response;
    const newData = {
      ...data,
      phone: data.phone.replace("+", ""),
    };
    if (forEdit) {
      response = await generalCRUD.editConcreteData("user", newData.id, newData);
    } else {
      delete clientItem.id;
      response = await generalCRUD.addNewClient("registration", newData);
    }

    if (response) {
      if (response.code === 200) {
        handleSuccess();
        if (forEdit && regPassword.value) {
          changePassword();
        }
      } else {
        return toastr.error("Error", response.message);
      }
    }

    setIsLoading(false);
  };

  const sendRequestDelete = async () => {
    closeModal();
    const response = await generalCRUD.deleteData("admin/user", clientItem.id);
    if (response.code === 200) {
      getAllClients();
      toastr.success("Client was deleted successfully", "");
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const addEntity = async () => {
    const newData = {
      ...clientItem,
      password: regPassword.value ? String(Md5.hashStr(regPassword.value)) : "",
      phone: clientItem.phone.replace("+", ""),
    };
    setClientItem(newData);
    const handleSuccess = () => {
      getAllClients();
      toastr.success("New Client was added successfully", "");
      closeModal();
    };

    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, newData, false);
    }
  };

  const deleteEntity = (index: number): void => {
    sendRequestDelete();
  };

  const changePassword = async () => {
    const data = {
      newPassword: String(Md5.hashStr(regPassword.value)),
    };
    const response = await generalCRUD.changePassword("admin/user/change/password/", clientItem.id, data);

    if (response.code === 200) {
      toastr.success("New Password was added successfully", "");
      closeModal();
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<clientItemType> = [...clientList];
      newData[index] = { ...clientItem };
      setClientList(newData);
      toastr.success("Your Client was edited successfully", "");
      closeModal();
    };

    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, clientItem, true);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, field: string, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...clientItem,
      [field]: value,
    };
    setClientItem(newData);
  };

  const onBlurFirstPass = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    validateField(e.target.value, firstPasswordValid, setFirstPasswordValid, {
      secondPass: secondPasswordValid,
      setSecondPass: setSecondPasswordValid,
      anotherPass: repeatPassword.value,
    });
  };

  const onBlurSecondPass = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    validateField(e.target.value, secondPasswordValid, setSecondPasswordValid, {
      secondPass: firstPasswordValid,
      setSecondPass: setFirstPasswordValid,
      anotherPass: regPassword.value,
    });
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Your name</p>
          <div>
            <TextField
              helperText={nameValid.error}
              error={nameValid.valid === false}
              value={clientItem.username}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
              placeholder="Name"
              type="text"
              onChange={(event) => textChangeHandler(event, "username", false)}
              onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Company name</p>
          <div>
            <TextField
              helperText={companyNameValid.error}
              error={companyNameValid.valid === false}
              value={clientItem.companyName || ""}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: companyNameValid.maxLength } }}
              placeholder="Company"
              type="text"
              onChange={(event) => textChangeHandler(event, "companyName", false)}
              onBlur={(e) => validateField(e.target.value, companyNameValid, setCompanyNameValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit half">
          <p className="sub-title">Mail</p>
          <div>
            <TextField
              helperText={emailValid.error}
              error={emailValid.valid === false}
              value={clientItem.email}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: emailValid.maxLength } }}
              placeholder="example@mail.com"
              type="text"
              onChange={(event) => textChangeHandler(event, "email", false)}
              onBlur={(e) => validateField(e.target.value, emailValid, setEmailValid)}
            />
          </div>
        </div>
        <div className="unit half">
          <p className="sub-title">Phone</p>
          <div>
            <NumberFormat
              customInput={TextField}
              prefix={"+"}
              helperText={phoneValid.error}
              error={phoneValid.valid === false}
              value={clientItem.phone}
              className="base-input"
              inputProps={{ maxLength: phoneValid.maxLength }}
              placeholder="+ 345 (45) 678-90-12"
              allowNegative={false}
              isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, false) : true)}
              onChange={(event) => textChangeHandler(event, "phone", false)}
              onBlur={(e) => validateField(e.target.value, phoneValid, setPhoneValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Address</p>
          <div>
            <TextField
              helperText={addressValid.error}
              error={addressValid.valid === false}
              value={clientItem.companyAddress || ""}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: addressValid.maxLength } }}
              placeholder="Address"
              type="text"
              onChange={(event) => textChangeHandler(event, "companyAddress", false)}
              onBlur={(e) => validateField(e.target.value, addressValid, setAddressValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Canadian Business number (optional)</p>
          <div>
            <TextField
              helperText={businessNumberValid.error}
              error={businessNumberValid.valid === false}
              value={clientItem.canadianBusinessNumber || ""}
              InputProps={{
                disableUnderline: true,
                inputProps: { maxLength: businessNumberValid.maxLength },
              }}
              placeholder="Canadian Business number"
              type="text"
              onChange={(event) => textChangeHandler(event, "canadianBusinessNumber")}
              onBlur={(e) => validateField(e.target.value, businessNumberValid, setBusinessNumberValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit half">
          <p className="sub-title">Password</p>
          <div>
            <TextField
              helperText={firstPasswordValid.error}
              error={firstPasswordValid.valid === false}
              value={regPassword.value}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: firstPasswordValid.maxLength } }}
              type="password"
              onChange={(event) => changeInputPassHandler(event.target.value, regPassword, setRegPassword)}
              onBlur={onBlurFirstPass}
            />
          </div>
        </div>
        <div className="unit half">
          <p className="sub-title">Repeat password</p>
          <div>
            <TextField
              helperText={secondPasswordValid.error}
              error={secondPasswordValid.valid === false}
              value={repeatPassword.value}
              InputProps={{
                disableUnderline: true,
                inputProps: { maxLength: secondPasswordValid.maxLength },
              }}
              type="password"
              onChange={(event) => changeInputPassHandler(event.target.value, repeatPassword, setRepeatPassword)}
              onBlur={onBlurSecondPass}
            />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="clients" className="page-container">
        <TopPartPage title="Clients" buttonTitle="Register new Client" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th sortable first" onClick={() => sortOrdersHandler("id")}>
            <div className={defineThClassName("id")}>&nbsp;</div>
            Id
          </div>
          <div className="th sortable" onClick={() => sortOrdersHandler("username")}>
            <div className={defineThClassName("username")}>&nbsp;</div>
            Name
          </div>
          <div className="th">Mail</div>
          <div className="th">Phone</div>
          <div className="th sortable" onClick={() => sortOrdersHandler("company_name")}>
            <div className={defineThClassName("companyName")}>&nbsp;</div>
            Company name
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {clientList.map((item: clientItemType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <Link className="th first" to={`/clients/${item.id}`}>
                  <div>{item.id}</div>
                </Link>
                <Link className="th" to={`/clients/${item.id}`}>
                  <div>{item.username}</div>
                </Link>
                <Link className="th" to={`/clients/${item.id}`}>
                  <div className={item.confirmed ? "email-confirmed" : "email-simple"}>{item.email}</div>
                </Link>
                <Link className="th" to={`/clients/${item.id}`}>
                  <div>{item.phone}</div>
                </Link>
                <Link className="th" to={`/clients/${item.id}`}>
                  <div>{item.companyName}</div>
                </Link>
                <div className="th last">
                  <FormControl>
                    <img src={DotsMenu} className="dots-position" alt="dots-menu" />
                    <Select value={""} displayEmpty>
                      <MenuItem onClick={() => openEditModal(item, index)}>Edit</MenuItem>
                      <MenuItem className="delete-client-item" onClick={() => openDeleteModal(item, index)}>
                        Delete
                      </MenuItem>
                      <MenuItem className="view-client-item" onClick={() => viewClient(item, index)}>View</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </li>
            );
          })}
        </ul>
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="New client`s registration"
          classes="client-modal"
          description="You can registrate a new client"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit user`s information"
          classes="client-modal"
          description="You can edit User`s information"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={deleteModal.status}
          handleClose={closeModal}
          handleSubmit={() => deleteEntity(deleteModal.index)}
          title="Delete user`s information"
          description="Attention! Deleting of the client's account leads to the deleting of the booking request"
        >
          {}
        </ModalWindow>
        <CustomPagination
          page={props.page}
          maxPage={props.maxPage}
          isLoaded={!isLoading}
          itemsNumber={clientList.length}
          handleClickPage={(value) => getAllClients(value)}
          handleClickQuantity={(quantity) => getAllClients(undefined, undefined, quantity)}
        />
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    page: state.clients.page,
    maxPage: state.clients.maxPage,
    sortedColumn: state.clients.sortedColumn,
    quantityPerPage: state.app.quantityPerPage,
  };
};

export default connect(mapStateToProps)(withRouter(ClientsContainer));
