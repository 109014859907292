export const orderDataInitial = {
  id: 0,
  email: "",
  phone: "",
  created: 0,
  yourName: "",
  hashSum: "",
  customCode: "",
  lastEventName: "",
  cityName: "",
  cartageName: "",
  shipmentVolume: "",
  shipmentDescription: "",
  status: 0,
  lastEventTime: 0,
  lastEventId: 0,
  userId: null,
  companyName: "",
  companyNameShipper: "",
  amazonRefId: "",
  transportationMode: 0,
  shipmentDestination: 0,
  shipmentOriginPort: 0,
  shipmentWeight: "",
  estimatedTransportation: "",
  customBrokerage: 0,
  shipmentTradeTerms: 0,
  cargoInsurance: false,
  shipmentValue: "",
  lastEventPublicStatus: "",
  companyAddress: "",
  additionalInfo: "",
  shipmentPurchase: "",
  suppliersInvoiceNumber: "",
  otherReferenceUsed: "",
  date: "",
  companyAddressShipper: "",
  namePersonCharge: "",
  emailShipper: "",
  phoneShipper: "",
  additionalInformationShipper: "",
  shipmentId: "",
  merchantName: "",
  additionalInformation: "",
  bookingConfirmation: null,
  user: {
    canadianBusinessNumber: null,
    companyAddress: "",
    companyName: "",
    email: "",
    name: "",
    phone: "",
    confirmed: false,
  },
};
/*
 *
 * orders reducer
 *
 */
const initialState = {
  page: 1,
  maxPage: 1,
  orderData: orderDataInitial,
  sortedColumn: {
    type: "desc",
    name: "id",
  },
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case "ORDERS_PAGE":
      return Object.assign({}, state, {
        page: action.payload.page,
        maxPage: action.payload.maxPage,
      });

    case "ORDER_DATA":
      return Object.assign({}, state, {
        orderData: action.payload.orderData,
      });

    case "SORTED_COLUMN":
      return Object.assign({}, state, {
        sortedColumn: action.payload.sortedColumn,
      });

    default:
      return state;
  }
};

export default orders;
