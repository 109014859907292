import { generalCRUD as generalCRUDApi } from "../api/generalCRUD";

import { generalCRUDActionType } from "../types/actions/generalCRUD";

export const generalCRUD: generalCRUDActionType = {
  async getAllData(path, page, sort, quantity, rest) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.getAllData(path, token, page, sort, quantity, rest);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getConcreteData(path, id) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.getConcreteData(path, token, id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async addNewData(path, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.addNewData(path, token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async addNewClient(path, data) {
    try {
      const response = await generalCRUDApi.addNewClient(path, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async editConcreteData(path, id, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.editConcreteData(path, token, id, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async editDeliveryData(path, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.editDeliveryData(path, token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async deleteData(path, id) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.deleteData(path, token, id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async editUserData(path, data, id) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.editUserData(path, token, data, id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },

  async getSimpleList(path) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.getSimpleList(path, token);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getUserBookings(path, id) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.getUserBookings(path, token, id);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async changePassword(path, id, data) {
    const token = localStorage.getItem("token");
    try {
      const response = await generalCRUDApi.changePassword(path, token, id, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getFormulaResult(path, data) {
    try {
      const response = await generalCRUDApi.getFormulaResult(path, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
};
