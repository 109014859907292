import React, { useEffect, FC, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// components
import LayoutSign from "./LayoutSign";
import OrdersContainer from "../containers/Orders";
import OrdersConcreteContainer from "../containers/OrdersConcrete";
import ClientsContainer from "../containers/Clients";
import ClientsConcreteContainer from "../containers/ClientsConcrete";
import CsrContainer from "../containers/Csr";
import BlogContainer from "../containers/Blog";
import EventsContainer from "../containers/Events";
import FreightContainer from "../containers/Freight";
import PortsContainer from "../containers/Ports";
import CitiesContainer from "../containers/Cities";
import VesselsContainer from "../containers/Vessels";
import CartagesContainer from "../containers/Cartages";
import DepotsContainer from "../containers/Depots";
import ConstantsContainer from "../containers/Constants";
import LocationsContainer from "../containers/Locations";
import DeliveryTimeContainer from "../containers/DeliveryTime";
import LoginContainer from "../containers/Login";
import SetEventContainer from "../containers/SetEvent";
import BlogArticleContainer from "../containers/BlogArticle";
import TemplatesPageContainer from "../containers/TemplatesPage";
import MailContainer from "../containers/Mail";
import HtmlBlockContainer from "../containers/HtmlBlock";
import MailSettingsContainer from "../containers/MailSettings";
import HintsContainer from "../containers/Hints";
import NotFoundContainer from "../containers/NotFound";
import ReduxToastr from "react-redux-toastr";
import NewOrderContainer from "../containers/NewOrder";

// style
import "./style.scss";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

const App: FC = () => {
  useEffect(() => {
    const token: string | null = localStorage.getItem("token");
    const userId: string | null = localStorage.getItem("userId");
    if (token && userId) {
    }
  });

  return (
    <Suspense fallback={null}>
      <Router>
        <Switch>
          <LayoutSign exact path={`/`} component={OrdersContainer} />
          <LayoutSign exact path={`/orders`} component={OrdersContainer} />
          <LayoutSign exact path={`/orders/*`} component={OrdersConcreteContainer} />
          <LayoutSign exact path={`/clients`} component={ClientsContainer} />
          <LayoutSign exact path={`/clients/*`} component={ClientsConcreteContainer} />
          <LayoutSign exact path={`/csr`} component={CsrContainer} />
          <LayoutSign exact path={`/blog`} component={BlogContainer} />
          <LayoutSign exact path={`/events`} component={EventsContainer} />
          <LayoutSign exact path={`/freight`} component={FreightContainer} />
          <LayoutSign exact path={`/ports`} component={PortsContainer} />
          <LayoutSign exact path={`/cities`} component={CitiesContainer} />
          <LayoutSign exact path={`/vessels`} component={VesselsContainer} />
          <LayoutSign exact path={`/cartages`} component={CartagesContainer} />
          <LayoutSign exact path={`/delivery-time`} component={DeliveryTimeContainer} />
          <LayoutSign exact path={`/depots`} component={DepotsContainer} />
          <LayoutSign exact path={`/constants`} component={ConstantsContainer} />
          <LayoutSign exact path={`/locations`} component={LocationsContainer} />
          <LayoutSign exact path={`/set-event/*`} component={SetEventContainer} />
          <LayoutSign exact path={`/create-article`} component={BlogArticleContainer} />
          <LayoutSign exact path={`/edit-article/*`} component={BlogArticleContainer} />
          <LayoutSign exact path={`/templates`} component={TemplatesPageContainer} />
          <LayoutSign exact path={`/create-mail`} component={MailContainer} />
          <LayoutSign exact path={`/edit-mail/*`} component={MailContainer} />
          <LayoutSign exact path={`/create-html-block`} component={HtmlBlockContainer} />
          <LayoutSign exact path={`/edit-html-block/*`} component={HtmlBlockContainer} />
          <LayoutSign exact path={`/mail-settings`} component={MailSettingsContainer} />
          <LayoutSign exact path={`/hints`} component={HintsContainer} />
          <LayoutSign exact path={`/new-order/*`} component={NewOrderContainer} />
          <Route path="/login" component={LoginContainer} />
          <Route path="*" component={NotFoundContainer} />
        </Switch>
      </Router>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
        className="toastr-custom"
      />
    </Suspense>
  );
};

export default App;
