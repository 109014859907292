import React from "react";
import Dropzone, { IDropzoneProps, ILayoutProps } from "react-dropzone-uploader";
import ExtensionBox from "../../atoms/ExtensionBox";

import "react-dropzone-uploader/dist/styles.css";
import "./style.scss";

type UploaderPropsType = {
  files: Array<any>;
  setter: ([]) => void;
  deleteFile: (number) => void;
  setterError?: (string) => void;
  maxByteSize?: number;
  maxFiles?: number;
  disabled?: boolean;
};

const Uploader = (props: UploaderPropsType) => {
  const withFiles = !!props.files.length;
  const maxFiles = props.maxFiles ? props.maxFiles : Infinity;

  const getUploadParams: IDropzoneProps["getUploadParams"] = (file) => {
    if (file.meta.size === 0) {
      props.setterError("minBytesSize");
      return { url: "" };
    }
    if (props.maxByteSize) {
      let sumSize = 0;
      props.files.forEach((item) => {
        sumSize += Number(item.meta.size);
      });
      sumSize += file.meta.size;
      if (sumSize > props.maxByteSize * 1000000) {
        props.setterError("maxBytesSize");
        return { url: "" };
      }
    }
    const data = [...props.files];
    data.push(file);
    props.setter(data);
    return { url: "" };
  };

  const Layout = ({ input, dropzoneProps }: ILayoutProps) => {
    return (
      <div id="uploader" className={props.disabled ? "disable-uploading" : ""}>
        <div {...dropzoneProps} className={withFiles ? "dzu-dropzone full" : "dzu-dropzone"}>
          <ul className="preview-list">
            {props.files.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`preview-item${
                    item.hasOwnProperty("validation") && !item.validation ? " preview-item-error" : ""
                  }`}
                >
                  {<ExtensionBox fileName={item.meta.name} />}
                  <span className="preview-title">{item.meta.name}</span>
                  <span className="remove-icon" onClick={() => props.deleteFile(index)}>
                    &nbsp;
                  </span>
                </li>
              );
            })}
          </ul>
          {props.files.length < maxFiles && input}
        </div>
      </div>
    );
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      disabled={props.disabled}
      LayoutComponent={Layout}
      classNames={{ inputLabelWithFiles: withFiles ? "full-input" : "dzu-inputLabel" }}
      inputContent="Load document"
      accept={".pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .bmp, .tiff"}
      inputWithFilesContent={withFiles ? "Load more" : "Load document"}
    />
  );
};

export default Uploader;
