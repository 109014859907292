import React, { FC, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import InstantLCLQuote from "../../organisms/BookingRequests/InstantLCLQuote";
import QuoteRequest from "../../organisms/BookingRequests/QuoteRequest";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Styles
import "./style.scss";

type userType = {
  user: any;
};

const NewOrderContainer: FC<any> = (props: userType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [bookingTitle, setBookingTitle] = useState<string>("Instant LCL Quote");

  const [shipmentTrade, setShipmentTrade] = React.useState<number>(3);
  const [transportationMode, setTransportationMode] = React.useState<string>("0");

  const [radioLoader, setRadioLoader] = React.useState<boolean>(true);

  const handleShipmentTrade = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentTrade(Number((event.target as HTMLInputElement).value));
    // if (shipmentTrade === "0" && transportationMode === "0") {
    //   quote();
    // } else if (shipmentTrade === "1" && transportationMode === "0") {
    //   instant();
    // }
  };

  const handleTransportationMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransportationMode((event.target as HTMLInputElement).value);
    // if (transportationMode === "0" && shipmentTrade === "0") {
    //   quote();
    // } else if (shipmentTrade === "1" && transportationMode === "0") {
    //   quote();
    // } else if (shipmentTrade === "0" && transportationMode === "1") {
    //   instant();
    // }
    transportationMode === "1" ? instant() : quote();
  };

  const instant = () => {
    setTabValue(0);
    setBookingTitle("Instant LCL Quote");
  };
  const quote = () => {
    setTabValue(1);
    setBookingTitle("Quote Request");
  };

  const getRadioLoader = (value: boolean) => {
    setRadioLoader(value);
  };

  return (
    <MainTemplate>
      <div id="new-order" className="new-order">
        <TopPartPage title="New order" />
        <div className="main-info">
          <div className="booking-title">{bookingTitle}</div>
          <div className="flex switcher-wrapper">
            <div className="flex-item">
              <div className="sub-title">Shipment trade terms</div>
              <div>
                <RadioGroup
                  aria-label="shipment_trade"
                  name="shipment_trade"
                  value={shipmentTrade}
                  onChange={handleShipmentTrade}
                >
                  <FormControlLabel
                    disabled={radioLoader}
                    value={3}
                    control={<Radio />}
                    label="Pick-Up from Supplier"
                  />
                  <FormControlLabel disabled={radioLoader} value={2} control={<Radio />} label="Drop-Off by Supplier" />
                  <FormControlLabel
                    disabled={radioLoader}
                    value={1}
                    control={<Radio />}
                    label="Supplier pays all origin fees (FOB)"
                  />
                  <FormControlLabel disabled={radioLoader} value={0} control={<Radio />} label="Other" />
                </RadioGroup>
              </div>
            </div>
            <div className="flex-item">
              <div className="sub-title">Transportation mode</div>
              <RadioGroup
                aria-label="transportation_mode"
                name="transportation_mode"
                value={transportationMode}
                onChange={handleTransportationMode}
              >
                <FormControlLabel value="0" control={<Radio />} label="Less-than-Container Load" />
                <FormControlLabel value="1" control={<Radio />} label="Full Container Load" />
              </RadioGroup>
            </div>
          </div>
          <div>
            {tabValue === 0 && <InstantLCLQuote shipmentTrade={shipmentTrade} getRadioLoader={getRadioLoader} />}
            {tabValue === 1 && <QuoteRequest />}
          </div>
        </div>
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(NewOrderContainer));
