/*
 *
 * clients reducer
 *
 */
const initialState = {
  page: 1,
  maxPage: 1,
  orderData: {},
  sortedColumn: {
    type: "desc",
    name: "id",
  },
  filterParams: {},
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case "CLIENTS_PAGE":
      return Object.assign({}, state, {
        page: action.payload.page,
        maxPage: action.payload.maxPage,
      });

    case "CLIENT_DATA":
      return Object.assign({}, state, {
        clientData: action.payload.clientData,
      });

    case "SORTED_CLIENTS_COLUMN":
      return Object.assign({}, state, {
        sortedColumn: action.payload.sortedColumn,
      });

    case "FILTER_CLIENTS_COLUMN":
      return Object.assign({}, state, {
        filterParams: action.payload.filterParams,
      });

    default:
      return state;
  }
};

export default clients;
