import React, { useEffect, FC, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { orderDataType } from "../../../types/actions/orders";
import { orderDataInitial } from "../../../redux/reducers/orders";
import { Md5 } from "ts-md5/dist/md5";

// Components
import helpFunctions from "../../../tools/helpFunctions";
import { generalCRUD } from "../../../actions/generalCRUD";
import MainTemplate from "../templates/MainTemplate";
import { toastr } from "react-redux-toastr";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import QuotesTable from "../../organisms/QuotesTable";
import TextField from "@material-ui/core/TextField";
import LoaderLocal from "../../atoms/LoaderLocal";

// Styles
import "./style.scss";
import { FieldType } from "../../../validator/types";
import { defaultValidationProperties, validateAllFields, validateField } from "../../../validator/Fields";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";
import { emailField, phoneField } from "../../../validator/Fields/quoteRequest";
import { allField } from "../Clients";
import NumberFormat from "react-number-format";

type userType = {
  user: any;
  history: any;
};

type clientItemType = {
  id: number;
  canadianBusinessNumber: string;
  companyAddress: string;
  companyName: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  confirmed: boolean;
  [key: number]: number;
};

const clientItemInitial = {
  id: 0,
  canadianBusinessNumber: "",
  companyAddress: "",
  companyName: "",
  email: "",
  phone: "",
  username: "",
  password: "",
  confirmed: false,
};

const passwordFieldForEdit: FieldType = {
  ...defaultValidationProperties,
  maxLength: 50,
  dependencies: [
    { depCase: "minLength", attr: { minLength: 8 } },
    { depCase: "maxLength", attr: { maxLength: 150 } },
    { depCase: "passwords", attr: { isEdit: true } },
  ],
};

const ClientsConcreteContainer: FC<any> = (props: userType) => {
  type passwordType = {
    value: string;
    valid: boolean;
    touched: boolean;
    required: boolean;
    error: string;
    maxLength: number;
    minLength: number;
    reference: any;
  };
  const passwordInitial = {
    value: "",
    valid: true,
    touched: false,
    required: true,
    error: "",
    maxLength: 255,
    minLength: 7,
    reference: useRef(null),
  };
  const [clientItem, setClientItem] = useState<clientItemType>(clientItemInitial);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regPassword, setRegPassword] = useState<passwordType>(passwordInitial);
  const [repeatPassword, setRepeatPassword] = useState<passwordType>(passwordInitial);
  const [quotesData, setQuotesData] = useState<[orderDataType]>([orderDataInitial]);

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(50) });
  const [companyNameValid, setCompanyNameValid] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [emailValid, setEmailValid] = useState<FieldType>({ ...emailField });
  const [phoneValid, setPhoneValid] = useState<FieldType>({ ...phoneField });
  const [addressValid, setAddressValid] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [businessNumberValid, setBusinessNumberValid] = useState<FieldType>({ ...maxLengthInstance(15, false) });
  const [firstPasswordValid, setFirstPasswordValid] = useState<FieldType>({ ...passwordFieldForEdit });
  const [secondPasswordValid, setSecondPasswordValid] = useState<FieldType>({ ...passwordFieldForEdit });

  const allFields: Array<allField> = [
    { validate: { ...nameValid, value: clientItem.username }, setValidate: setNameValid },
    { validate: { ...companyNameValid, value: clientItem.companyName || "" }, setValidate: setCompanyNameValid },
    { validate: { ...emailValid, value: clientItem.email }, setValidate: setEmailValid },
    { validate: { ...phoneValid, value: clientItem.phone }, setValidate: setPhoneValid },
    { validate: { ...addressValid, value: clientItem.companyAddress || "" }, setValidate: setAddressValid },
    {
      validate: { ...businessNumberValid, value: clientItem.canadianBusinessNumber || "" },
      setValidate: setBusinessNumberValid,
    },
    {
      validate: { ...firstPasswordValid, value: regPassword.value || "" },
      setValidate: setFirstPasswordValid,
      props: {
        secondPass: secondPasswordValid,
        setSecondPass: setSecondPasswordValid,
        anotherPass: repeatPassword.value,
      },
    },
    {
      validate: { ...secondPasswordValid, value: repeatPassword.value || "" },
      setValidate: setSecondPasswordValid,
      props: { secondPass: firstPasswordValid, setSecondPass: setFirstPasswordValid, anotherPass: regPassword.value },
    },
  ];

  const changeInputPassHandler = (value, name, setName) => {
    setName({ value: value });
  };

  const changePassword = async () => {
    const data = {
      newPassword: String(Md5.hashStr(regPassword.value)),
    };
    const response = await generalCRUD.changePassword(
      "admin/user/change/password/",
      parseInt(helpFunctions.getParamIdFromUrl(), 10),
      data,
    );

    if (response.code === 200) {
      toastr.success("New Password was added successfully", "");
      closeModal();
    } else {
      return toastr.error("Error", response.message);
    }
  };

  useEffect(() => {
    getUserBookings();
    getUserInfo();
  }, []);

  const getUserBookings = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getUserBookings("request/booking/get/user", helpFunctions.getParamIdFromUrl());
    if (response.code === 200) {
      setQuotesData(response.data);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    const clientId = parseInt(helpFunctions.getParamIdFromUrl(), 10);
    const response = await generalCRUD.getConcreteData("user", clientId);
    // @ts-ignore
    if (response.code === 200) {
      setClientItem(response.data);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const closeModal = (): void => {
    setIsEditModal(false);
    setRegPassword(passwordInitial);
    setRepeatPassword(passwordInitial);
    getUserInfo();
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "" }));
      }
    });
  };

  const editEntity = (): void => {
    const handleSuccess = () => {
      setClientItem(Object.assign({}, clientItem, { password: Md5.hashStr(regPassword.value) }));
      toastr.success("Your Client was edited successfully", "");
      closeModal();
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const sendRequest = async (handleSuccess: () => void) => {
    setIsLoading(true);
    const newData = {
      ...clientItem,
      phone: clientItem.phone.replace("+", ""),
    };
    const response = await generalCRUD.editConcreteData(
      "user",
      parseInt(helpFunctions.getParamIdFromUrl(), 10),
      newData,
    );

    if (response) {
      if (response.code === 200) {
        handleSuccess();
        if (regPassword.value) {
          changePassword();
        }
      } else {
        return toastr.error("Error", response.message);
      }
    }

    setIsLoading(false);
  };

  const textChangeHandler = (event: any, field: string, num?: boolean | string) => {
    const value = event.target.value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    setClientItem({
      ...clientItem,
      [field]: value,
    });
  };

  const linkToCreateOrder = (id) => {
    props.history.push(`/new-order/${id}`);
  };

  const onBlurFirstPass = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    validateField(e.target.value, firstPasswordValid, setFirstPasswordValid, {
      secondPass: secondPasswordValid,
      setSecondPass: setSecondPasswordValid,
      anotherPass: repeatPassword.value,
    });
  };

  const onBlurSecondPass = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    validateField(e.target.value, secondPasswordValid, setSecondPasswordValid, {
      secondPass: firstPasswordValid,
      setSecondPass: setFirstPasswordValid,
      anotherPass: regPassword.value,
    });
  };

  const modalEditContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Your name</p>
          <div>
            <TextField
              helperText={nameValid.error}
              error={nameValid.valid === false}
              value={clientItem.username}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
              placeholder="Name"
              type="text"
              onChange={(event) => textChangeHandler(event, "username", false)}
              onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Company name</p>
          <div>
            <TextField
              helperText={companyNameValid.error}
              error={companyNameValid.valid === false}
              value={clientItem.companyName}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: companyNameValid.maxLength } }}
              placeholder="Company"
              type="text"
              onChange={(event) => textChangeHandler(event, "companyName", false)}
              onBlur={(e) => validateField(e.target.value, companyNameValid, setCompanyNameValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit half">
          <p className="sub-title">Mail</p>
          <div>
            <TextField
              helperText={emailValid.error}
              error={emailValid.valid === false}
              value={clientItem.email}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: emailValid.maxLength } }}
              placeholder="example@mail.com"
              type="text"
              onChange={(event) => textChangeHandler(event, "email", false)}
              onBlur={(e) => validateField(e.target.value, emailValid, setEmailValid)}
            />
          </div>
        </div>
        <div className="unit half">
          <p className="sub-title">Phone</p>
          <div>
            <NumberFormat
              customInput={TextField}
              prefix={"+"}
              helperText={phoneValid.error}
              error={phoneValid.valid === false}
              value={clientItem.phone}
              className="base-input"
              inputProps={{ maxLength: phoneValid.maxLength }}
              placeholder="+ 345 (45) 678-90-12"
              allowNegative={false}
              isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, false) : true)}
              onChange={(event) => textChangeHandler(event, "phone", false)}
              onBlur={(e) => validateField(e.target.value, phoneValid, setPhoneValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Address</p>
          <div>
            <TextField
              helperText={addressValid.error}
              error={addressValid.valid === false}
              value={clientItem.companyAddress}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: addressValid.maxLength } }}
              placeholder="Address"
              type="text"
              onChange={(event) => textChangeHandler(event, "companyAddress", false)}
              onBlur={(e) => validateField(e.target.value, addressValid, setAddressValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Canadian Business number (optional)</p>
          <div>
            <TextField
              helperText={businessNumberValid.error}
              error={businessNumberValid.valid === false}
              value={clientItem.canadianBusinessNumber || ""}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: businessNumberValid.maxLength } }}
              placeholder="Canadian Business number"
              type="text"
              onChange={(event) => textChangeHandler(event, "canadianBusinessNumber")}
              onBlur={(e) => validateField(e.target.value, businessNumberValid, setBusinessNumberValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit half">
          <p className="sub-title">Password</p>
          <div>
            <TextField
              helperText={firstPasswordValid.error}
              error={firstPasswordValid.valid === false}
              value={regPassword.value}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: firstPasswordValid.maxLength } }}
              type="password"
              onChange={(event) => changeInputPassHandler(event.target.value, regPassword, setRegPassword)}
              onBlur={onBlurFirstPass}
            />
          </div>
        </div>
        <div className="unit half">
          <p className="sub-title">Repeat password</p>
          <div>
            <TextField
              helperText={secondPasswordValid.error}
              error={secondPasswordValid.valid === false}
              value={repeatPassword.value}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: secondPasswordValid.maxLength } }}
              type="password"
              onChange={(event) => changeInputPassHandler(event.target.value, repeatPassword, setRepeatPassword)}
              onBlur={onBlurSecondPass}
            />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="clientsConcrete" className="page-container">
        <TopPartPage
          title="Client information"
          buttonTitle="Edit information"
          handleClick={() => setIsEditModal(true)}
        />
        <div className="main-info">
          <div className="item-wrapper">
            <div className="title-info">Contact name</div>
            <div className="description-info">{clientItem.username}&#160;</div>
          </div>
          <div className="item-wrapper">
            <div className="title-info">Company</div>
            <div className="description-info">{clientItem.companyName}&#160;</div>
          </div>
          <div className="item-wrapper">
            <div className="title-info">Phone</div>
            <div className="description-info">{clientItem.phone}&#160;</div>
          </div>
          <div className="item-wrapper">
            <div className="title-info">Mail</div>
            <div className={clientItem.confirmed ? "description-info email-confirmed" : "description-info"}>
              {clientItem.email}&#160;
            </div>
          </div>
        </div>
        <div className="orders">
          <TopPartPage
            title="orders"
            buttonTitle="Create new order"
            // classes={"disable"}
            handleClick={() => linkToCreateOrder(clientItem.id)}
          />
          <div className="order-list">
            <QuotesTable data={quotesData} />
          </div>
        </div>
      </div>
      {isLoading && <LoaderLocal />}
      <ModalWindow
        open={isEditModal}
        handleClose={closeModal}
        handleSubmit={editEntity}
        classes="selected-user-modal"
        title="Edit user`s information"
        description="You can edit User`s information"
      >
        {modalEditContent}
      </ModalWindow>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(ClientsConcreteContainer));
