import React, { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";
import ReactHtmlParser from "react-html-parser";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import 'moment-timezone';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import WEditor from "../../../molecules/WEditor";
import { orders } from "../../../../actions/orders";
import helpFunctions from "../../../../tools/helpFunctions";

// Styles
import "./style.scss";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../../validator/Fields";
import { FieldType } from "../../../../validator/types";
import { maxLengthInstance } from "../../../../validator/Fields/instantLCLQuote";

let launchMoment = require('moment');
require('moment-timezone');
moment.tz.setDefault('UTC');
type portCityItemType = {
  code: string;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
  groupName: string;
};

const portCityItemInitial = {
  code: "",
  createdAt: "",
  id: 0,
  name: "",
  updatedAt: "",
  groupName: "",
};

export type vesselsItemType = {
  id: number;
  imoNumber: string;
  name: string;
};

export type bookingConfirmationType = {
  placeOfReceipt: string;
  cargoReadyAt: any;
  cargoCutOffAt: any;
  documentationCutOffAt: any;
  portOfLoading: string;
  estimatedTimeOfDepartureAt: any;
  actualTimeOfDepartureAt: any;
  portOfDischarge: string;
  portEstimatedTimeOfArrivalAt: any;
  portActualTimeOfArrivalAt: any;
  placeOfDelivery: string;
  placeEstimatedTimeOfArrivalAt: any;
  placeActualTimeOfArrivalAt: any;
  vessel: { id: number; name: string };
  vesselVoyage: string;
  bookingClauses: string;
};

export const bookingConfirmationInitial = {
  placeOfReceipt: "",
  cargoReadyAt: null,
  cargoCutOffAt: null,
  documentationCutOffAt: null,
  portOfLoading: "",
  estimatedTimeOfDepartureAt: null,
  actualTimeOfDepartureAt: null,
  portOfDischarge: "",
  portEstimatedTimeOfArrivalAt: null,
  portActualTimeOfArrivalAt: null,
  placeOfDelivery: "",
  placeEstimatedTimeOfArrivalAt: null,
  placeActualTimeOfArrivalAt: null,
  vessel: { id: null, name: "" },
  vesselVoyage: "",
  bookingClauses: "",
};

type orderDetailsType = {
  orderData: orderDataType;
};

type dateValidationType = {
  [key: string]: boolean;
};

const BookingСonfirmation: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);
  const [bookingConfirmation, setBookingConfirmation] = useState<bookingConfirmationType>(bookingConfirmationInitial);

  const [vesselsList, setVesselsList] = useState<Array<vesselsItemType>>([]);

  const [portList, setPortList] = useState<[portCityItemType]>([portCityItemInitial]);
  const [cityList, setCityList] = useState<[portCityItemType]>([portCityItemInitial]);

  /*Validation*/
  const [vesselVoyageValid, setVesselVoyageValid] = useState<FieldType>({
    ...maxLengthInstance(100, false),
    value: bookingConfirmation.vesselVoyage,
  });
  const [datesValid, setDatesValid] = useState<dateValidationType>({});

  const dispatch = useDispatch();

  useEffect(() => {
    getAllPorts(1, 1000);
    getAllCites(1, 1000);
    getAllVessel(1, 1000);
    if (props.orderData.bookingConfirmation !== null) {
      setBookingConfirmation(props.orderData.bookingConfirmation);
    }
  }, []);

  const getAllPorts = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("port", page, null, quantity);
    if (response.code === 200) {
      setPortList(response.data.items);
    }
  };

  const getAllCites = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("city", page, null, quantity);
    if (response.code === 200) {
      setCityList(response.data.items);
    }
  };

  const getAllVessel = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("vessel", page, null, quantity);
    if (response.code === 200) {
      setVesselsList(response.data.items);
    }
  };

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const canselHendler = () => {
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    let result = true;
    for (const key in datesValid) {
      if (datesValid[key] === false) {
        result = false;
        break;
      }
    }
    if (result && validateAllFields([{ validate: vesselVoyageValid, setValidate: setVesselVoyageValid }])) {
      if (!bookingConfirmation.vessel || bookingConfirmation.vessel.id === null) {
        delete bookingConfirmation.vessel;
      }
      sendRequest(bookingConfirmation, orderData.id);
    }
  };

  const sendRequest = async (data: any, id: number) => {
    const response = await generalCRUD.editUserData("request/booking/", data, id + "/confirmation/edit");
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      const response = await orders.getOrderInfo(+helpFunctions.getParamIdFromUrl());
      if (response.code === 200) {
        dispatch({
          type: "ORDER_DATA",
          payload: {
            orderData: response.data,
          },
        });
      }
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...bookingConfirmation,
      [key]: value,
    };
    setBookingConfirmation(newData);
  };

  const handleSelectVessel = (event: React.ChangeEvent<{ value: unknown }>, key: string) => {
    const vesselObj: Array<vesselsItemType> = vesselsList.filter((item: vesselsItemType) => {
      return item.id === (event.target.value as number);
    });
    const newData = {
      ...bookingConfirmation,
      [key]: { id: event.target.value, name: vesselObj[0].name },
    };
    setBookingConfirmation(newData);
  };

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>, key: string) => {
    const newData = {
      ...bookingConfirmation,
      [key]: event.target.value,
    };
    setBookingConfirmation(newData);
  };

  const handleDateChange = (date, key: string) => {
    const newData = {
      ...bookingConfirmation,
      [key]: date,
    };
    if (date == "Invalid Date") {
      setDatesValid((prevState) => ({ ...prevState, [key]: false }));
    } else {
      setDatesValid((prevState) => ({ ...prevState, [key]: true }));
    }
    setBookingConfirmation(newData);
  };

  const handleEditorChange = (value) => {
    const newData = {
      ...bookingConfirmation,
      bookingClauses: value,
    };
    setBookingConfirmation(newData);
  };

  const dateFormat = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString("en-US",{timeZone: "UTC"});
    } else {
      return null;
    }
  };
  const timeFormat = (date) => {
    if (date) {
      return new Date(date).toLocaleTimeString("en-US", {timeZone: "UTC"});
    } else {
      return null;
    }
  };

  const convertCodeToName = (code) => {
    let name = "";
    portList.concat(cityList).map((item) => {
      if (item.code === code) {
        name = item.name;
      }
    });
    return name;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="booking-confirmation">
      <div className="tab-header">
        <div className="tab-header-title">Booking Сonfirmation</div>
        <div onClick={editHendler} className={!lineEditing ? "show" : "hide"}>
          Edit
        </div>
      </div>
      <div className="border booking-confirmation-fix">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item-wrapper">
            <div className="tab-item">
              <div className="title-summary">Place of Receipt</div>
              <div className="description-summary">
                {lineEditing ? (
                  <FormControl>
                    <Select
                      value={bookingConfirmation.placeOfReceipt ? bookingConfirmation.placeOfReceipt : ""}
                      disableUnderline={true}
                      onChange={(event) => handleSelect(event, "placeOfReceipt")}
                    >
                      <MenuItem className="no-value" value={null} disabled></MenuItem>
                      <optgroup label="City"></optgroup>
                      {cityList.map((elem, i) => {
                        return (
                          <MenuItem value={elem.code} key={i}>
                            {elem.name}
                          </MenuItem>
                        );
                      })}
                      <optgroup label="Port"></optgroup>
                      {portList.map((elem, i) => {
                        return (
                          <MenuItem value={elem.code} key={i}>
                            {elem.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <div className="value">{convertCodeToName(bookingConfirmation.placeOfReceipt)}</div>
                )}
              </div>
            </div>
            <div className="tab-item">
              <div className="title-summary">Cargo ready date</div>
              <div className="description-summary">
                {lineEditing ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      className="base-date-input"
                      format="MM/dd/yyyy"
                      value={bookingConfirmation.cargoReadyAt}
                      onChange={(event) => handleDateChange(event, "cargoReadyAt")}
                      autoOk={true}
                      InputProps={{ disableUnderline: true }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <div className="value">{dateFormat(bookingConfirmation.cargoReadyAt)}</div>
                )}
              </div>
            </div>
            <div className="tab-item">
              <div className="title-summary">Cargo cut-off date & time</div>
              <div className="description-summary">
                {lineEditing ? (
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      format="MM/DD/yyyy HH:mm"
                      className="base-date-input"
                      value={bookingConfirmation.cargoCutOffAt}
                      InputProps={{ disableUnderline: true }}
                      onChange={(event) => handleDateChange(event, "cargoCutOffAt")}
                      autoOk={true}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <div className="value">
                    {dateFormat(bookingConfirmation.cargoCutOffAt)} {timeFormat(bookingConfirmation.cargoCutOffAt)}
                  </div>
                )}
              </div>
            </div>
            <div className="tab-item">
              <div className="title-summary">Doc. cut-off date & time</div>
              <div className="description-summary">
                {lineEditing ? (
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      className="base-date-input"
                      format="MM/DD/yyyy HH:mm"
                      value={bookingConfirmation.documentationCutOffAt}
                      onChange={(event) => handleDateChange(event, "documentationCutOffAt")}
                      autoOk={true}
                      showTodayButton
                      InputProps={{ disableUnderline: true }}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <div className="value">
                    {dateFormat(bookingConfirmation.documentationCutOffAt)}{" "}
                    {timeFormat(bookingConfirmation.documentationCutOffAt)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border tab-item-wrapper-border">
            <div className="tab-header-title">Port of loading details</div>
            <div className="tab-item-wrapper">
              <div className="tab-item">
                <div className="title-summary">Port of loading</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <FormControl>
                      <Select
                        value={bookingConfirmation.portOfLoading ? bookingConfirmation.portOfLoading : ""}
                        disableUnderline={true}
                        onChange={(event) => handleSelect(event, "portOfLoading")}
                      >
                        <MenuItem className="no-value" value={null} disabled></MenuItem>
                        <optgroup label="City"></optgroup>
                        {cityList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                        <optgroup label="Port"></optgroup>
                        {portList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="value">{convertCodeToName(bookingConfirmation.portOfLoading)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ETD</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        value={bookingConfirmation.estimatedTimeOfDepartureAt}
                        onChange={(event) => handleDateChange(event, "estimatedTimeOfDepartureAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.estimatedTimeOfDepartureAt)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ATD</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        format="MM/dd/yyyy"
                        value={bookingConfirmation.actualTimeOfDepartureAt}
                        onChange={(event) => handleDateChange(event, "actualTimeOfDepartureAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.actualTimeOfDepartureAt)}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border tab-item-wrapper-border">
            <div className="tab-header-title">Port of discharge details</div>
            <div className="tab-item-wrapper">
              <div className="tab-item">
                <div className="title-summary">Port of discharge</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <FormControl>
                      <Select
                        value={bookingConfirmation.portOfDischarge ? bookingConfirmation.portOfDischarge : ""}
                        disableUnderline={true}
                        onChange={(event) => handleSelect(event, "portOfDischarge")}
                      >
                        <MenuItem className="no-value" value={null} disabled></MenuItem>
                        <optgroup label="City"></optgroup>
                        {cityList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                        <optgroup label="Port"></optgroup>
                        {portList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="value">{convertCodeToName(bookingConfirmation.portOfDischarge)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ETA</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        format="MM/dd/yyyy"
                        value={bookingConfirmation.portEstimatedTimeOfArrivalAt}
                        onChange={(event) => handleDateChange(event, "portEstimatedTimeOfArrivalAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.portEstimatedTimeOfArrivalAt)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ATA</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        format="MM/dd/yyyy"
                        value={bookingConfirmation.portActualTimeOfArrivalAt}
                        onChange={(event) => handleDateChange(event, "portActualTimeOfArrivalAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.portActualTimeOfArrivalAt)}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border tab-item-wrapper-border">
            <div className="tab-header-title">Place of delivery details</div>
            <div className="tab-item-wrapper">
              <div className="tab-item">
                <div className="title-summary">Place of delivery</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <FormControl>
                      <Select
                        value={bookingConfirmation.placeOfDelivery ? bookingConfirmation.placeOfDelivery : ""}
                        disableUnderline={true}
                        onChange={(event) => handleSelect(event, "placeOfDelivery")}
                      >
                        <MenuItem className="no-value" value={null} disabled></MenuItem>
                        <optgroup label="City"></optgroup>
                        {cityList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                        <optgroup label="Port"></optgroup>
                        {portList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.code} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="value">{convertCodeToName(bookingConfirmation.placeOfDelivery)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ETA</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        format="MM/dd/yyyy"
                        value={bookingConfirmation.placeEstimatedTimeOfArrivalAt}
                        onChange={(event) => handleDateChange(event, "placeEstimatedTimeOfArrivalAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.placeEstimatedTimeOfArrivalAt)}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">ATA</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-date-input"
                        InputProps={{ disableUnderline: true }}
                        format="MM/dd/yyyy"
                        value={bookingConfirmation.placeActualTimeOfArrivalAt}
                        onChange={(event) => handleDateChange(event, "placeActualTimeOfArrivalAt")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <div className="value">{dateFormat(bookingConfirmation.placeActualTimeOfArrivalAt)}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border tab-item-wrapper-border">
            <div className="tab-header-title">Vessel information</div>
            <div className="tab-item-wrapper">
              <div className="tab-item tab-item-vessel">
                <div className="title-summary">Vessel name</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <FormControl>
                      <Select
                        value={bookingConfirmation.vessel ? bookingConfirmation.vessel.id : ""}
                        disableUnderline={true}
                        onChange={(event) => handleSelectVessel(event, "vessel")}
                      >
                        <MenuItem className="no-value" value={""} disabled></MenuItem>
                        {vesselsList.map((elem, i) => {
                          return (
                            <MenuItem value={elem.id} key={i}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="value">{bookingConfirmation.vessel ? bookingConfirmation.vessel.name : ""}</div>
                  )}
                </div>
              </div>
              <div className="tab-item">
                <div className="title-summary">Vessel voyage</div>
                <div className="description-summary">
                  {lineEditing ? (
                    <TextField
                      helperText={vesselVoyageValid.error}
                      error={vesselVoyageValid.valid === false}
                      value={bookingConfirmation.vesselVoyage}
                      ref={inputRef}
                      InputProps={{ disableUnderline: true, inputProps: { maxLength: vesselVoyageValid.maxLength } }}
                      type="text"
                      onChange={(event) => textChangeHandler(event, "vesselVoyage")}
                      onBlur={(e) => validateField(e.target.value, vesselVoyageValid, setVesselVoyageValid)}
                    />
                  ) : (
                    <div className="value">{bookingConfirmation.vesselVoyage}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Booking clauses</div>
            <div className="description-summary">
              {lineEditing ? (
                <WEditor content={bookingConfirmation.bookingClauses} handleEditorChange={handleEditorChange} />
              ) : (
                <div className="value">{ReactHtmlParser(bookingConfirmation.bookingClauses)}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(BookingСonfirmation);
