import React, { useState, useEffect, FC, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orders } from "../../../actions/orders";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import { rootReducerType } from "../../../redux/reducers";
import { orderDataType } from "../../../types/actions/orders";
import { orderDataInitial } from "../../../redux/reducers/orders";
import { eventsItemType } from "../Events";
import { myDataType } from "../../../types/actions/user";

// Components
import MainTemplate from "../templates/MainTemplate";
import { toastr } from "react-redux-toastr";
import LoaderLocal from "../../atoms/LoaderLocal";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import QuotesTable from "../../organisms/QuotesTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventStatus from "../../organisms/orderTabs/EventStatus";
import ShipmentInfo from "../../organisms/orderTabs/ShipmentInfo";
import DocExchange from "../../organisms/orderTabs/DocExchange";
import Ship from "../../../assets/images/ship.svg";
import ShipDark from "../../../assets/images/shipDark.svg";
import Track from "../../../assets/images/track.svg";
import TrackDark from "../../../assets/images/trackDark.svg";
import ModalWindow from "../../molecules/ModalWindow";

// Styles
import "./style.scss";

type userDataBlankType = {
  value: string;
  title: string;
};

type documentType = {
  createdAt: string;
  id: number;
  name: string;
};

export type eventsHistoryType = {
  id: number;
  comment: string;
  date: number;
  deletedEmailTemplate: boolean | null;
  emailTemplate: eventsItemType | null;
  event: eventsItemType;
  user: myDataType;
  documents: Array<documentType>;
};

export type TDocument = {
  createdAt: string;
  id: number;
  name: string;
};

export type TDocuments = {
  admin: Array<TDocument>;
  user: Array<TDocument>;
  csr: Array<TDocument>;
};

const statusOptions = [
  {
    name: "In progress",
    value: 0,
  },
  {
    name: "Cancelled",
    value: 1,
  },
  {
    name: "Completed",
    value: 2,
  },
];

const OrdersConcreteContainer: FC<any> = React.memo(() => {
  const history = useHistory();
  const storeOrderData = useSelector((state: rootReducerType) => state.orders.orderData);
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState<orderDataType>(storeOrderData);
  const [quotesData, setQuotesData] = useState<Array<orderDataType>>([orderDataInitial]);
  const [eventsData, setEventsData] = useState<Array<eventsHistoryType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenUserData, setIsOpenUserData] = useState<boolean>(false);
  const [isOpenUserQuotes, setIsOpenUserQuotes] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [statusModalValue, setStatusModalValue] = useState<unknown | null>(null);
  const shouldWatchForUrl = useRef(null);
  const [documentsExchange, setDocumentsExchange] = useState<TDocuments>({ admin: [], user: [], csr: [] });

  useEffect(() => {
    if (shouldWatchForUrl.current && history.location.pathname.includes("/orders/")) {
      getOrderInfo();
      getEventsForOrderInfo();
      setTabValue(0);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!storeOrderData.id) {
      getOrderInfo();
    } else {
      getInstanceQuotes(storeOrderData.userId);
    }
    getEventsForOrderInfo();
  }, []);

  // for cleaning order store
  useEffect(() => {
    return () => {
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: orderDataInitial,
        },
      });
    };
  }, []);

  useEffect(() => {
    orderData.id && getDocExchange(orderData.id);
  }, [orderData]);

  const getDocExchange = useCallback((id) => {
    orders.getDocExchange(id).then((res) => {
      setDocumentsExchange(res.data.data);
    });
  }, []);

  useEffect(() => {
    setOrderData(storeOrderData);
  }, [storeOrderData]);

  const getOrderInfo = async () => {
    setIsLoading(true);
    const response = await orders.getOrderInfo(+helpFunctions.getParamIdFromUrl());
    if (response.code === 200) {
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
      !shouldWatchForUrl.current && getInstanceQuotes(response.data.userId);
    } else {
      if (history.action === "PUSH") {
        history.goBack();
      } else {
        document.location.href = "/orders";
      }
      toastr.error("Error", response.message);
    }
    shouldWatchForUrl.current = true;
    setIsLoading(false);
  };

  const tabUsabilityHandler = (tabName: string): boolean => {
    return !(
      (tabName === "documents" && documentsExchange?.csr.length) ||
      documentsExchange?.user.length ||
      documentsExchange?.admin.length
    );
  };

  const getInstanceQuotes = async (id: number) => {
    const result = await orders.getInstantQuotes(id);
    if (result.code === 200) {
      const newResData = [...result.data];
      newResData.forEach((item, index) => {
        newResData[index] = { ...item, shipmentVolume: Number(item.shipmentVolume) }; // convert volume to number
      });
      setQuotesData(newResData);
    }
  };

  const getEventsForOrderInfo = async () => {
    const response = await await generalCRUD.getConcreteData("request/event/data", +helpFunctions.getParamIdFromUrl());
    if (response.code === 200) {
      setEventsData(response.data);
    } else {
      toastr.error("Error", response.message);
    }
  };

  const changeStatus = async () => {
    setIsLoading(true);
    const response = await orders.editOrderInfo(orderData.id, { ...orderData, status: statusModalValue });
    if (response.code === 200) {
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
      setOrderData(response.data);
      getInstanceQuotes(response.data.userId);
    } else {
      toastr.error("Error", response.message);
    }
    setStatusModalValue(null);
    setIsLoading(false);
  };

  const changeTabValue = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabValue(newValue);
  };

  const userDataBlank = [
    {
      value: orderData.user ? orderData.user.name : "",
      title: "Contact name",
    },
    {
      value: orderData.user ? orderData.user.companyName : "",
      title: "Company",
    },
    {
      value: orderData.user ? orderData.user.phone : "",
      title: "Phone",
    },
    {
      value: orderData.user ? orderData.user.email : "",
      title: "Mail",
    },
  ];

  const progress = {
    value: "50%",
    leftDays: 21,
  };

  return (
    <MainTemplate>
      <div id="ordersConcrete">
        <div className="top-part">
          <div className="flex-between">
            <div className="number">{`${orderData.hashSum}${orderData.customCode}`}</div>
            <div className="flex-between">
              <div
                className={isOpenUserData ? "user-data active" : "user-data"}
                onClick={() => setIsOpenUserData(!isOpenUserData)}
              >
                {orderData.user ? (orderData.user.companyName ? orderData.user.companyName : orderData.user.name) : ""}
              </div>
              <div
                className={isOpenUserQuotes ? "user-data active" : "user-data"}
                onClick={() => setIsOpenUserQuotes(!isOpenUserQuotes)}
              >
                See all orders
              </div>
            </div>
          </div>
          <div className="status">
            Status:
            <Select
              value={orderData.status}
              onChange={(event) => setStatusModalValue(event.target.value)}
              input={<Input />}
              className={`circle-status status-${orderData.status}`}
            >
              {statusOptions.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.value}
                  className={`status-option-order circle-status status-${index}`}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={isOpenUserData ? "user-data-wrap open" : "user-data-wrap"}>
          <ul className="user-data-list">
            {userDataBlank.map((item: userDataBlankType, index: number) => {
              return (
                <li className="user-data-item" key={index}>
                  <div className="top">{item.title}</div>
                  <div className={item.title === "Mail" && orderData.user.confirmed ? "email-confirmed" : ""}>
                    {item.value}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={isOpenUserQuotes ? "quotes-wrap open" : "quotes-wrap"}>
          <QuotesTable data={quotesData} />
        </div>
        <div className="way-wrap">
          <img src={helpFunctions.isDarkTheme() ? ShipDark : Ship} alt="ship" />
          <div className="way">
            <div className="progress-wrap">
              <div className="progress" style={{ width: progress.value }}>
                &nbsp;
              </div>
              <div className="city-wrap">
                <div className="city">
                  Origin: <span>{orderData.cityName}</span>
                </div>
                <div className="city">
                  Destination: <span>{orderData.cartageName}</span>
                </div>
                <div className="pin-box" style={{ left: progress.value }}>
                  <div className="pin">&nbsp;</div>
                  {20} days left
                </div>
              </div>
            </div>
          </div>
          <img src={helpFunctions.isDarkTheme() ? TrackDark : Track} alt="track" />
        </div>
        <div className="content-wrap">
          <div className="general-tab-wrap">
            <Tabs value={tabValue} onChange={changeTabValue}>
              <Tab label="Event status" />
              <Tab label="Shipment information" />
              <Tab label="Documents Exchange" disabled={tabUsabilityHandler("documents")} />
            </Tabs>
          </div>
          <div className="content">
            {tabValue === 0 && <EventStatus data={eventsData} />}
            {tabValue === 1 && <ShipmentInfo />}
            {tabValue === 2 && <DocExchange documentsExchange={documentsExchange} />}
          </div>
        </div>
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={statusModalValue !== null}
          handleClose={() => setStatusModalValue(null)}
          handleSubmit={changeStatus}
          title="Change status"
          description="You can change the status of order"
        >
          &nbsp;
        </ModalWindow>
      </div>
    </MainTemplate>
  );
});

export default OrdersConcreteContainer;
