import React, { useState, useEffect, FC, SyntheticEvent } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../../actions/auth";
import { FieldType } from "../../../validator/types";
import { defaultValidationProperties, validateAllFields, validateField } from "../../../validator/Fields";

// Components
import Logo from "../../../assets/images/logo.svg";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { toastr } from "react-redux-toastr";

// Styles
import "./style.scss";

type loginType = {
  children?: React.ReactNode;
  isValidToken: boolean;
};

const initialLogin: FieldType = {
  ...defaultValidationProperties,
  maxLength: 100,
  nameForError: "Email",
  dependencies: [{ depCase: "required" }, { depCase: "email" }, { depCase: "maxLength", attr: { maxLength: 100 } }],
};

const initialPassword: FieldType = {
  ...defaultValidationProperties,
  maxLength: 50,
  nameForError: "Password",
  dependencies: [
    { depCase: "required" },
    { depCase: "minLength", attr: { minLength: 8 } },
    { depCase: "maxLength", attr: { maxLength: 50 } },
  ],
};

const LoginContainer: FC<any> = (props: loginType) => {
  const history = useHistory();
  const token: string | null = localStorage.getItem("token");
  const userId: string | null = localStorage.getItem("userId");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [validationLogin, setValidationLogin] = useState<FieldType>(initialLogin);
  const [validationPass, setValidationPass] = useState<FieldType>(initialPassword);

  useEffect(() => {
    if (token && userId && token !== "undefined" && userId !== "undefined" && props.isValidToken) {
      history.push("/");
    }
  }, []);

  const textHandler = (event: SyntheticEvent, setter: (n: string) => any) => {
    setter((event.target as HTMLInputElement).value);
  };
  const checkAdminRole = (data) => {
    data.map((item) => {
      if (item === "ROLE_MANAGER" || item === "ROLE_ADMIN") {
        history.push("/");
      } else {
        toastr.error("Error", "Access denied");
      }
    });
  };

  const submitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    const fields = [
      { validate: validationLogin, setValidate: setValidationLogin },
      { validate: validationPass, setValidate: setValidationPass },
    ];

    if (validateAllFields(fields)) {
      const response = await auth.login({ login, password, rememberMe });
      if (response.code === 200) {
        checkAdminRole(response.data.role);
      } else if (response.code === 400) {
        toastr.error("Error", response.message);
      }
    }
  };
  return (
    <div id="login" className="wrapper">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <h2 className="title">Admin</h2>
        <form onSubmit={submitHandler}>
          <div className="row fields">
            <div className="label">
              <label htmlFor="login-input">Email</label>
            </div>
            <TextField
              helperText={validationLogin.error}
              error={validationLogin.valid === false}
              inputProps={{ maxLength: validationLogin.maxLength }}
              id="login-input"
              value={login}
              onChange={(event) => textHandler(event, setLogin)}
              onBlur={(e) => validateField(e.target.value, validationLogin, setValidationLogin)}
            />
          </div>
          <div className="row fields">
            <div className="label">
              <label htmlFor="pass-input">Password</label>
            </div>
            <TextField
              helperText={validationPass.error}
              error={validationPass.valid === false}
              id="pass-input"
              value={password}
              type="password"
              inputProps={{ maxLength: validationPass.maxLength }}
              onChange={(event) => textHandler(event, setPassword)}
              onBlur={(e) => validateField(e.target.value, validationPass, setValidationPass)}
            />
          </div>
          <div className="row">
            <div className="label pre-remember">&nbsp;</div>
            <Checkbox id="remember" checked={rememberMe} onChange={(event) => setRememberMe(event.target.checked)} />
            <div className="label remember">
              <label htmlFor="remember">Remember me</label>
            </div>
          </div>
          <div className="row">
            <div className="label">&nbsp;</div>
            <Button
              type="submit"
              variant="contained"
              value="Login"
              classes={{ root: "login-btn" }}
              onClick={(event) => submitHandler(event)}
            >
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    isValidToken: state.app.isValidToken,
  };
};

export default connect(mapStateToProps)(withRouter(LoginContainer));
