import React from "react";
import helpFunctions from "../../../tools/helpFunctions";

import "./style.scss";

type propsType = {
  fileName: string;
  colour?: number; // 1 - blue, 2 - yellow
};

const ExtensionBox = (props: propsType) => {
  const colour = props.colour ? props.colour : 1;
  return (
    <span id="extension" className={colour === 1 ? "extension" : "extension alt"}>
      {helpFunctions.getFileExtension(props.fileName)}
    </span>
  );
};

export default ExtensionBox;
