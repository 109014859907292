import store from "../redux/store";
import { optionsActionType } from "../types/actions/options";

export const options: optionsActionType = {
  setSortedColumn(data) {
    store.dispatch({
      type: "SORTED_CLIENTS_COLUMN",
      payload: {
        sortedColumn: data,
      },
    });
  },
  setFilterParams(data) {
    store.dispatch({
      type: "FILTER_CLIENTS_COLUMN",
      payload: {
        filterParams: data,
      },
    });
  },
};
