import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomPagination from "../../organisms/CustomPagination";

// Styles
import "./style.scss";

import { rootReducerType } from "../../../redux/reducers";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { requireInstance } from "../../../validator/Fields/instantLCLQuote";

type depotItemType = {
  createdAt: string;
  id: number;
  name: string;
  code: string;
  tier: number;
  status: boolean;
  visibility: boolean;
  updatedAt: string;
  origins: Array<{ id: number; name: string }>;
  originIds: Array<number>;
  [key: number]: number;
  depotFixed: any;
  depotVar: any;
  depotVarMin: any;
};

const depotItemInitial = {
  createdAt: "",
  id: 0,
  name: "",
  code: "",
  tier: 0,
  status: true,
  visibility: true,
  updatedAt: "",
  origins: [],
  originIds: [],
  depotFixed: "",
  depotVar: "",
  depotVarMin: "",
};

type portCityItemType = {
  code: string;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
  groupName: string;
};

const portCityItemInitial = {
  code: "",
  createdAt: "",
  id: 0,
  name: "",
  updatedAt: "",
  groupName: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const DepotsContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [depotList, setDepotList] = useState<Array<depotItemType>>([]);
  const [depotItem, setDepotItem] = useState<depotItemType>(depotItemInitial);

  const [portList, setPortList] = useState<[portCityItemType]>([portCityItemInitial]);
  const [cityList, setCityList] = useState<[portCityItemType]>([portCityItemInitial]);
  const [originList, setOriginList] = useState<Array<any>>([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  /*Validation*/
  const [originIdsValid, setOriginIdsValid] = useState<FieldType>({ ...requireInstance });
  const [depotItemValid, setDepotItemValid] = useState<FieldType>({ ...requireInstance });
  //const [depotNameValid, setDepotNameValid] = useState<FieldType>({ ...requireInstance });
  //const [depotCodeValid, setDepotCodeValid] = useState<FieldType>({ ...requireInstance });
  const [depotTierValid, setDepotTierValid] = useState<FieldType>({ ...requireInstance });

  const [precarFixedValid, setPrecarFixedValid] = useState<FieldType>({ ...requireInstance });
  const [precarVarValid, setPrecarVarValid] = useState<FieldType>({ ...requireInstance });
  const [precarVarMinValid, setPrecarVarMinValid] = useState<FieldType>({ ...requireInstance });

  const portCityList = portList.concat(cityList);

  useEffect(() => {
    getAllDepots(paginationSettings.page);
    getAllPorts(1, 1000);
    getAllCites(1, 1000);
    getAllOrigins();
  }, []);

  const openEditModal = (data: depotItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setDepotItem({ ...data, originIds: multiSelectValue(data.origins) });
  };

  const closeModal = (): void => {
    setDepotItem(depotItemInitial);
    setIsFilterModalOpen(false);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    setDepotItemValid((prevState) => ({ ...prevState, valid: true, error: "", value: "" }));
    setDepotTierValid((prevState) => ({ ...prevState, valid: true, error: "", value: "" }));
  };

  const getAllDepots = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("value/depot", page, undefined, quantity);
    if (response.code === 200) {
      setDepotList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllDepots(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const getAllPorts = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("port", page, null, quantity);
    if (response.code === 200) {
      setPortList(response.data.items);
    }
  };

  const getAllOrigins = async () => {
    const response = await generalCRUD.getAllData("admin/value/origin", undefined, undefined, undefined);
    if (response.code === 200) {
      setOriginList(response.data.items);
    }
  };

  const getAllCites = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("city", page, null, quantity);
    if (response.code === 200) {
      setCityList(response.data.items);
    }
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    const valid = true;
    /*for (const key in depotItem) {
      if (key === "id" || key === "status" || key === "updatedAt" || key === "createdAt") continue;
      if (!depotItem[key]) {
        valid = false;
        break;
      }
    }*/

    if (valid) {
      setIsLoading(true);
      let response;
      const request = {
        originIds: depotItem.originIds,
        code: depotItem.code,
        name: depotItem.name,
        tier: Number(depotItem.tier),
        status: true,
        visibility: depotItem.visibility,
        depotFixed: Math.round(parseFloat(depotItem.depotFixed)),
        depotVar: Math.round(parseFloat(depotItem.depotVar)),
        depotVarMin: Math.round(parseFloat(depotItem.depotVarMin)),
      };
      if (forEdit) {
        response = await generalCRUD.editConcreteData("value/depot", depotItem.id, request);
      } else {
        response = await generalCRUD.addNewData("value/depot", request);
      }
      if (response.code === 200) {
        handleSuccess();
        closeModal();
        return setIsLoading(false);
      }

      if (response.status === "error") {
        toastr.error("Error", helpFunctions.buildErrorString(response));

        return setIsLoading(false);
      }
      /*
      const errorObj = response;
      let setter = setDepotItemValid;
      if (errorObj.problem_field.includes("tier")) {
        setter = setDepotTierValid;
      }
      setter((prevState) => ({
        ...prevState,
        valid: false,
        error: errorObj.message ? errorObj.message : response.message,
      }));
      */
      return setIsLoading(false);
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllDepots(paginationSettings.page);
      toastr.success("New Depot was added successfully", "");
    };
    if (
      validateAllFields([
        { validate: originIdsValid, setValidate: setOriginIdsValid },
        { validate: depotTierValid, setValidate: setDepotTierValid },
        { validate: depotItemValid, setValidate: setDepotItemValid },
      ])
    ) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      /*
      const newData: Array<depotItemType> = [...depotList];
      newData[index] = { ...depotItem };
      setDepotList(newData);
      */
      getAllDepots(paginationSettings.page);
      toastr.success("Your Depot was edited successfully", "");
    };
    sendRequest(handleSuccess, true);
  };

  const textChangeHandler = (event: any, field: string, num?: boolean | string) => {
    const value: any = (event.target as HTMLInputElement).value;

    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...depotItem,
      [field]: value,
    };

    if (field === "originIds") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, originIdsValid, setOriginIdsValid);
    }

    setDepotItem(newData);
  };

  const switchStatus = (value: boolean): void => {
    setDepotItem({ ...depotItem, visibility: value });
  };

  const handleToggle = (status: boolean): void => {
    setDepotItem({ ...depotItem, visibility: !status });
  };
  const handleAutocomplete = (event, newValue) => {
    if (newValue != null) {
      const newData = {
        ...depotItem,
        code: newValue.code,
        name: newValue.name,
      };
      setDepotItem(newData);
      setDepotItemValid((prevState) => ({ ...prevState, valid: true, error: "", value: newValue.code }));
    } else {
      const newData = {
        ...depotItem,
        code: "",
        name: "",
      };
      setDepotItem(newData);
      setDepotItemValid((prevState) => ({
        ...prevState,
        valid: false,
        error: "This field cannot be empty",
        value: "",
      }));
    }
  };

  const selectCityPort = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
  };

  const multiSelectValue = (arr: any) => {
    if (arr) {
      const newArr = arr.map((item) => {
        return item.id;
      });
      return newArr;
    }
    return [];
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Port/City</p>
          <div>
            <Autocomplete
              id={"autocomplete=box"}
              options={portCityList}
              groupBy={(option) => option.groupName}
              // @ts-ignore
              value={depotItem || ""}
              onChange={handleAutocomplete}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText={depotItemValid.error}
                  error={depotItemValid.valid === false}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Category</p>
          <div>
            <FormControl className="general-select">
              <Select
                multiple
                value={depotItem.originIds}
                error={originIdsValid.valid === false}
                onChange={(event) => textChangeHandler(event, "originIds")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem className="no-value" value={0} disabled>
                  Category
                </MenuItem>
                {originList &&
                  originList.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {originIdsValid.valid === false && <div className="freight-err-text">This field cannot be empty</div>}
          </div>
        </div>
      </div>

      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Depot Tier (Integer only)</p>
          <div>
            <TextField
              helperText={depotTierValid.error}
              error={depotTierValid.valid === false}
              value={depotItem.tier || ""}
              InputProps={{ disableUnderline: true }}
              placeholder="Depot Tier (Integer only)"
              type="text"
              onChange={(event) => textChangeHandler(event, "tier", true)}
              onBlur={(e) => validateField(e.target.value, depotTierValid, setDepotTierValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">DEPOT_FIXED</p>
          <div>
            <TextField
              helperText={precarFixedValid.error}
              error={precarFixedValid.valid === false}
              value={depotItem.depotFixed}
              type="number"
              placeholder="DEPOT_FIXED"
              onChange={(event) => textChangeHandler(event, "depotFixed", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">DEPOT_VAR</p>
          <div>
            <TextField
              helperText={precarVarValid.error}
              error={precarVarValid.valid === false}
              value={depotItem.depotVar}
              type="number"
              placeholder="DEPOT_VAR"
              onChange={(event) => textChangeHandler(event, "depotVar", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">DEPOT_VAR_MIN</p>
          <div>
            <TextField
              helperText={precarVarMinValid.error}
              error={precarVarMinValid.valid === false}
              value={depotItem.depotVarMin}
              type="number"
              placeholder="DEPOT_VAR_MIN"
              onChange={(event) => textChangeHandler(event, "depotVarMin", true)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Visibility</p>
          <p className="switcher-visibility">
            <span className="hover-cursor" onClick={() => switchStatus(false)}>
              Off
            </span>
            <Switch
              checked={depotItem.visibility}
              name={"switcher-visibility-" + depotItem.id}
              style={{ color: "white" }}
              onClick={() => handleToggle(depotItem.visibility)}
            />
            <span className="hover-cursor" onClick={() => switchStatus(true)}>
              On
            </span>
          </p>
        </div>
      </div>
    </>
  );

  const modalCityPort = (
    <>
      <div className="select-wraper-city-port">
        <div>
          <span>Port</span>
          <FormControl>
            <Select onChange={(event) => selectCityPort(event)} displayEmpty>
              <MenuItem disabled value="">
                Choose your port
              </MenuItem>
              {portList.map((item: portCityItemType, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <span>City</span>
          <FormControl>
            <Select onChange={(event) => selectCityPort(event)} displayEmpty>
              <MenuItem disabled value="">
                Choose your city
              </MenuItem>
              {cityList.map((item: portCityItemType, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="depots" className="page-container">
        <TopPartPage title="Depots" buttonTitle="add new Depot" handleClick={() => setIsAddModalOpen(true)} />
        <table className="depot-table">
          <thead className="table-header">
            <tr>
              <th className="depot-id">ID</th>
              <th className="depot-name">
                Name
                {/*<Button*/}
                {/*  onClick={() => {*/}
                {/*    setIsFilterModalOpen(true);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  City/Port*/}
                {/*</Button>*/}
              </th>
              <th className="code-name-value">Codename</th>
              <th className="depot-value">Tier</th>
              <th className="th">DEPOT_FIXED</th>
              <th className="th">DEPOT_VAR</th>
              <th className="th">DEPOT_VAR_MIN</th>
              <th className="depot-visibility">Visibility</th>
              <th className="th">Category</th>
              <th className="depot-action">Action</th>
            </tr>
          </thead>
          <tbody className="table-body-wrapper">
            {depotList &&
              depotList.map((item: depotItemType, index) => {
                return (
                  <tr key={index}>
                    <td className="depot-id">
                      <span>{item.id}</span>
                    </td>
                    <td className="depot-name">
                      <span>{item.name}</span>
                    </td>
                    <td className="code-name-value">
                      <span>{item.code}</span>
                    </td>
                    <td className="depot-value">
                      <span>{item.tier}</span>
                    </td>
                    <td className="th">{item.depotFixed}</td>
                    <td className="th">{item.depotVar}</td>
                    <td className="th">{item.depotVarMin}</td>
                    <td className="depot-visibility">
                      <span>{item.visibility ? "On" : "Off"}</span>
                    </td>
                    <td className="th">
                      {item.origins &&
                        item.origins.map((el) => {
                          return `${el.name}; `;
                        })}
                    </td>
                    <td className="depot-action" onClick={() => openEditModal(item, index)}>
                      <span>Edit</span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {isLoading && <LoaderLocal />}
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={depotList.length}
          handleClickPage={(value) => getAllDepots(value)}
          handleClickQuantity={(quantity) => getAllDepots(paginationSettings.page, quantity)}
        />
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add depot"
          classes="depot-modal"
          description="You can add new Depot"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          classes="depot-modal"
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit depot"
          description="You can edit your Depot"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={isFilterModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          classes={"modal-city-port"}
          title="Choose field that you need"
        >
          {modalCityPort}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(withRouter(DepotsContainer));
