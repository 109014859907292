import React, { useEffect, FC, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";
import { app } from "../../../actions/app";
import { CONSTS } from "../../../config/objectConst";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import MainTemplate from "../templates/MainTemplate";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import ModalWindow from "../../molecules/ModalWindow";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import GeneralButton from "../../atoms/GeneralButton";
import LoaderLocal from "../../atoms/LoaderLocal";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch/Switch";

import { IArticleData } from "../BlogArticle";
import { rootReducerType } from "../../../redux/reducers";

// Styles
import "./style.scss";

interface IBlogArticleData extends IArticleData {
  slug: string;
  user: {
    username: string;
  };
  visibility: boolean;
  previewImageId: number | null;
}

export type articleOrderType = {
  news: Array<number | null>;
  resources: Array<number | null>;
};

type modalVisDataType = {
  value: boolean;
  id: number | null;
  status: boolean;
};

const BlogContainer: FC = () => {
  const storeActiveTab = useSelector((state: rootReducerType) => state.app.activeTabBlog);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(storeActiveTab);
  const [articleList, setArticleList] = useState<Array<IBlogArticleData>>([]);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [articleOrder, setArticleOrder] = useState<articleOrderType>({
    news: [null],
    resources: [null],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [modalVisData, setModalVisData] = useState<modalVisDataType>({
    value: false,
    id: null,
    status: false,
  });

  useEffect(() => {
    getAllArticles();
    getArticlesPosition();

    return () => {
      dispatch({
        type: "ACTIVE_TAB_BLOG",
        payload: {
          activeTab: 0,
        },
      });
    };
  }, []);

  const changeTabValue = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabValue(newValue);
  };

  const newsList: Array<IBlogArticleData> = articleList.filter((item: IBlogArticleData) => {
    return item.type === "news";
  });

  const resourcesList: Array<IBlogArticleData> = articleList.filter((item: IBlogArticleData) => {
    return item.type === "resources";
  });

  const getAllArticles = async () => {
    const response = await generalCRUD.getAllData("article");
    if (response.code === 200) {
      setArticleList(response.data);
    } else {
      toastr.error("Error", response.message);
    }
  };

  const getArticlesPosition = async () => {
    const response = await generalCRUD.getAllData("article/position");
    if (response.code === 200) {
      setArticleOrder(response.data);
    } else {
      toastr.error("Error", response.message);
    }
  };

  const deleteArticle = async () => {
    const id = deleteId;
    setDeleteId(0);
    const response = await generalCRUD.deleteData("article", id);
    if (response.code === 200) {
      toastr.success("Article was deleted successfully", "");
      const newArticleList: Array<IBlogArticleData> = articleList.filter((item: IBlogArticleData) => {
        return item.id !== id;
      });
      setArticleList(newArticleList);
    } else {
      toastr.error("Error", response.message);
    }
  };

  const prepareToChangeVisibility = (value: boolean, id: number) => {
    let isArtcileSelected = false;
    for (const key in articleOrder) {
      if (articleOrder[key].includes(id)) {
        isArtcileSelected = true;
        break;
      }
    }
    if (isArtcileSelected) {
      return setModalVisData({ value: value, id: id, status: true });
    }
    changeVisibility(value, id, false);
  };

  const changeVisibility = async (value: boolean, id: number, isArticleSelected) => {
    setIsLoading(true);
    setModalVisData({
      value: false,
      id: null,
      status: false,
    });
    const articleRealIndex = articleList.findIndex((elem) => {
      return elem.id === id;
    });
    const articleData = { ...articleList[articleRealIndex] };
    articleData.visibility = value;
    articleData.previewImageId = articleData.previewImage.id;
    const response = await generalCRUD.editConcreteData("article", id, articleData);
    if (response.code === 200) {
      toastr.success("Visibility of article was changed successfully", "");
      const newArticleList: Array<IBlogArticleData> = [...articleList];
      newArticleList[articleRealIndex].visibility = value;
      setArticleList(newArticleList);
      if (isArticleSelected) {
        const newArticleOrder = { ...articleOrder };
        for (const key in newArticleOrder) {
          newArticleOrder[key].forEach((item, index) => {
            if (item === id) return (newArticleOrder[key][index] = null);
          });
        }
        const newObj = getOrderObjectForRequest(newArticleOrder);
        const result = await app.changeArticlesOrder(newObj);
        if (result.code === 200) {
          setArticleOrder(newArticleOrder);
        } else {
          toastr.error("Error", result.message);
        }
      }
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const buildArticlesBox = (list: Array<IBlogArticleData>) => {
    return (
      <div className="content">
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Date</div>
            <div className="th">Creator</div>
            <div className="th article-title">Title</div>
          </div>
          <div className="th visibility">Visibility</div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {list.map((item: IBlogArticleData, index: number) => {
            return (
              <li className="table-content-item" key={index}>
                <a
                  href={`${CONSTS.clientUrl}article.html?article=${item.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="th first">{item.id}</div>
                  <div className="middle-part">
                    <div className="th">{helpFunctions.getDateFromUnix(item.customCreatedAt)}</div>
                    <div className="th">{item.user.username}</div>
                    <div className="th article-title">{item.title}</div>
                  </div>
                </a>
                <div className=" th visibility">
                  <Grid component="label" container alignItems="center">
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        checked={item.visibility}
                        onChange={(event) => prepareToChangeVisibility(event.target.checked, item.id)}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </div>
                <div className="th last">
                  <div className="action-buttons">
                    <Button onClick={() => setDeleteId(item.id)}>Delete</Button>
                    <Link
                      to={{
                        pathname: `/edit-article/${item.id}`,
                        state: { type: item.type },
                      }}
                    >
                      <Button className="edit">Edit</Button>
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const articleOrderHandler = (event: React.ChangeEvent<{ value: unknown }>, type: string, order: number) => {
    const newOrderObject = { ...articleOrder };
    newOrderObject[type][order] = event.target.value;
    setArticleOrder(newOrderObject);
    if (isHidden) setIsHidden(false);
  };

  const submitArticlesOrder = async () => {
    setIsLoading(true);
    const newObj = getOrderObjectForRequest(articleOrder);
    const response = await app.changeArticlesOrder(newObj);
    if (response.code === 200) {
      setIsHidden(true);
      toastr.success("Articles order was saved successfully", "");
    } else {
      setIsHidden(false);
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const getOrderObjectForRequest = (data) => {
    const newObj = {
      news: [],
      resources: [],
    };
    selectArray.forEach((item, index) => {
      newObj.news[index] = data.news[index] ? data.news[index] : null;
      newObj.resources[index] = data.resources[index] ? data.resources[index] : null;
    });
    return newObj;
  };

  const selectArray = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth"];

  const newsBox = (
    <>
      <div className="middle">
        <p className="name">You can create, edit and delete news</p>
        <Link
          to={{
            pathname: "/create-article",
            state: { type: "news" },
          }}
        >
          Add news
        </Link>
      </div>
      {buildArticlesBox(newsList)}
    </>
  );

  const resourcesBox = (
    <>
      <div className="middle">
        <p className="name">You can create, edit and delete resources</p>
        <Link
          to={{
            pathname: "/create-article",
            state: { type: "resources" },
          }}
        >
          Add resource
        </Link>
      </div>
      {buildArticlesBox(resourcesList)}
    </>
  );

  const mainViewBox = (
    <>
      <div className="main-tab-wrap">
        <div className="main-tab-item">
          <p className="title">News</p>
          <div className="middle">
            <p className="name">
              Here you can choose the news for publishing on the main page. They will appear in a certain order, that
              you choose below.
            </p>
          </div>
          <div className="content">
            {selectArray.map((item, index) => {
              const newOptionArray = newsList.filter((item: IBlogArticleData) => {
                return (
                  item.visibility && (!articleOrder.news.includes(item.id) || item.id === articleOrder.news[index])
                );
              });
              return (
                <div className="select-wrap" key={item}>
                  <p className="select-title">{item}</p>
                  <FormControl className="general-select" key={item}>
                    <Select
                      value={articleOrder.news[index] ? articleOrder.news[index] : ""}
                      onChange={(event) => articleOrderHandler(event, "news", index)}
                    >
                      <MenuItem className="no-value" value={null}>
                        No article
                      </MenuItem>
                      {newOptionArray.map((elem, i) => {
                        return (
                          <MenuItem value={elem.id} key={i}>
                            {elem.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              );
            })}
          </div>
        </div>
        <div className="main-tab-item">
          <p className="title">Resources</p>
          <div className="middle">
            <p className="name">
              Here you can choose the resources for publishing on the main page. They will appear in a certain order,
              that you choose below
            </p>
          </div>
          <div className="content">
            {selectArray.map((item, index) => {
              const newOptionArray = resourcesList.filter((item: IBlogArticleData) => {
                return (
                  item.visibility &&
                  (!articleOrder.resources.includes(item.id) || item.id === articleOrder.resources[index])
                );
              });
              return (
                <div className="select-wrap" key={item}>
                  <p className="select-title">{item}</p>
                  <FormControl className="general-select">
                    <Select
                      value={articleOrder.resources[index] ? articleOrder.resources[index] : ""}
                      onChange={(event) => articleOrderHandler(event, "resources", index)}
                    >
                      <MenuItem className="no-value" value={null}>
                        No article
                      </MenuItem>
                      {newOptionArray.map((elem, i) => {
                        return (
                          <MenuItem value={elem.id} key={i}>
                            {elem.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="main-tab-actions">
        {!isHidden && (
          <GeneralButton classes="submit" handleClick={submitArticlesOrder}>
            Submit
          </GeneralButton>
        )}
      </div>
    </>
  );

  return (
    <MainTemplate>
      <div id="blog">
        <div className="top-part">
          <span className="title">Blog</span>
        </div>
        <div className="general-tab-wrap">
          <Tabs value={tabValue} onChange={changeTabValue}>
            <Tab label="News" />
            <Tab label="Resources" />
            <Tab label="Main view" />
          </Tabs>
        </div>
        <div className="page-container">
          {tabValue === 0 && newsBox}
          {tabValue === 1 && resourcesBox}
          {tabValue === 2 && mainViewBox}
        </div>
        <ModalWindow
          open={!!deleteId}
          isDeleting={true}
          handleClose={() => setDeleteId(0)}
          handleSubmit={deleteArticle}
          title="Delete article"
          description="You can delete article"
        >
          &nbsp;
        </ModalWindow>
        <ModalWindow
          open={modalVisData.status}
          isDeleting={true}
          handleClose={() =>
            setModalVisData({
              value: false,
              id: null,
              status: false,
            })
          }
          handleSubmit={() => changeVisibility(modalVisData.value, modalVisData.id, true)}
          title="Change article visibility"
          description="This article is chosen for presentation to customers. Changing the visibility status means, that the article will be hidden to customers. Do you agree?"
        >
          &nbsp;
        </ModalWindow>
        {isLoading && <LoaderLocal />}
      </div>
    </MainTemplate>
  );
};

export default BlogContainer;
