import React, { useState, useEffect, FC, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { orderDataType } from "../../../../types/actions/orders";
import { useHistory } from "react-router-dom";
// Components
import { generalCRUD } from "../../../../actions/generalCRUD";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { toastr } from "react-redux-toastr";
import helpFunctions from "../../../../tools/helpFunctions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Styles
import "./style.scss";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField, validateFloatNumber } from "../../../../validator/Fields";
import { FieldType } from "../../../../validator/types";
import { maxLengthInstance, regularInstance, requireInstance } from "../../../../validator/Fields/instantLCLQuote";
import NumberFormat from "react-number-format";
import { emailField } from "../../../../validator/Fields/quoteRequest";
import { requiredField } from "../../shipmentInfoTab/QuoteSummary";
import { user } from "../../../../api/user";
import { ListSubheader } from "@material-ui/core";

//WARNING: The code that follows may make you cry, A Safety Pig has been provided below for your benefit
const SafetyPig = `
                         _
 _._ _..._ .-',     _.._(\`))
'-. \`     '  /-._.-'    ',/
   )         \\            '.
  / _    _    |             \\
 |  a    a    /              |
 \\   .-.                     ;  
  '-('' ).-'       ,'       ;
     '-;           |      .'
        \\           \\    /
        | 7  .__  _.-\\   \\
        | |  |  \`\`/  /\`  /
       /,_|  |   /,_/   /
          /,_/      '\`-'
`;

type InstantLCLQuotePropsType = {
  orderData: orderDataType;
  shipmentTrade: number;
  getRadioLoader?: any;
};

type calculatorDataType = {
  depotId: string;
  cartageId: string;
  weight: string;
  cbm: string;
  insuranceValue: string;
  cargoInsurance: boolean;
  customsBrokerage: boolean;
};

const calculatorDataInitial = {
  depotId: "",
  cartageId: "",
  weight: "",
  cbm: "",
  insuranceValue: "",
  cargoInsurance: false,
  customsBrokerage: false,
};

type depotItemType = {
  createdAt: string;
  id: number;
  name: string;
  tier: number;
  status: boolean;
  typeName: string;
  updatedAt: string;
  [key: number]: number;
};

const depotItemInitial = {
  createdAt: "",
  id: 1,
  name: "",
  tier: 0,
  status: true,
  typeName: "",
  updatedAt: "",
};

const companiesListInitial = {
  additionalInformation: "",
  companyAddress: "",
  companyName: "",
  email: "",
  id: null,
  nameOfThePersonInCharge: "",
  phone: "",
};

type companiesListType = {
  additionalInformation: string;
  companyAddress: string;
  companyName: string;
  email: string;
  id: number | null;
  nameOfThePersonInCharge: string;
  phone: string;
};

type cartageItemType = {
  amazon: boolean;
  code: string;
  createdAt: string;
  id: number;
  name: string;
  status: boolean;
  updatedAt: string;
  cartages: any;
};

const cartageCityItemInitial = {
  amazon: false,
  code: "",
  createdAt: "",
  id: 1,
  name: "",
  status: false,
  updatedAt: "",
  cartages: [],
};

type formulaResponsType = {
  amzlclCost: number;
  delivery: string;
  freight: number;
  insurance: number;
  oncarriage: number;
};

const formulaResponsInitial = {
  amzlclCost: 0,
  delivery: "",
  freight: 0,
  insurance: 0,
  oncarriage: 0,
};

type instantLCLQuoteType = {
  shipmentTradeTerms: number;
  email: string;
  phone: string;
  userId: string;
  additionalInfo: string;
  additionalInformation: string;
  shipmentDescription: string;
  additionalInformationShipper: string;
  amazonRefId: string;
  shipmentPurchase: string;
  companyAddress: string;
  companyAddressShipper: string;
  companyName: string;
  companyNameShipper: string;
  emailShipper: string;
  estimatedTransportation: string;
  namePersonCharge: string;
  phoneShipper: string;
  shipmentDestination: string;
  shipmentId: string;
  shipmentOriginPort: string;
  shipmentValue: string;
  shipmentVolume: string;
  shipmentWeight: string;
  yourName: string;
  cargoInsurance: boolean;
  customBrokerage: boolean;
  transportationMode: string;
  sendMail: boolean;
};

export type shipperInfo = {
  companyName?: string;
  companyAddress: string;
  nameOfThePersonInCharge: string;
  email: string;
  phone: string;
  additionalInformation: string;
};

const instantLCLQuoteInitial = {
  shipmentTradeTerms: 0,
  email: "",
  phone: "",
  userId: "",
  additionalInfo: "",
  additionalInformation: "",
  additionalInformationShipper: "",
  shipmentDescription: "",
  amazonRefId: "",
  shipmentPurchase: "",
  companyAddress: "",
  companyAddressShipper: "",
  companyName: "",
  companyNameShipper: "",
  emailShipper: "",
  estimatedTransportation: "",
  namePersonCharge: "",
  phoneShipper: "",
  shipmentDestination: "",
  shipmentId: "",
  shipmentOriginPort: "",
  shipmentValue: "",
  shipmentVolume: "",
  shipmentWeight: "",
  yourName: "",
  cargoInsurance: false,
  customBrokerage: false,
  transportationMode: "0",
  sendMail: false,
};

const InitialPanelStatus = {
  panel1: true,
  panel2: true,
  panel3: false,
  panel4: false,
  panel5: false,
  panel6: false,
  lastPanel: false,
};

const InstantLCLQuote: FC<any> = (props: InstantLCLQuotePropsType) => {
  const [insurance, setInsurance] = React.useState<boolean>(false);
  const [instantLCLQuote, setInstantLCLQuote] = useState<instantLCLQuoteType>(instantLCLQuoteInitial);

  const [cargoInsurance, setCargoInsurance] = React.useState<boolean>(false);
  const [customsBrokerage, setCustomsBrokerage] = React.useState<boolean>(false);

  const [formulaRespons, setFormulaRespons] = useState<formulaResponsType>(formulaResponsInitial);
  const [calculatorData, setCalculatorData] = useState<calculatorDataType>(calculatorDataInitial);

  const [depotList, setDepotList] = useState<[depotItemType]>([depotItemInitial]);
  const [cartageList, setCartageList] = useState<[cartageItemType]>([cartageCityItemInitial]);

  const [errorChekerCbm, setErrorChekerCbm] = React.useState<boolean>(false);
  const [errorChekerWeight, setErrorChekerWeight] = React.useState<boolean>(false);

  const [formulaResult, setFormulaResult] = React.useState<boolean>(false);
  const [hideCalculate, sethideCalculate] = React.useState<boolean>(true);
  const [showAccordion, setShowAccordion] = React.useState<boolean>(false);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const [panelStatus, setPanelStatus] = useState(InitialPanelStatus);
  const [volumeValidation, setVolumeValidation] = useState<FieldType>({ ...requireInstance });
  const [weightValidation, setWeightValidation] = useState<FieldType>({ ...requireInstance });
  /*Validation step for user information */
  const [nameValidation, setNameValidation] = useState<FieldType>({ ...maxLengthInstance(50) });
  const [companyNameValidation, setCompanyNameValidation] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [companyAddressValidation, setCompanyAddressValidation] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [emailValidation, setEmailValidation] = useState<FieldType>({ ...emailField });
  const [phoneValidation, setPhoneValidation] = useState<FieldType>({ ...maxLengthInstance(25) });
  const [additionalInfoValidation, setAdditionalInfoValidation] = useState<FieldType>({
    ...maxLengthInstance(100, false),
  });
  /*Validation step for shipper information */
  const [shipCompanyNameVal, setShipCompanyNameVal] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [shipCompanyAddressVal, setShipCompanyAddressVal] = useState<FieldType>({ ...maxLengthInstance(100) });
  const [shipChargeNameVal, setShipChargeNameVal] = useState<FieldType>({ ...maxLengthInstance(50) });
  const [shipEmailVal, setShipEmailVal] = useState<FieldType>({ ...emailField });
  const [shipPhoneVal, setShipPhoneVal] = useState<FieldType>({ ...maxLengthInstance(25) });
  const [shipAdditionalInfoVal, setShipAdditionalInfoVal] = useState<FieldType>({ ...maxLengthInstance(100, false) });
  /*Validation step for shipment information*/
  const [shipmentNumberVal, setShipmentNumberVal] = useState<FieldType>({
    ...regularInstance("^([a-zA-Z1-9-][0-9]*)*$", 90, "Invalid field. You can only use letter, number and dashes"),
  });
  const [supplierNumberVal, setSupplierNumberVal] = useState<FieldType>({
    ...regularInstance("^([a-zA-Z1-9-][0-9]*)*$", 50, "Invalid field. You can only use letter, number and dashes"),
  });
  const [shipDescriptionVal, setShipDescriptionVal] = useState<FieldType>({ ...maxLengthInstance(280, true) });
  const [referenceVal, setReferenceVal] = useState<FieldType>({ ...maxLengthInstance(50, false) });
  const [shipmentDateVal, setShipmentDateVal] = useState(true);
  /*Validation step for Amazon FBA Booking Information*/
  const [amazonIdVal, setAmazonIdVal] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 25, "Invalid field. You can only use letter, number", true),
  });
  const [shipmentIDVal, setShipmentIDVal] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 25, "Invalid field. You can only use letter, number", true),
  });
  const [merchantVal, setMerchantVal] = useState<FieldType>({ ...maxLengthInstance(100) });
  /*Validation step for Additional information*/
  const [additionalInfoVal, setAdditionalInfoVal] = useState<FieldType>({ ...maxLengthInstance(255, false) });

  const [transportationCost, setTransportationCost] = useState<FieldType>({
    ...requiredField,
  });

  const [activeCustomCost, setActivateCustomCost] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [amazonStatus, setAmazonStatus] = useState<boolean>(false);

  const [companiesList, setCompaniesList] = useState<companiesListType[]>([companiesListInitial]);
  const [isNewCompany, newCompanySwitch] = useState<boolean>(false);
  const [isOpenCompanySelect, companySelectSwitch] = useState<boolean>(false);
  const [companyNameId, setCompanyNameId] = useState<number>(null);

  const [radioArray1, setRadioArray1] = useState<any>([]);
  const [radioArray2, setRadioArray2] = useState<any>([]);
  const [radioArray3, setRadioArray3] = useState<any>([]);
  const [radioArray4, setRadioArray4] = useState<any>([]);
  const [radioArrayLoad, setRadioArrayLoad] = useState<boolean>(true);

  const history = useHistory();

  useEffect(() => {
    const id = parseInt(helpFunctions.getParamIdFromUrl(), 10);

    getRadioArrays();
    //getAllDepots(1, 1000);

    getUserInfo();
    user.getMyCompanies(id).then((res) => {
      if (res.data.code === 200 && res.data.data.length) {
        setCompaniesList(res.data.data);
      }
    });
  }, []);

  const getRadioArrays = async () => {
    try {
      await Promise.all([
        getAllDepots(1, 1000),
        getAllPickUpLocation(),
        getAllOriginType1(),
        getAllOriginType2(),
        getAllCartages(1, 1000),
      ]);
      props.getRadioLoader(false);
      setRadioArrayLoad(false);
    } catch (e) {
      const error: any = e?.message;
      toastr.error("Error", error);
    }
  };

  useEffect(() => {
    //setCalculatorData({ ...calculatorData, depotId: null });
    switch (props.shipmentTrade) {
      case 3:
        setDepotList(radioArray1);
        break;
      case 2:
        setDepotList(radioArray2);
        break;
      case 1:
        setDepotList(radioArray3);
        break;
      case 0:
        setDepotList(radioArray4);
        break;

      default:
        break;
    }
  }, [props.shipmentTrade]);

  useEffect(() => {
    props.shipmentTrade && radioArray1 && setDepotList(radioArray1);
  }, [radioArray1]);

  /* ALL SELECT ACTIONS */
  const getAllPickUpLocation = async () => {
    const response = await generalCRUD.getAllData("pick-up-location", undefined, null, undefined);
    if (response.code === 200) {
      setRadioArray1(
        response.data.items.map((item) => {
          return { id: item.id, name: item.city.name, code: item.city.code };
        }),
      );
      //setCalculatorData({ ...calculatorData, depotId: String(response.data.items[0].id) });
    }
  };

  const getAllOriginType1 = async () => {
    const response = await generalCRUD.getAllData(
      "value/origin/get_all?shipment_trade_terms=2",
      undefined,
      null,
      undefined,
    );
    if (response.code === 200) {
      setRadioArray2(response.data.items[0].depot);
      //setCalculatorData({ ...calculatorData, depotId: String(response.data.items[0].id) });
    }
  };

  const getAllOriginType2 = async () => {
    const response = await generalCRUD.getAllData(
      "value/origin/get_all?shipment_trade_terms=1",
      undefined,
      null,
      undefined,
    );
    if (response.code === 200) {
      setRadioArray3(response.data.items[0].depot);
      //setCalculatorData({ ...calculatorData, depotId: String(response.data.items[0].id) });
    }
  };

  useEffect(() => {
    if (errorChekerCbm && errorChekerWeight && !hideCalculate) {
      checkCollectData();
    }
  }, [calculatorData]);

  useEffect(() => {
    const defaultCartageId =
      cartageList.length > 0 ? (cartageList[0].cartages[0]?.id ? cartageList[0].cartages[0]?.id : "") : "";
    const defaultDepotId = depotList[0]?.id ? depotList[0]?.id : "";

    if (depotList && !radioArrayLoad) {
      setCalculatorData((prevState: any) => ({ ...prevState, depotId: defaultDepotId, cartageId: defaultCartageId }));
    }
  }, [radioArrayLoad]);

  useEffect(() => {
    if (!radioArrayLoad && depotList) {
      const defaultDepotId = depotList[0]?.id ? depotList[0]?.id : "";
      setCalculatorData((prevState: any) => ({ ...prevState, depotId: defaultDepotId }));
    }
  }, [depotList, props.shipmentTrade]);

  useEffect(() => {
    //const shipmentDestination = cartageList.find((item) => item.id === Number(calculatorData.cartageId));
    //shipmentDestination && setAmazonStatus(shipmentDestination.amazon);
    cartageList &&
      cartageList.map((item: any) => {
        const isAmazon: any = item.isAmazon;
        const shipmentDestination = item.cartages.find((item) => item.id === Number(calculatorData.cartageId));
        if (shipmentDestination) {
          setAmazonStatus(isAmazon);
          changeActivePanel(isAmazon);
        }
      });
    //const id = getDefaultSelectId(cartageList);
    //console.log(depotList);
    //return setCalculatorData((prevState) => ({ ...prevState, cartageId: id }));
  }, [cartageList.length, calculatorData.cartageId]);

  const getUserInfo = async () => {
    const id = parseInt(helpFunctions.getParamIdFromUrl(), 10);
    const response = await generalCRUD.getConcreteData("user", id);
    if (response.code === 200) {
      setInstantLCLQuote({
        ...instantLCLQuote,
        yourName: response.data.username,
        email: response.data.email,
        phone: response.data.phone,
        userId: String(id),
        companyAddress: response.data.companyAddress,
        companyName: response.data.companyName,
      });
    }
  };

  const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getAllDepots = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("value/depot", page, null, quantity);
    if (response.code === 200) {
      //console.log("getAllDepots", response.data.items);
      //setDepotList(response.data.items);
      setRadioArray4(response.data.items);
      //setCalculatorData({ ...calculatorData, depotId: String(response.data.items[0].id) });
    }
  };

  const getAllCartages = async (page: number, quantity: number) => {
    const response = await generalCRUD.getAllData("value/cartage-cartegory", page, null, quantity);
    if (response.code === 200) {
      setCartageList(response.data.items);
    }
  };

  const getFormulaResult = async (path: string, data: any) => {
    const response = await generalCRUD.getFormulaResult(path, data);
    if (response.code === 200) {
      setFormulaRespons(response.data);
      setFormulaResult(true);
      setInstantLCLQuote({
        ...instantLCLQuote,
        cargoInsurance: cargoInsurance,
        shipmentDestination: String(calculatorData.cartageId),
        shipmentValue: calculatorData.insuranceValue,
        shipmentVolume: calculatorData.cbm,
        shipmentWeight: calculatorData.weight,
        shipmentTradeTerms: props.shipmentTrade,
        shipmentOriginPort: String(calculatorData.depotId),
        customBrokerage: customsBrokerage,
        estimatedTransportation: response.data.amzlclCost.toFixed(2),
        userId: String(parseInt(helpFunctions.getParamIdFromUrl(), 10)),
      });
      return;
    }
    return toastr.error("Error", response.message);
  };

  const checkCollectData = () => {
    //old request
    //getFormulaResult("value/formula/get", JSON.stringify(calculatorData));

    let request;
    if (calculatorData.depotId) {
      switch (props.shipmentTrade) {
        case 3:
          request = {
            pickUpLocationId: Number(calculatorData.depotId),
            cartageId: Number(calculatorData.cartageId),
            weight: Number(calculatorData.weight),
            cbm: Number(calculatorData.cbm),
            insurance: Number(calculatorData.insuranceValue),
            cargoInsurance: calculatorData.cargoInsurance,
            customsBrokerage: calculatorData.customsBrokerage,
          };
          getFormulaResult("calculator/pick-up_from_supplier", request);
          break;
        case 2:
          request = {
            depotId: Number(calculatorData.depotId),
            cartageId: Number(calculatorData.cartageId),
            weight: Number(calculatorData.weight),
            cbm: Number(calculatorData.cbm),
            insurance: Number(calculatorData.insuranceValue),
            cargoInsurance: calculatorData.cargoInsurance,
            customsBrokerage: calculatorData.customsBrokerage,
          };
          getFormulaResult("calculator/drop-off_by_supplier", request);
          break;
        case 0:
        case 1:
          request = {
            depotId: Number(calculatorData.depotId),
            cartageId: Number(calculatorData.cartageId),
            weight: Number(calculatorData.weight),
            cbm: Number(calculatorData.cbm),
            insurance: Number(calculatorData.insuranceValue),
            cargoInsurance: calculatorData.cargoInsurance,
            customsBrokerage: calculatorData.customsBrokerage,
          };
          getFormulaResult("calculator/supplier_is_responsible_for_all_origin_fees", request);
          break;

        default:
          break;
      }
    }
  };

  const textChangeHandler = (event: SyntheticEvent, field: string) => {
    let value: any = (event.target as HTMLInputElement).value;
    if (field === "insuranceValue") {
      value = validateFloatNumber(value, 2);
      if (errorChekerCbm && errorChekerWeight && !hideCalculate) {
        checkCollectData();
      }
    }
    const newData = {
      ...calculatorData,
      [field]: value,
    };
    setCalculatorData(newData);
  };

  const cbmChangeHandler = (event: SyntheticEvent) => {
    let valCbm: any = (event.target as HTMLInputElement).value;
    valCbm = valCbm.replace(/,/, ".");
    valCbm = valCbm.replace(/^[.]+$/, "0.");
    valCbm = valCbm.replace(/[^.\d]+/g, "").replace(/^([^\.]*\.)|\./g, "$1");
    valCbm = valCbm.replace(/([0-9])(?=([0-9]{3})+(?![0-9]))/g, "");
    if (valCbm > 16) {
      setErrorChekerCbm(false);
      setCalculatorData({ ...calculatorData, cbm: valCbm });
      toastr.error("For shipments over 16 cubic meters we recommend requesting a FCL quote", "");
    } else if (valCbm < 17 && valCbm > 0) {
      setCalculatorData({ ...calculatorData, cbm: valCbm });
      setErrorChekerCbm(true);
    } else {
      setErrorChekerCbm(false);
      setCalculatorData({ ...calculatorData, cbm: valCbm });
    }

    if (isNaN(valCbm)) {
      toastr.error("Please enter a number", "");
      setCalculatorData({ ...calculatorData, cbm: valCbm });
      setErrorChekerCbm(false);
    }
  };

  const weightChangeHandler = (event: SyntheticEvent) => {
    let weightCbm: any = (event.target as HTMLInputElement).value;
    const valCbm = Number(calculatorData.cbm);
    weightCbm = validateFloatNumber(weightCbm, 3);
    if (weightCbm / 1000 > valCbm && weightCbm > 5000) {
      toastr.error(
        "It appears you have High Density Cargo, please check and correct the entered volume and weight or request a custom quote",
        "",
      );
      setErrorChekerWeight(false);
    } else if (weightCbm / 1000 < valCbm && weightCbm > 8000) {
      setErrorChekerWeight(false);
      toastr.error(
        "It appears you have very Light weight Cargo, please check and correct  he entered volume and weight or request a custom quote",
        "",
      );
    } else if (weightCbm === 0) {
      setErrorChekerWeight(false);
      toastr.error("Please enter a value", "");
    } else {
      setCalculatorData({ ...calculatorData, weight: weightCbm });
      setErrorChekerWeight(true);
    }
    if (!weightCbm || parseInt(weightCbm) === 0) {
      toastr.error("Please enter a value", "");
      setErrorChekerWeight(false);
    } else if (valCbm == 0) {
      toastr.error("The cbn cannot be zero", "");
      setCalculatorData({ ...calculatorData, cbm: "" });
      setErrorChekerCbm(false);
    }

    if (isNaN(weightCbm)) {
      toastr.error("Please enter a number", "");
      setErrorChekerWeight(false);
    }
  };

  const handleInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setInsurance(value);
    setCargoInsurance(value);

    if (value) {
      setCalculatorData({ ...calculatorData, cargoInsurance: true });
    } else if (!value) {
      setCustomsBrokerage(false);
      setCalculatorData({ ...calculatorData, insuranceValue: "", cargoInsurance: false, customsBrokerage: false });
    }
  };

  const handleCargoInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setCargoInsurance(value);
    if (!value && !customsBrokerage) {
      setInsurance(false);
      setCalculatorData({ ...calculatorData, insuranceValue: "", cargoInsurance: false });
    } else {
      setCalculatorData({ ...calculatorData, cargoInsurance: value ? true : false });
    }
  };

  const handleCustomsBrokerage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setCustomsBrokerage(value);
    if (!value && !cargoInsurance) {
      setInsurance(false);
      setCalculatorData({ ...calculatorData, insuranceValue: "", customsBrokerage: false });
    } else {
      setCalculatorData({ ...calculatorData, customsBrokerage: value ? true : false });
    }
  };

  const selectChangeHandler = (event: React.ChangeEvent<{ value: unknown }>, field: string) => {
    if (errorChekerCbm && errorChekerWeight && !hideCalculate) {
      //checkCollectData();
    }
    const value = event.target.value as number;
    const newData = {
      ...calculatorData,
      [field]: String(value),
    };
    setCalculatorData(newData);
    //console.log(value, field);
    if (field === "cartageId") {
      cartageList.map((item: any) => {
        const isAmazon: any = item.isAmazon;
        const shipmentDestination = item.cartages.find((item) => item.id === value);
        if (shipmentDestination) {
          setAmazonStatus(isAmazon);
          changeActivePanel(isAmazon);
        }
      });
    }
  };

  const changeActivePanel = (amazonStatus) => {
    if (expanded === "panel6" && amazonStatus) {
      setPanelStatus((prevState) => ({ ...prevState, panel6: false }));
      setExpanded("panel5");
    }
  };

  const submitHandler = () => {
    sethideCalculate(false);
    checkCollectData();
  };

  const updateShipmentCost = () => {
    if (transportationCost.value && formulaRespons.amzlclCost !== Number(transportationCost.value)) {
      setFormulaRespons({ ...formulaRespons, amzlclCost: Number(transportationCost.value) });
      setInstantLCLQuote({ ...instantLCLQuote, estimatedTransportation: transportationCost.value });
    }
  };

  const handleThisShipment = () => {
    if (validateBookShipment()) {
      setShowAccordion(true);
      updateShipmentCost();
    }
  };

  const validateBookShipment = () => {
    if (activeCustomCost) {
      return validateAllFields([{ validate: { ...transportationCost }, setValidate: setTransportationCost }]);
    } else return true;
  };

  const nextStep = (panel: string) => {
    let fields = [];
    updateShipmentCost();
    if (transportationCost.valid === false) {
      return setShowAccordion(false);
    }
    if (panel === "panel3") {
      fields = [
        { validate: { ...nameValidation, value: instantLCLQuote.yourName }, setValidate: setNameValidation },
        {
          validate: { ...companyNameValidation, value: instantLCLQuote.companyName },
          setValidate: setCompanyNameValidation,
        },
        {
          validate: { ...companyAddressValidation, value: instantLCLQuote.companyAddress },
          setValidate: setCompanyAddressValidation,
        },
        { validate: { ...emailValidation, value: instantLCLQuote.email }, setValidate: setEmailValidation },
        { validate: { ...phoneValidation, value: instantLCLQuote.phone }, setValidate: setPhoneValidation },
        {
          validate: { ...additionalInfoValidation, value: instantLCLQuote.additionalInfo },
          setValidate: setAdditionalInfoValidation,
        },
      ];
    }

    if (panel === "panel4") {
      fields = [
        {
          validate: { ...shipCompanyNameVal, value: instantLCLQuote.companyNameShipper },
          setValidate: setShipCompanyNameVal,
        },
        {
          validate: { ...shipCompanyAddressVal, value: instantLCLQuote.companyAddressShipper },
          setValidate: setShipCompanyAddressVal,
        },
        {
          validate: { ...shipChargeNameVal, value: instantLCLQuote.namePersonCharge },
          setValidate: setShipChargeNameVal,
        },
        { validate: { ...shipEmailVal, value: instantLCLQuote.emailShipper }, setValidate: setShipEmailVal },
        { validate: { ...shipPhoneVal, value: instantLCLQuote.phoneShipper }, setValidate: setShipPhoneVal },
        {
          validate: { ...shipAdditionalInfoVal, value: instantLCLQuote.additionalInformationShipper },
          setValidate: setShipAdditionalInfoVal,
        },
      ];
    }
    if (panel === "panel5") {
      fields = [
        {
          validate: { ...shipDescriptionVal, value: instantLCLQuote.shipmentDescription },
          setValidate: setShipDescriptionVal,
        },
        {
          validate: { ...shipmentNumberVal, value: instantLCLQuote.shipmentPurchase },
          setValidate: setShipmentNumberVal,
        },
      ];
    }
    if (panel === "panel6") {
      fields = [
        { validate: { ...amazonIdVal, value: instantLCLQuote.amazonRefId }, setValidate: setAmazonIdVal },
        { validate: { ...shipmentIDVal, value: instantLCLQuote.shipmentId }, setValidate: setShipmentIDVal },
      ];
    }

    if (fields.length) {
      if (validateAllFields(fields) && shipmentDateVal) {
        const isSkipPanel = !amazonStatus && panel === "panel5";
        if (isSkipPanel) {
          setPanelStatus((prevState) => ({ ...prevState, [panel]: true, panel6: true }));
          setExpanded(expanded ? "panel6" : false);
        } else {
          setPanelStatus((prevState) => ({ ...prevState, [panel]: true }));
          setExpanded(expanded ? panel : false);
        }
      } else {
        setPanelStatus((prevState) => ({ ...prevState, [panel]: false }));
      }
    } else {
      setExpanded(expanded ? panel : false);
    }
  };

  const toRegularView = (value) => {
    return value
      .toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
      .split(".")
      .join(".");
  };

  const fillResultDelivery = (value) => {
    if (value === 1) {
      return `${value} day`;
    } else if (value > 0) {
      return `${value} days`;
    } else {
      return `To be confirmed`;
    }
  };

  const returnSelectedName = (id: string, list: string) => {
    let name = "";
    if (list === "depot") {
      depotList.map((item: depotItemType) => {
        if (item.id === Number(id)) {
          name = item.name;
        }
      });
    } else {
      cartageList.map((item: cartageItemType) => {
        item.cartages.forEach((element) => {
          if (element.id === Number(id)) {
            name = element.name;
          }
        });
      });
    }
    return name;
  };

  const instantLCLQuoteHandler = (event: SyntheticEvent, field: string) => {
    let value: any = (event.target as HTMLInputElement).value;
    if (field === "phone" || field === "phoneShipper") {
      value = String(value).split(" ").join("").replace("+", "");
    }
    const newData = {
      ...instantLCLQuote,
      [field]: value,
    };
    setInstantLCLQuote(newData);
  };

  /*const handleDateChange = (date, key: string, panel: string) => {
    const newData = {
      ...instantLCLQuote,
      [key]: date.getTime() / 1000,
    };
    if (date == "Invalid Date") {
      setShipmentDateVal(false);
      setPanelStatus((prevState) => ({ ...prevState, [panel]: false }));
    } else {
      setShipmentDateVal(true);
    }
    setInstantLCLQuote(newData);
  };*/

  const sendInstantLCLQuote = async () => {
    setDisableButton(true);
    const tempInstantLCLQuote = instantLCLQuote;

    if (!amazonStatus) {
      tempInstantLCLQuote.amazonRefId = "";
      tempInstantLCLQuote.shipmentId = "";
    }
    tempInstantLCLQuote.shipmentTradeTerms = props.shipmentTrade;

    const response = await generalCRUD.addNewData("request/booking", JSON.stringify(tempInstantLCLQuote));
    if (response.code === 200) {
      const id = parseInt(helpFunctions.getParamIdFromUrl(), 10);
      const shipperInfo: shipperInfo = {
        companyName: instantLCLQuote.companyNameShipper,
        companyAddress: instantLCLQuote.companyAddressShipper,
        nameOfThePersonInCharge: instantLCLQuote.namePersonCharge,
        email: instantLCLQuote.emailShipper,
        phone: instantLCLQuote.phoneShipper,
        additionalInformation: instantLCLQuote.additionalInformationShipper,
      };
      toastr.success("New order was created", "");
      if (isNewCompany || !companiesList[0].id) {
        user.saveClientCompany(id, shipperInfo);
      }
      history.goBack();
      setDisableButton(false);
    }
  };

  const getDefaultSelectId = (depotList: any) => {
    for (let i = 0; i < depotList.length; i++) {
      return String(depotList[i].id);
    }
  };

  const addEntity = async () => {
    const validationResult = validateField(
      instantLCLQuote.additionalInformation,
      additionalInfoVal,
      setAdditionalInfoVal,
    );
    if (validationResult["valid"]) {
      sendInstantLCLQuote();
    } else {
    }
  };

  const onBlurHandler = (value, currentValidation, setValidation, panel) => {
    const validationResult = validateField(value, currentValidation, setValidation);
    if (!validationResult["valid"]) {
      setPanelStatus((prevState) => ({ ...prevState, [panel]: false }));
    }
  };

  const transportationCostHandler = (event, element) => {
    if (element === "checkbox") {
      const value = event.target.checked;
      const tempTransportationCost = transportationCost.value;
      setActivateCustomCost(value);
      setTransportationCost({ ...requiredField, value: tempTransportationCost });
    } else {
      let value: any = (event.target as HTMLInputElement).value;
      if (value.length && !helpFunctions.isNumber(value, "dec")) return false;
      value = validateFloatNumber(value, 2);
      setTransportationCost({ ...transportationCost, value: value });
    }
  };

  const companyNameHandler = (event) => {
    if (event.target.value === "new") {
      newCompanySwitch(true);
      const newData = {
        ...instantLCLQuote,
        companyNameShipper: "",
        companyAddressShipper: "",
        namePersonCharge: "",
        emailShipper: "",
        phoneShipper: "",
        additionalInformationShipper: "",
      };
      setInstantLCLQuote(newData);
    } else if (event.target.value !== "new" && isNewCompany) {
      newCompanySwitch(false);
    }

    setCompanyNameId(event.target.value);
  };

  const chooseFromExisting = () => {
    newCompanySwitch(false);
    companySelectSwitch(true);
    setCompanyNameId(null);
  };

  const selectItemHandler = (company: companiesListType) => {
    setShipCompanyNameVal({ ...shipCompanyNameVal, valid: true, error: "" });
    setShipCompanyAddressVal({ ...shipCompanyAddressVal, valid: true, error: "" });
    setShipChargeNameVal({ ...shipChargeNameVal, valid: true, error: "" });
    setShipEmailVal({ ...shipEmailVal, valid: true, error: "" });
    setShipPhoneVal({ ...shipPhoneVal, valid: true, error: "" });
    setShipAdditionalInfoVal({ ...shipAdditionalInfoVal, valid: true, error: "" });

    const newData = {
      ...instantLCLQuote,
      companyNameShipper: company.companyName,
      companyAddressShipper: company.companyAddress,
      namePersonCharge: company.nameOfThePersonInCharge,
      emailShipper: company.email,
      phoneShipper: company.phone,
      additionalInformationShipper: company.additionalInformation,
    };

    setInstantLCLQuote(newData);
  };

  const renderSelectGroup = (element: any) => {
    const items = element.cartages.map((p) => {
      return (
        <MenuItem key={p.id} value={p.id}>
          {p.name}
        </MenuItem>
      );
    });
    return [<ListSubheader key={element.id}>{element.name}</ListSubheader>, items];
  };

  return (
    <div id="instant-lcl-quote">
      <div className="calculator">
        <div className="flex">
          <div className="flex-item-50">
            <div className="title-item">Shipment origin</div>
            <FormControl className="select-wrapper">
              <Select
                value={calculatorData.depotId}
                onChange={(event) => selectChangeHandler(event, "depotId")}
                displayEmpty
              >
                <MenuItem disabled value="">
                  e. g. Shanghai
                </MenuItem>
                {depotList.map((item: depotItemType, index) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex-item-50">
            <div className="title-item">Shipment volume</div>
            <NumberFormat
              customInput={TextField}
              helperText={weightValidation.error}
              error={weightValidation.valid === false}
              value={calculatorData.cbm}
              className="base-input"
              placeholder="e. g. 16"
              allowNegative={false}
              isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, "dec") : true)}
              onChange={(event) => cbmChangeHandler(event)}
              onBlur={(e) => validateField(e.target.value, weightValidation, setWeightValidation)}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-item-50">
            <div className="title-item">Shipment destination</div>
            <FormControl className="select-wrapper">
              <Select
                value={calculatorData.cartageId ? calculatorData.cartageId : ""}
                onChange={(event) => selectChangeHandler(event, "cartageId")}
              >
                {cartageList && cartageList?.map((element) => renderSelectGroup(element))}
              </Select>
            </FormControl>
          </div>
          <div className="flex-item-50">
            <div className="title-item">Shipment weight</div>
            <NumberFormat
              customInput={TextField}
              helperText={volumeValidation.error}
              error={volumeValidation.valid === false}
              value={calculatorData.weight}
              className="base-input"
              placeholder="e. g. 9"
              allowNegative={false}
              isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, "dec") : true)}
              onChange={(event) => weightChangeHandler(event)}
              onBlur={(e) => validateField(e.target.value, volumeValidation, setVolumeValidation)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-item-100">
            <div className="title-item">Cargo insurance and customs brokerage</div>
            <FormControlLabel
              control={<Checkbox checked={insurance} onChange={handleInsurance} className="checkbox-notify" />}
              label="Add Cargo Insurance and\or Import Customs Brokerage"
            />
          </div>
        </div>
        <Collapse in={insurance}>
          <div className="flex">
            <div className="flex-item-100">
              <div className="title-item">Enter approximate shipment value in USD</div>
              <NumberFormat
                customInput={TextField}
                value={calculatorData.insuranceValue}
                className="base-input"
                placeholder="e. g. 16 000"
                allowNegative={false}
                isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, "dec") : true)}
                onChange={(event) => textChangeHandler(event, "insuranceValue")}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={cargoInsurance} onChange={handleCargoInsurance} className="checkbox-notify" />
                }
                label="Add Cargo Insurance"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={customsBrokerage} onChange={handleCustomsBrokerage} className="checkbox-notify" />
                }
                label="Add Canadian Customs Brokerage"
              />
            </div>
          </div>
        </Collapse>
        <Collapse in={hideCalculate}>
          <div className="submit-button-wrapper">
            <Button
              type="submit"
              variant="contained"
              value="send"
              className={errorChekerCbm && errorChekerWeight ? "main-btn" : "main-btn disable"}
              onClick={submitHandler}
            >
              Calculate
            </Button>
          </div>
        </Collapse>
        <Collapse in={formulaResult}>
          <div className="flex-result transportation-cost">
            <div className="result-item">
              <div className="title-item">Estimated transportation cost for this shipment</div>
              <div className="transportation-cost__content">
                <div className="bold">
                  {formulaRespons.amzlclCost % 1 !== 0
                    ? toRegularView(formulaRespons.amzlclCost.toFixed(2))
                    : formulaRespons.amzlclCost}{" "}
                  USD
                </div>
                <div className="transportation-cost__check">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeCustomCost}
                        onChange={(event) => transportationCostHandler(event, "checkbox")}
                        className="checkbox-notify"
                      />
                    }
                    label="Enter the rate manually"
                  />
                </div>
              </div>
              <div className="flex-item-50">
                <TextField
                  helperText={transportationCost.error}
                  error={transportationCost.valid === false}
                  value={transportationCost.value}
                  className="base-input accordion-input"
                  placeholder="e. g. 20"
                  disabled={!activeCustomCost}
                  onChange={(event) => transportationCostHandler(event, "input")}
                  onBlur={(e) => validateField(e.target.value, transportationCost, setTransportationCost)}
                />
              </div>
            </div>
            <div className="result-item">
              <div className="title-item">Estimated delivery time for this shipment</div>
              <div className="bold">{fillResultDelivery(Number(formulaRespons.delivery))}</div>
            </div>
            <Collapse in={!showAccordion}>
              <Button
                type="submit"
                variant="contained"
                value="book"
                className={errorChekerCbm && errorChekerWeight ? "main-btn" : "main-btn disable"}
                onClick={handleThisShipment}
              >
                Book this shipment
              </Button>
            </Collapse>
          </div>
        </Collapse>
      </div>
      <Collapse in={showAccordion}>
        <div className="accordion-wrapper">
          <Accordion expanded={expanded === "panel1"} onChange={handleChangeAccordion("panel1")}>
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
              <p className="title">QUOTE SUMMARY</p>
            </AccordionSummary>
            <AccordionDetails className="accordion-content-wrapper">
              <div className="flex">
                <div className="flex-item-100">
                  <span className="item">Transportation mode:&#160;</span>
                  <span className="item-value">Less-than-Container Load</span>
                </div>
                <div className="flex-item-100">
                  <span className="item">Shipment trade terms:&#160;</span>
                  <span className="item-value">{(props.shipmentTrade == 3) ? 'Pick-Up from Supplier' :  (props.shipmentTrade == 2) ? 'Drop-Off by Supplier' :  (props.shipmentTrade == 1) ? 'Supplier pays all origin fees (FOB)' : 'Other'}</span>
                </div>
                <div className="flex-item-100">
                  <span className="item">Shipment origin:&#160;</span>
                  <span className="item-value">{returnSelectedName(calculatorData.depotId, "depot")}</span>
                </div>
                <div className="flex-item-100">
                  <span className="item">Shipment destination:&#160;</span>
                  <span className="item-value">{returnSelectedName(calculatorData.cartageId, "cartage")}</span>
                </div>
                <div className="flex-item-100">
                  <span className="item">Shipment volume:&#160;</span>
                  <span className="item-value">{calculatorData.cbm} CBM</span>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-item-50">
                  <span className="item">Shipment weight:&#160;</span>
                  <span className="item-value">{calculatorData.weight} KG</span>
                </div>
                <div className="flex-item-50">
                  <span className="item">Shipment value:&#160;</span>
                  <span className="item-value">
                    {Number(calculatorData.insuranceValue) > 0 ? calculatorData.insuranceValue : ""} USD
                  </span>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-item-50">
                  <span className="item">Cargo insurance included:&#160;</span>
                  <span className="item-value"> {cargoInsurance ? "Yes" : "No"}</span>
                </div>
                <div className="flex-item-50">
                  <span className="item">Customs brokerage included:&#160;</span>
                  <span className="item-value">{customsBrokerage ? "Yes" : "No"}</span>
                </div>
              </div>
              <div className="flex">
                <div className="flex-item-100">
                  <span className="item">Estimated transportation cost for this shipment:&#160;</span>
                  <span className="item-value">{toRegularView(formulaRespons.amzlclCost.toFixed(2))} USD</span>
                </div>
              </div>
              <Button className="main-btn" onClick={() => nextStep("panel2")}>
                Next Step
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel2"} onChange={handleChangeAccordion("panel2")}>
            <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
              <p className="title">YOUR INFORMATION</p>
            </AccordionSummary>
            <AccordionDetails className="accordion-content-wrapper">
              <div className="flex flex-row">
                <div className="flex-item-50">
                  <div className="item">Your name</div>
                  <TextField
                    helperText={nameValidation.error}
                    error={nameValidation.valid === false}
                    value={instantLCLQuote.yourName}
                    type="text"
                    inputProps={{ maxLength: nameValidation.maxLength }}
                    className="base-input accordion-input"
                    placeholder="Tonny G. Stark"
                    onChange={(event) => instantLCLQuoteHandler(event, "yourName")}
                    onBlur={(e) => onBlurHandler(e.target.value, nameValidation, setNameValidation, "panel3")}
                  />
                </div>
                <div className="flex-item-50">
                  <div className="item">Company name</div>
                  <TextField
                    helperText={companyNameValidation.error}
                    error={companyNameValidation.valid === false}
                    value={instantLCLQuote.companyName}
                    type="text"
                    inputProps={{ maxLength: companyNameValidation.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. ABC Trader Inc."
                    onChange={(event) => instantLCLQuoteHandler(event, "companyName")}
                    onBlur={(e) =>
                      onBlurHandler(e.target.value, companyNameValidation, setCompanyNameValidation, "panel3")
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-item-100">
                  <div className="item">Company address</div>
                  <TextField
                    helperText={companyAddressValidation.error}
                    error={companyAddressValidation.valid === false}
                    value={instantLCLQuote.companyAddress}
                    type="text"
                    inputProps={{ maxLength: companyAddressValidation.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. 1345 West Pender St, Vancouver, BC, V2I 3B1, Canada"
                    onChange={(event) => instantLCLQuoteHandler(event, "companyAddress")}
                    onBlur={(e) =>
                      onBlurHandler(e.target.value, companyAddressValidation, setCompanyAddressValidation, "panel3")
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-item-50">
                  <div className="item">E-mail</div>
                  <TextField
                    helperText={emailValidation.error}
                    error={emailValidation.valid === false}
                    value={instantLCLQuote.email}
                    type="text"
                    inputProps={{ maxLength: emailValidation.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. jdowling@abctrader.com"
                    onChange={(event) => instantLCLQuoteHandler(event, "email")}
                    onBlur={(e) => onBlurHandler(e.target.value, emailValidation, setEmailValidation, "panel3")}
                  />
                </div>
                <div className="flex-item-50">
                  <div className="item">Phone</div>
                  <NumberFormat
                    customInput={TextField}
                    prefix={"+"}
                    helperText={phoneValidation.error}
                    error={phoneValidation.valid === false}
                    value={instantLCLQuote.phone}
                    className="base-input accordion-input"
                    inputProps={{ maxLength: phoneValidation.maxLength }}
                    placeholder="e.g. (604) 785 - 3176"
                    allowNegative={false}
                    isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, true) : true)}
                    onChange={(event) => instantLCLQuoteHandler(event, "phone")}
                    onBlur={(e) => onBlurHandler(e.target.value, phoneValidation, setPhoneValidation, "panel3")}
                  />
                </div>
              </div>
              <div className="flex-item-100">
                <div className="item">
                  Additional information <span className="optional">(Optional)</span>
                </div>
                <TextField
                  helperText={additionalInfoValidation.error}
                  error={additionalInfoValidation.valid === false}
                  value={instantLCLQuote.additionalInfo}
                  type="text"
                  inputProps={{ maxLength: additionalInfoValidation.maxLength }}
                  className="base-input accordion-input"
                  placeholder="e.g. Bill To: Nothern Lights Imports Inc"
                  onChange={(event) => instantLCLQuoteHandler(event, "additionalInfo")}
                  onBlur={(e) =>
                    onBlurHandler(e.target.value, additionalInfoValidation, setAdditionalInfoValidation, "panel3")
                  }
                />
              </div>
              <Button className="main-btn" onClick={() => nextStep("panel3")}>
                Next Step
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={!panelStatus.panel3}
            expanded={expanded === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
              <p className="title">SHIPPER INFORMATION</p>
            </AccordionSummary>
            <AccordionDetails className="accordion-content-wrapper">
              <div className="flex">
                <div className="flex-item-100">
                  <div className="item">Company name</div>
                  {isNewCompany || !companiesList[0].id ? (
                    <div className="company-name-wrapper">
                      <TextField
                        helperText={shipCompanyNameVal.error}
                        error={shipCompanyNameVal.valid === false}
                        value={instantLCLQuote.companyNameShipper}
                        type="text"
                        inputProps={{ maxLength: shipCompanyNameVal.maxLength }}
                        className="base-input accordion-input"
                        placeholder="e. g. ABC Trader Inc."
                        onChange={(event) => instantLCLQuoteHandler(event, "companyNameShipper")}
                        onBlur={(e) =>
                          onBlurHandler(e.target.value, shipCompanyNameVal, setShipCompanyNameVal, "panel4")
                        }
                      />
                      {companiesList[0].id && <span onClick={chooseFromExisting}>Choose from existing companies</span>}
                    </div>
                  ) : (
                    <>
                      <FormControl
                        className={`select-wrapper ${shipCompanyNameVal.valid === false ? "select-with-error" : ""}`}
                      >
                        <Select
                          open={isOpenCompanySelect}
                          onOpen={() => companySelectSwitch(true)}
                          onClose={() => companySelectSwitch(false)}
                          value={companyNameId ?? ""}
                          onChange={(event) => companyNameHandler(event)}
                          displayEmpty
                          className={`${!companyNameId ? "select-with-default" : ""}`}
                        >
                          <MenuItem disabled value="">
                            e. g. ABC Trader Inc.
                          </MenuItem>
                          <MenuItem value="new">Add new shipper</MenuItem>
                          {companiesList[0].id &&
                            companiesList.map((item: companiesListType, index) => {
                              return (
                                <MenuItem key={item.id} value={item.id} onClick={() => selectItemHandler(item)}>
                                  {item.companyName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      {shipCompanyNameVal.valid === false && <p className="select-error">This field cannot be empty</p>}
                    </>
                  )}
                </div>
                <div className="flex-item-100">
                  <div className="item">Company address</div>
                  <TextField
                    helperText={shipCompanyAddressVal.error}
                    error={shipCompanyAddressVal.valid === false}
                    value={instantLCLQuote.companyAddressShipper}
                    type="text"
                    inputProps={{ maxLength: shipCompanyAddressVal.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. No. 55, Bldg., 168 Jihua West Road, Chancheng Dist., Foshan, Guangdong, China"
                    onChange={(event) => instantLCLQuoteHandler(event, "companyAddressShipper")}
                    onBlur={(e) =>
                      onBlurHandler(e.target.value, shipCompanyAddressVal, setShipCompanyAddressVal, "panel4")
                    }
                  />
                </div>
                <div className="flex-item-100">
                  <div className="item">Name of the person in charge</div>
                  <TextField
                    helperText={shipChargeNameVal.error}
                    error={shipChargeNameVal.valid === false}
                    value={instantLCLQuote.namePersonCharge}
                    type="text"
                    inputProps={{ maxLength: shipChargeNameVal.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. Wang Wei"
                    onChange={(event) => instantLCLQuoteHandler(event, "namePersonCharge")}
                    onBlur={(e) => onBlurHandler(e.target.value, shipChargeNameVal, setShipChargeNameVal, "panel4")}
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-item-50">
                  <div className="item">E-mail</div>
                  <TextField
                    helperText={shipEmailVal.error}
                    error={shipEmailVal.valid === false}
                    value={instantLCLQuote.emailShipper}
                    type="text"
                    inputProps={{ maxLength: shipEmailVal.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. jdowling@abctrader.com"
                    onChange={(event) => instantLCLQuoteHandler(event, "emailShipper")}
                    onBlur={(e) => onBlurHandler(e.target.value, shipEmailVal, setShipEmailVal, "panel4")}
                  />
                </div>
                <div className="flex-item-50">
                  <div className="item">Phone</div>
                  <NumberFormat
                    customInput={TextField}
                    prefix={"+"}
                    helperText={shipPhoneVal.error}
                    error={shipPhoneVal.valid === false}
                    value={instantLCLQuote.phoneShipper}
                    className="base-input accordion-input"
                    inputProps={{ maxLength: shipPhoneVal.maxLength }}
                    placeholder="e.g. (604) 785 - 3176"
                    allowNegative={false}
                    isAllowed={(values) => (values.value.length ? helpFunctions.isNumber(values.value, true) : true)}
                    onChange={(event) => instantLCLQuoteHandler(event, "phoneShipper")}
                    onBlur={(e) => onBlurHandler(e.target.value, shipPhoneVal, setShipPhoneVal, "panel4")}
                  />
                </div>
              </div>
              <div className="flex-item-100">
                <div className="item">
                  Additional information <span className="optional">(Optional)</span>
                </div>
                <TextField
                  helperText={shipAdditionalInfoVal.error}
                  error={shipAdditionalInfoVal.valid === false}
                  value={instantLCLQuote.additionalInformationShipper}
                  type="text"
                  inputProps={{ maxLength: shipAdditionalInfoVal.maxLength }}
                  className="base-input accordion-input"
                  placeholder="e.g. Bill To: Nothern Lights Imports Inc"
                  onChange={(event) => instantLCLQuoteHandler(event, "additionalInformationShipper")}
                  onBlur={(e) =>
                    onBlurHandler(e.target.value, shipAdditionalInfoVal, setShipAdditionalInfoVal, "panel4")
                  }
                />
              </div>
              <Button className={"main-btn"} onClick={() => nextStep("panel4")}>
                Next Step
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={!panelStatus.panel4}
            expanded={expanded === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
              <p className="title">SHIPMENT INFORMATION</p>
            </AccordionSummary>
            <AccordionDetails className="accordion-content-wrapper">
              <div className="flex">
                <div className="flex-item-100">
                  <div className="item">Description of the goods</div>
                  <TextField
                    helperText={shipDescriptionVal.error}
                    error={shipDescriptionVal.valid === false}
                    value={instantLCLQuote.shipmentDescription}
                    type="text"
                    multiline
                    rows={6}
                    inputProps={{ maxLength: shipDescriptionVal.maxLength }}
                    className="base-input accordion-textarea"
                    placeholder="e.g. What your goods are"
                    onChange={(event) => instantLCLQuoteHandler(event, "shipmentDescription")}
                    onBlur={(e) => onBlurHandler(e.target.value, shipDescriptionVal, setShipDescriptionVal, "panel5")}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-item-100">
                  <div className="item">PO number or other reference used during order placement with supplier</div>
                  <TextField
                    helperText={shipmentNumberVal.error}
                    error={shipmentNumberVal.valid === false}
                    value={instantLCLQuote.shipmentPurchase}
                    type="text"
                    inputProps={{ maxLength: shipmentNumberVal.maxLength }}
                    className="base-input accordion-input"
                    placeholder="e.g. 20019012356"
                    onChange={(event) => instantLCLQuoteHandler(event, "shipmentPurchase")}
                    onBlur={(e) => onBlurHandler(e.target.value, shipmentNumberVal, setShipmentNumberVal, "panel5")}
                  />
                </div>
              </div>
              {/* 
                <div className="flex">
                  <div className="flex-item-100">
                    <div className="item">
                      Shipment purchase order number <span className="optional">(Optional)</span>
                    </div>
                    <TextField
                      helperText={shipmentNumberVal.error}
                      error={shipmentNumberVal.valid === false}
                      value={instantLCLQuote.shipmentPurchase}
                      type="text"
                      inputProps={{ maxLength: shipmentNumberVal.maxLength }}
                      className="base-input accordion-input"
                      placeholder="e.g. 20019012356"
                      onChange={(event) => instantLCLQuoteHandler(event, "shipmentPurchase")}
                      onBlur={(e) => onBlurHandler(e.target.value, shipmentNumberVal, setShipmentNumberVal, "panel5")}
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex-item-50">
                    <div className="item">
                      Supplier's invoice number <span className="optional">(Optional)</span>
                    </div>
                    <TextField
                      helperText={supplierNumberVal.error}
                      error={supplierNumberVal.valid === false}
                      value={instantLCLQuote.suppliersInvoiceNumber}
                      type="text"
                      inputProps={{ maxLength: supplierNumberVal.maxLength }}
                      className="base-input accordion-input"
                      placeholder="e.g. 12364-53"
                      onChange={(event) => instantLCLQuoteHandler(event, "suppliersInvoiceNumber")}
                      onBlur={(e) => onBlurHandler(e.target.value, supplierNumberVal, setSupplierNumberVal, "panel5")}
                    />
                  </div>
                  <div className="flex-item-50 date-wrapper">
                    <div className="item">
                      Date <span className="optional">(Optional)</span>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        className="base-input"
                        margin="normal"
                        format="MM/dd/yyyy"
                        value={helpFunctions.getDateFromUnix(instantLCLQuote.date)}
                        onChange={(event) => handleDateChange(event, "date", "panel5")}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-item-100">
                    <div className="item">
                      Other reference used during order placement with supplier{" "}
                      <span className="optional">(Optional)</span>
                    </div>
                    <TextField
                      helperText={referenceVal.error}
                      error={referenceVal.valid === false}
                      value={instantLCLQuote.otherReferenceUsed}
                      type="text"
                      inputProps={{ maxLength: referenceVal.maxLength }}
                      className="base-input accordion-input"
                      placeholder="e.g. Order for John"
                      onChange={(event) => instantLCLQuoteHandler(event, "otherReferenceUsed")}
                      onBlur={(e) => onBlurHandler(e.target.value, referenceVal, setReferenceVal, "panel5")}
                    />
                  </div>
                </div>
              */}

              <Button className={"main-btn"} onClick={() => nextStep("panel5")}>
                Next Step
              </Button>
            </AccordionDetails>
          </Accordion>
          {amazonStatus && (
            <Accordion
              disabled={!panelStatus.panel5}
              expanded={expanded === "panel5"}
              onChange={handleChangeAccordion("panel5")}
            >
              <AccordionSummary aria-controls="panel5bh-content" id="panel5bh-header">
                <p className="title">AMAZON FBA BOOKING INFORMATION</p>
              </AccordionSummary>
              <AccordionDetails className="accordion-content-wrapper">
                <div className="flex flex-row">
                  <div className="flex-item-50">
                    <div className="item">Amazon Ref ID</div>
                    <TextField
                      helperText={amazonIdVal.error}
                      error={amazonIdVal.valid === false}
                      value={instantLCLQuote.amazonRefId}
                      type="text"
                      inputProps={{ maxLength: amazonIdVal.maxLength }}
                      className="base-input accordion-input"
                      placeholder="e.g. 731QUYFD"
                      onChange={(event) => instantLCLQuoteHandler(event, "amazonRefId")}
                      onBlur={(e) => onBlurHandler(e.target.value, amazonIdVal, setAmazonIdVal, "panel6")}
                    />
                  </div>
                  <div className="flex-item-50">
                    <div className="item">Shipment ID (PO)</div>
                    <TextField
                      helperText={shipmentIDVal.error}
                      error={shipmentIDVal.valid === false}
                      value={instantLCLQuote.shipmentId}
                      type="text"
                      inputProps={{ maxLength: shipmentIDVal.maxLength }}
                      className="base-input accordion-input"
                      placeholder="e.g. FBA16HWLG7WZ"
                      onChange={(event) => instantLCLQuoteHandler(event, "shipmentId")}
                      onBlur={(e) => onBlurHandler(e.target.value, shipmentIDVal, setShipmentIDVal, "panel6")}
                    />
                  </div>
                </div>
                {/*
                  <div className="flex">
                    <div className="flex-item-100">
                      <div className="item">Merchant name</div>
                      <TextField
                        helperText={merchantVal.error}
                        error={merchantVal.valid === false}
                        value={instantLCLQuote.merchantName}
                        type="text"
                        inputProps={{ maxLength: merchantVal.maxLength }}
                        className="base-input accordion-input"
                        placeholder="e.g. FBA Merchant"
                        onChange={(event) => instantLCLQuoteHandler(event, "merchantName")}
                        onBlur={(e) => onBlurHandler(e.target.value, merchantVal, setMerchantVal, "panel6")}
                      />
                    </div>
                  </div>
                */}
                <Button className={"main-btn"} onClick={() => nextStep("panel6")}>
                  Next Step
                </Button>
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion
            disabled={!panelStatus.panel6}
            expanded={expanded === "panel6"}
            onChange={handleChangeAccordion("panel6")}
          >
            <AccordionSummary aria-controls="panel6bh-content" id="panel6bh-header">
              <p className="title">ADDITIONAL INFORMATION OR REQUESTS</p>
            </AccordionSummary>
            <AccordionDetails className="accordion-content-wrapper">
              <div className="flex">
                <div className="flex-item-100">
                  <div className="item">
                    Enter your information <span className="optional">(Optional)</span>
                  </div>
                  <TextField
                    helperText={additionalInfoVal.error}
                    error={additionalInfoVal.valid === false}
                    value={instantLCLQuote.additionalInformation}
                    type="text"
                    multiline
                    rows="4"
                    inputProps={{ maxLength: additionalInfoVal.maxLength }}
                    className="base-input accordion-textarea"
                    placeholder="Enter your information"
                    onChange={(event) => instantLCLQuoteHandler(event, "additionalInformation")}
                    onBlur={(e) => onBlurHandler(e.target.value, additionalInfoVal, setAdditionalInfoVal, "lastPanel")}
                  />
                </div>
              </div>
              <Button
                className={disableButton ? "main-btn disable" : "main-btn"}
                disabled={disableButton}
                onClick={addEntity}
              >
                Send
              </Button>
            </AccordionDetails>
          </Accordion>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(InstantLCLQuote);
