import React, { useEffect, FC, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import helpFunctions from "../../../tools/helpFunctions";
import { generalCRUD } from "../../../actions/generalCRUD";
import { app } from "../../../actions/app";

// Components
import { toastr } from "react-redux-toastr";
import LoaderLocal from "../../atoms/LoaderLocal";
import GeneralButton from "../../atoms/GeneralButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NoImage from "../../../assets/images/noimage.svg";
import DatePicker from "../../molecules/DatePicker";
import WEditor from "../../molecules/WEditor";
import ModalWindow from "../../molecules/ModalWindow";
import ThemeSwitcher from "../../molecules/themeSwitcher";

// Styles
import "./style.scss";
import {FieldType} from "../../../validator/types";
import {maxLengthInstance, requireInstance} from "../../../validator/Fields/instantLCLQuote";
import TextField from "@material-ui/core/TextField";
import {validateAllFields, validateField} from "../../../validator/Fields";

export interface IArticleData {
  id: number;
  type: string;
  title: string;
  metaDescription: string;
  content: string;
  previewImage: {
    original: string;
    id: number | null;
  };
  customCreatedAt: number;
  metaTitle: null | string;
}

export const articleDataInitial = {
  id: 0,
  type: "news",
  title: "",
  metaDescription: "",
  content: "",
  previewImage: {
    original: "",
    id: null,
  },
  customCreatedAt: 0,
  metaTitle: null,
};

type articlePropsType = {
  history: any;
};

const BlogArticle: FC<any> = (props: articlePropsType) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [articleData, setArticleData] = useState<IArticleData>(articleDataInitial);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>(props.history.location.state ? props.history.location.state.type : "news");
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(Date.now()));
  const [selectedTime, setSelectedTime] = useState<Date | null>(new Date(Date.now()));
  const [exitModal, setExitModal] = useState<string | null>(null);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const [titleValid, setTitleValid] = useState<FieldType>({...maxLengthInstance(150)})
  const [metaTitleValid, setMetaTitleValid] = useState<FieldType>({...maxLengthInstance(150, false)})
  const [descriptionValid, setDescriptionValid] = useState<FieldType>({...maxLengthInstance(255, false)})
  const [articleValid, setArticleValid] = useState<boolean>(null)
  const [imageValid, setImageValid] = useState({valid: null, message: ''})
  const [datesValid, setDatesValid] = useState<boolean>(true)

  /*Validation*/

  const allFields = [
    {validate: {...titleValid, value: articleData.title}, setValidate: setTitleValid},
    {validate: {...descriptionValid,value: articleData.metaDescription}, setValidate: setDescriptionValid},
  ]

  const articleId = helpFunctions.getParamIdFromUrl();

  useEffect(() => {
    if (articleId) {
      getArticleData();
    }

    return () => {
      window.removeEventListener("popstate", showExitModalBackAction);
    };
  }, []);

  useEffect(() => {
    if (isEdited) {
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
      window.addEventListener("popstate", showExitModalBackAction, false);
    }
  }, [isEdited]);

  const getArticleData = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getConcreteData("article", Number(articleId));
    if (response.code === 200) {
      setArticleData(response.data);
      setType(response.data.type);
      const dateObject = new Date(response.data.customCreatedAt * 1000);
      setSelectedDate(dateObject);
      setSelectedTime(dateObject);
      setIsLoading(false);
    } else {
      history.push(`/blog`);
      //document.location.href = "/blog";
      toastr.error("Error", response.message);
    }
  };

  const inputTextHandler = (value: string, field: string) => {
    setArticleData({
      ...articleData,
      [field]: value,
    });

    /*WEditor does not work correctly, on change handler works on init component*/
    if (field === "content" && value !== articleData.content) {
      setIsEdited(true)
    } else if (field !== "content" && !isEdited) {
      setIsEdited(true);
    }
    /*End*/

    if (field === "content") {
      if (value.length) {
        setArticleValid(true)
      } else {
        setArticleValid(false)
      }
    }
  };

  const typeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  const handleDateChange = (date) => {
    if (date == "Invalid Date") {
      setDatesValid(false)
    } else {
      setDatesValid(true)
    }
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    if (time == "Invalid Date") {
      setDatesValid(false)
    } else {
      setDatesValid(true)
    }
    console.log(time)
    setSelectedTime(time);
  };

  const cancelCreationArticle = () => {
    if (isEdited) return setExitModal("cancel");
    confirmExit();
  };

  const showExitModalBackAction = () => {
    setExitModal("back");
  };

  const confirmExit = () => {
    setActiveTab();
    if (exitModal === "back") {
      if (window.history.state.name) {
        history.goBack();
      }
      return history.goBack();
    }
    history.push("/blog");
  };

  const setActiveTab = () => {
    if (type === "news") return;
    dispatch({
      type: "ACTIVE_TAB_BLOG",
      payload: {
        activeTab: 1,
      },
    });
  };

  const loadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files.length) return false;
    const fileSize = event.target.files[0].size;
    if (fileSize > 4 * 1024 * 1024) {
      setImageValid(prevState => ({...prevState, valid: false, message: 'Error. Maximal image size is 4 Mb'}))
      return false;
    } else {
      setImageValid(prevState => ({...prevState, valid: true, message: ''}))
    }
    setIsPreviewLoading(true);
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append(`image`, file, fileName);
    const response = await app.uploadImage(formData);
    if (response.code === 200) {
      setArticleData({
        ...articleData,
        previewImage: response.data,
      });
    } else {
      toastr.error("Error", response.message);
    }
    setIsPreviewLoading(false);
  };

  const submitArticle = async () => {
    if (!articleData.previewImage.original) {
      setImageValid({valid: false, message: 'This field cannot be empty'})
    } else {
      setImageValid({valid: true, message: ''})
    }
    if (!articleData.content) {
      setArticleValid(false)
    } else {
      setArticleValid(true)
    }
    if (validateAllFields(allFields) && articleValid && datesValid && imageValid) {
      setIsLoading(true);
      const resultObject = {
        type: type,
        content: articleData.content,
        title: articleData.title,
        metaTitle: articleData.metaTitle,
        metaDescription: articleData.metaDescription,
        previewImageId: articleData.previewImage.id,
        customCreatedAt: helpFunctions.countTimeStamp(selectedDate, selectedTime),
      };

      let response;
      if (!articleId) {
        response = await generalCRUD.addNewData("article", resultObject);
      } else {
        response = await generalCRUD.editConcreteData("article", articleData.id, resultObject);
      }
      if (response.code === 200) {
        setActiveTab();
        !articleId
            ? toastr.success("New article was created successfully", "")
            : toastr.success("Article was edited successfully", "");
        history.push(`/blog`);
      } else {
        toastr.error("Error", response.message);
      }
      setIsLoading(false);
    }
  };

  const fileRef = useRef(null);

  const typeList = ["news", "resources"];

  return (
    <div id="create-article">
      <div className="hide-switcher"><ThemeSwitcher/></div>
      <form onSubmit={(event) => event.preventDefault()} className="content">
        <div className="content-item top-title">article constructor</div>
        <div className="content-item">
          <p className="title">Choose section for article:</p>
          <FormControl className="general-select">
            <Select value={type} onChange={typeHandler}>
              {typeList.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="content-item inputs">
          <div>
            <p className="title">Add title:</p>
            <TextField
              helperText={titleValid.error}
              error={titleValid.valid === false}
              value={articleData.title}
              className="base-input"
              InputProps={{ disableUnderline: true, inputProps: {maxLength: titleValid.maxLength} }}
              type="text"
              placeholder="Title of article"
              onChange={(event) => inputTextHandler(event.target.value, "title")}
              onBlur={(e) => validateField(e.target.value, titleValid, setTitleValid)}
            />
          </div>
          <div>
            <p className="title">Add meta description:</p>
            <TextField
              helperText={descriptionValid.error}
              error={descriptionValid.valid === false}
              value={articleData.metaDescription}
              className="base-input"
              InputProps={{ disableUnderline: true, inputProps: {maxLength: descriptionValid.maxLength} }}
              type="text"
              placeholder="Meta description of article"
              onChange={(event) => inputTextHandler(event.target.value, "metaDescription")}
              onBlur={(e) => validateField(e.target.value, descriptionValid, setDescriptionValid)}
            />
          </div>
        </div>
        <div className="content-item inputs">
          <div>
            <p className="title">Add meta title:</p>
            <TextField
                helperText={metaTitleValid.error}
                error={metaTitleValid.valid === false}
                value={articleData.metaTitle ?? ''}
                className="base-input"
                InputProps={{ disableUnderline: true, inputProps: {maxLength: metaTitleValid.maxLength} }}
                type="text"
                placeholder="Meta title of article"
                onChange={(event) => inputTextHandler(event.target.value, "metaTitle")}
                onBlur={(e) => validateField(e.target.value, metaTitleValid, setMetaTitleValid)}
            />
          </div>
        </div>
        <div className="content-item editor">
          <p className="title">Make article:</p>
          <div className={articleValid === false ? 'editor-err': 'editor-wrapper'}>
            <WEditor
              content={articleData.content}
              uploadImage={app.uploadImage}
              handleEditorChange={(value) => inputTextHandler(value, "content")}
            />
          </div>
          <div className={articleValid === false ? 'editor-show-err editor-err-text': 'editor-err-text'}>This block cannot be empty</div>
          </div>
        <div className="content-item">
          <p className="title">Upload preview image (preferred image size is 1415х545, aspect ratio &#8776; 3x1):</p>
          <div className="preview">
            <div
              className={
                articleData.previewImage && articleData.previewImage.id ? "preview-input loaded" : "preview-input"
              }
              onClick={() => fileRef.current.click()}
            >
              &nbsp;
            </div>
            <input
              type="file"
              className="hidden-input"
              accept={"image/x-png,image/gif,image/jpeg"}
              ref={fileRef}
              required={true}
              onChange={(event) => loadFile(event)}
            />
            <div className={imageValid.valid === false ? 'image-error preview-image-wrapper': 'preview-image-wrapper'}>
              <div className="preview-image" onClick={() => fileRef.current.click()}>
                <img
                    src={
                      articleData.previewImage && articleData.previewImage.original
                          ? articleData.previewImage.original
                          : NoImage
                    }
                    alt="preview"
                />
                {isPreviewLoading && <LoaderLocal />}
              </div>
              <div className="image-preview-err-text">{imageValid.message}</div>
            </div>

          </div>
        </div>
        <div className="content-item">
          <DatePicker
            title="Set date of article creation"
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            handleDateChange={handleDateChange}
            handleTimeChange={handleTimeChange}
          />
        </div>
        <div className="actions">
          <GeneralButton classes="cancel" handleClick={cancelCreationArticle}>
            Cancel
          </GeneralButton>
          <GeneralButton classes="submit" type="submit" handleClick={submitArticle}>
            {!articleId ? "Create article" : "Edit article"}
          </GeneralButton>
        </div>
      </form>
      {isLoading && <LoaderLocal />}
      <ModalWindow
        open={!!exitModal}
        handleClose={() => setExitModal(null)}
        handleSubmit={confirmExit}
        title="Attention!"
        description="Leaving this page will lead to losing of the created content."
        submitTitle="leave"
        closeTitle="stay here"
        isDeleting={true}
      />
    </div>
  );
};

export default withRouter(BlogArticle);
