import React, { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { app } from "../../../actions/app";
import helpFunctions from "../../../tools/helpFunctions";
import clsx from "clsx";
import { createStyles, makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import Orders from "../../../assets/images/orders.svg";
import Clients from "../../../assets/images/clients.svg";
import Csr from "../../../assets/images/csr.svg";
import Blog from "../../../assets/images/blog.svg";
import Options from "../../../assets/images/options.svg";
import Mail from "../../../assets/images/mail.svg";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import { Popover } from "@material-ui/core";

// Styles
import "./style.scss";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
    },
    popover: {
      pointerEvents: "none",
    },
    popoverContent: {
      pointerEvents: "auto",
    },
  }),
);

type menuType = {
  isOpenMenu: boolean;
  activeLink: string;
  children: React.ReactNode;
};

const icons = [Orders, Clients, Csr, Blog, Mail, Options];
const optionsItems = [
  /*{
    title: "Categories",
    link: "categories",
  },*/
  {
    title: "Events",
    link: "events",
  },
  {
    title: "Depots",
    link: "depots",
  },
  {
    title: "Ports",
    link: "ports",
  },
  {
    title: "Cities",
    link: "cities",
  },
  {
    title: "Pick-up Locations",
    link: "locations",
  },
  {
    title: "Vessels",
    link: "vessels",
  },
  {
    title: "Cartages",
    link: "cartages",
  },
  {
    title: "Freight",
    link: "freight",
  },
  {
    title: "Constants",
    link: "constants",
  },
  {
    title: "Delivery Time",
    link: "delivery-time",
  },
  {
    title: "Mail Settings",
    link: "mail-settings",
  },
  {
    title: "Hints",
    link: "hints",
  },
];

const isSubLinkActive = (name: string): boolean => {
  let result = false;
  optionsItems.forEach((item) => {
    if (item.link === name) return (result = true);
  });
  return result;
};

let clickSublinkInside = false;

const Menu: FC<menuType> = (props: menuType) => {
  const [expanded, setExpanded] = useState(clickSublinkInside);
  const [openedPopover, setOpenedPopover] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  // for highlighting right link
  useEffect(() => {
    if (props.activeLink === helpFunctions.getActiveLinkFromUrl()) {
      if (
        !clickSublinkInside &&
        ((expanded && !isSubLinkActive(props.activeLink)) || (!expanded && isSubLinkActive(props.activeLink)))
      ) {
        setExpanded(isSubLinkActive(props.activeLink));
      } else {
        clickSublinkInside = false;
      }
    }
  }, [props.activeLink]); // only props.activeLink

  const handleDrawerOpen = () => {
    app.setIsOpenMenu(true);
  };

  const handleDrawerClose = () => {
    app.setIsOpenMenu(false);
  };

  const defineLinkClass = (name: string): string => {
    return props.activeLink === name || (name === "options" && isSubLinkActive(props.activeLink))
      ? "active in-menu"
      : "in-menu";
  };

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const popoverAnchor = useRef(null);

  const subMenuList = (
    <ul className="sub-menu">
      {optionsItems.map((item, i) => (
        <li className={props.activeLink === item.link ? "active" : ""} key={i}>
          <Link
            to={`/${item.link}`}
            onClick={() => {
              clickSublinkInside = true;
            }}
          >
            <span className="list-text">{item.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div
      id="menu"
      className={clsx(classes.root, {
        closed: !props.isOpenMenu,
      })}
    >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.isOpenMenu,
          [classes.drawerClose]: !props.isOpenMenu,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.isOpenMenu,
            [classes.drawerClose]: !props.isOpenMenu,
          }),
        }}
      >
        <div>
          <div className={clsx(classes.toolbar, "up-part")}>
            {props.isOpenMenu ? (
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={props.isOpenMenu ? classes.hide : ""}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <Divider />
          <div>
            <List>
              {["orders", "clients", "blog", "templates", "options"].map((text, index) =>
                text !== "options" ? (
                  <Link to={`/${text}`} className={defineLinkClass(text)} key={index}>
                    <ListItem button key={text} className="in-menu normal">
                      <div className="list-icon">
                        <img src={icons[index]} alt="text" />
                      </div>
                      <span className="list-text">{text}</span>
                    </ListItem>
                  </Link>
                ) : (
                  <Accordion
                    expanded={expanded && props.isOpenMenu}
                    classes={{ root: "in-menu" }}
                    onChange={() => setExpanded(!expanded)}
                    key={index}
                  >
                    <AccordionSummary>
                      <ListItem
                        className={`${defineLinkClass(text)} acc-btn`}
                        button
                        key={text}
                        ref={popoverAnchor}
                        aria-owns="mouse-over-popover"
                        aria-haspopup="true"
                        onMouseEnter={popoverEnter}
                        onMouseLeave={popoverLeave}
                      >
                        <div className="list-icon">
                          <img src={icons[index]} alt="text" />
                        </div>
                        <div className={expanded ? "triangle open" : "triangle"}>&nbsp;</div>
                        <span className="list-text">{text}</span>
                        {!props.isOpenMenu && (
                          <Popover
                            id="mouse-over-popover"
                            className={clsx(classes.popover, "popover-menu")}
                            classes={{
                              paper: classes.popoverContent,
                            }}
                            open={openedPopover}
                            anchorEl={popoverAnchor.current}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
                          >
                            <div>{subMenuList}</div>
                          </Popover>
                        )}
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>{subMenuList}</AccordionDetails>
                  </Accordion>
                ),
              )}
            </List>
          </div>
        </div>
      </Drawer>
      <main id="main" className="main">
        {props.children}
      </main>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    isOpenMenu: state.app.isOpenMenu,
    activeLink: state.app.activeLink,
  };
};

export default connect(mapStateToProps)(Menu);
