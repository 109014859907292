import React, { FC, useEffect } from "react";

import useCustomTheme from "react-switch-theme";
import Switch from "@material-ui/core/Switch";

import Sun from "../../../assets/images/sun.svg";
import Moon from "../../../assets/images/moon.svg";

// Styles
import "./style.scss";
import { app } from "../../../actions/app";

const colors = {
  light: {
    black: "#000000",
    black2: "#161616",
    white: "#FFFFFF",
    colorAccent: "#0094FF",
    colorHeaderTitle: "#FFFFFF",
    colorHeaderTitleBg: "#407499",
    colorHeaderTitleBgDelivery: "#407499",
    colorSpecial: "#F5F8FF",
    colorSpecialBorder: "#F5F8FF",
    colorSpecialBackground: "#F5F8FF",
    colorSpecialBackground2: "#F5F8FF",
    colorWhite: "#FFFFFF",
    colorBlack: "#000000",
    colorLightGray: "#F8F8F8",
    colorGrey: "#575757",
    colorInputIndicator: "#757575",
    colorInputDisableText: "#4B4B4B",
    colorInputDisableBg: "#CFCFCF",
    colorMediumGrey: "#9c9c9c",
    colorYellow: "#FFCA0D",
    colorSwitcher: "#FFCA0D",
    colorText: "#161616",
    colorTextLight: "#FFFFFF",
    colorPagi: "#000000de",
    colorTitle: "#4B4B4B",
    colorTitle2: "#4B4B4B",
    colorTitle3: "#685EB0",
    colorError: "#FF5757",
    colorTomato: "#FF4444",
    colorOrange: "#F8A64C",
    colorActive: "#685EB0",
    colorActiveEdit: "#685EB0",
    colorActiveSave: "#685EB0",
    colorHeader: "#232F3C",
    colorLine: "#FFFFFF",
    colorLineEven: "#EFF2F5",
    colorLineHover: "#CFCFCF",
    colorInputBG: "#EFF2F5",
    colorTabBG: "#EFF2F5",
    colorMenu: "#263544",
    colorMenuMarker: "#141B23",
    colorTab: "#D9E6FA",
    colorTabHover: "#B9D1F6",
    colorProfile: "#FBFBFB",
    colorMaterialInputHover: "#f7fafe",
    colorInputFocus: "#C5D2EE",
    colorInputBlogBg: "#EFF2F5",
    colorIconShadow: "#e7e7e7",
    colorBorder: "#EDECFF",
    colorBorder2: "#ECECEC",
    colorGrey2: "#aaa",
    colorGrey3: "#e2e2e2",
    colorGrey4: "#ccc",
    colorBackgroundMail1: "#A8C0FF",
    colorBackgroundMail2: "#3F2C96",
    colorTabTitle: "#685EB0",
    colorExtDoc: "#4B7EFF",
    colorButtonBorder: "transparent",
    topPartSubtitle: "#161616",
  },
  dark: {
    black: "#000000",
    black2: "#161616",
    white: "#FFFFFF",
    colorAccent: "#0094FF",
    colorHeaderTitle: "#0094FF",
    colorHeaderTitleBg: "#333333",
    colorHeaderTitleBgDelivery: "#4A4A4A",
    colorSpecial: "#F5F8FF",
    colorSpecialBorder: "#4A4A4A",
    colorSpecialBackground: "#333333",
    colorSpecialBackground2: "#2C2C2C",
    colorWhite: "#333333",
    colorBlack: "#FFFFFF",
    colorLightGray: "#F8F8F8",
    colorGrey: "#575757",
    colorInputIndicator: "#C2C2C2",
    colorInputDisableText: "#C2C2C2",
    colorInputDisableBg: "#4A4A4A",
    colorMediumGrey: "#9c9c9c",
    colorYellow: "#FFCA0D",
    colorSwitcher: "#0094FF",
    colorText: "#FFFFFF",
    colorTextLight: "#FFFFFF",
    colorPagi: "#C2C2C2",
    colorTitle: "#FFFFFF",
    colorTitle2: "#0094FF",
    colorTitle3: "#FFFFFF",
    colorError: "#FF5757",
    colorTomato: "#FF4444",
    colorOrange: "#A48206",
    colorActive: "#685EB0",
    colorActiveEdit: "#C2C2C2",
    colorActiveSave: "#0094FF",
    colorHeader: "#2F2F2F",
    colorLine: "#4A4A4A",
    colorLineEven: "#404040",
    colorLineHover: "#2F2F2F",
    colorInputBG: "#333333",
    colorTabBG: "#4A4A4A",
    colorMenu: "#202020",
    colorMenuMarker: "#484848",
    colorTab: "#404040",
    colorTabHover: "#2F2F2F",
    colorProfile: "#333333",
    colorMaterialInputHover: "#f7fafe",
    colorInputFocus: "#232323",
    colorInputBlogBg: "#4A4A4A",
    colorIconShadow: "#000000",
    colorBorder: "#EDECFF",
    colorBorder2: "#ECECEC",
    colorGrey2: "#aaa",
    colorGrey3: "#e2e2e2",
    colorGrey4: "#ccc",
    colorBackgroundMail1: "#A8C0FF",
    colorBackgroundMail2: "#3F2C96",
    colorTabTitle: "#0094FF",
    colorExtDoc: "#4B7EFF",
    colorButtonBorder: "#2f2f2f",
    topPartSubtitle: "#CFCFCF",
  },
};

const themeOptions = {
  colors,
  activeMode: localStorage.getItem("themeStyle") ? localStorage.getItem("themeStyle") : "light",
  offlineStorageKey: "themeStyle",
};

export const ThemeSwitcher: FC = () => {
  const [themeSwitcher, setThemeSwitcher] = useCustomTheme(themeOptions);

  useEffect(() => {
    const el = document.querySelector("body");
    el.className = localStorage.getItem("themeStyle") ? localStorage.getItem("themeStyle") : "light";
    app.setCurrentTheme(localStorage.getItem("themeStyle") ? localStorage.getItem("themeStyle") : "light");
  }, [themeSwitcher]);

  return (
    <div id="switcher-wrapper">
      <img src={Sun} className="sun" alt="sun" />
      <Switch checked={themeSwitcher === "dark"} value={themeSwitcher} onChange={() => setThemeSwitcher()} />
      <img src={Moon} className="moon" alt="moon" />
    </div>
  );
};

export default ThemeSwitcher;
