import React, { useState, useEffect, FC } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import queryString from "query-string";
import _ from "lodash";

import { FieldType } from "../../../validator/types";
import { requireInstance } from "../../../validator/Fields/instantLCLQuote";
import { validateAllFields, validateField, validateFloatNumber } from "../../../validator/Fields";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// Styles
import "./style.scss";
import { useSelector } from "react-redux";
import { rootReducerType } from "../../../redux/reducers";
import { options } from "../../../actions/options";
import { sortColumnType } from "../../../types/actions/generalCRUD";
import TableSelect from "../../atoms/ForTable/Selects";

type freightItemType = {
  id: string | number;
  tier: string | number;
  dest: string;
  cbm: string;
  mt: string;
  [key: string]: string | number;
};

const freightItemInitial = {
  id: "",
  tier: "",
  dest: "",
  cbm: "",
  mt: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const FreightContainer: FC = () => {
  const [freightList, setFreightList] = useState<Array<freightItemType>>([]);
  const [freightItem, setFreightItem] = useState<freightItemType>(freightItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [destOptions, setDestOptions] = useState<Array<string>>([]);
  const [destFilterOptions, setDestFilterOptions] = useState<Array<string>>([]);
  const [tierOptions, setTierOptions] = useState<Array<string>>([]);
  const [filterRequestController, setFilterRequestController] = useState<boolean>(false);

  const sortedColumn = useSelector((state: rootReducerType) => state.clients.sortedColumn);
  const filterParams = useSelector((state: rootReducerType) => state.clients.filterParams);

  /*Validation*/
  const [tierValid, setTierValid] = useState<FieldType>({ ...requireInstance });
  const [destinationValid, setDestinationValid] = useState<FieldType>({ ...requireInstance });
  const [cbmValid, setCbmValid] = useState<FieldType>({ ...requireInstance });
  const [mtValid, setMtValid] = useState<FieldType>({ ...requireInstance });

  // to get all entities
  useEffect(() => {
    getAllDestOptions();
    getAllFreight(true);
    setFilterRequestController(true);
    return () => options.setFilterParams({});
  }, []);

  const openEditModal = (data: freightItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setFreightItem(data);
  };

  const allFields = [
    { validate: { ...tierValid, value: freightItem.tier }, setValidate: setTierValid },
    {
      validate: { ...destinationValid, value: freightItem.dest ? freightItem.dest : "" },
      setValidate: setDestinationValid,
    },
    { validate: { ...cbmValid, value: freightItem.cbm }, setValidate: setCbmValid },
    { validate: { ...mtValid, value: freightItem.mt }, setValidate: setMtValid },
  ];

  const closeModal = (): void => {
    setFreightItem(freightItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const getAllDestOptions = async () => {
    const response = await generalCRUD.getSimpleList("value/cartage/uniq_destination");
    if (response.code === 200) {
      setDestOptions(response.data.items);
    }
  };

  const setSelectFilterOptions = (response) => {
    setDestFilterOptions(
      Array.from(
        new Set(
          response.data.map((el: { dest: any }) => {
            return el.dest;
          }),
        ),
      ),
    );
    setTierOptions(
      response.data.map((item: any) => item.tier).filter((value, index, self) => self.indexOf(value) === index),
    );
  };

  const getAllFreight = async (setOptions?: boolean, sort: sortColumnType = sortedColumn) => {
    const filter_string = _.isEmpty(filterParams) ? "" : `&${queryString.stringify(filterParams)}`;
    setIsLoading(true);
    const response = await generalCRUD.getAllData("value/freight", undefined, sort, undefined, filter_string);
    if (response.code === 200) {
      setFreightList(response.data);
      setOptions && setSelectFilterOptions(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    filterRequestController && getAllFreightByFilter();
  }, [filterParams]);

  const getAllFreightByFilter = async () => {
    const filter_string = _.isEmpty(filterParams) ? "" : `&${queryString.stringify(filterParams)}`;
    setIsLoading(true);
    const response = await generalCRUD.getAllData("value/freight", undefined, sortedColumn, undefined, filter_string);
    if (response.code === 200) {
      setFreightList(response.data);
    }
    setIsLoading(false);
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    setIsLoading(true);
    closeModal();
    let response;
    const freightDataServer = {
      ...freightItem,
      tier: Number(freightItem.tier),
      cbm: parseFloat(freightItem.cbm),
      mt: parseFloat(freightItem.mt),
    };
    if (forEdit) {
      response = await generalCRUD.editConcreteData("value/freight", freightItem.id, freightDataServer);
    } else {
      response = await generalCRUD.addNewData("value/freight", freightDataServer);
    }
    if (response.code === 200) {
      handleSuccess();
      return setIsLoading(false);
    }
    setIsLoading(false);
    return toastr.error("Error", response.message);
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllFreight(true);
      toastr.success("New Freight was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<freightItemType> = [...freightList];
      newData[index] = {
        ...freightItem,
        cbm: parseFloat(freightItem.cbm).toString(),
        mt: parseFloat(freightItem.mt).toString(),
      };
      setFreightList(newData);
      toastr.success("Your Freight was edited successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, true);
    }
  };

  const textChangeHandler = (event: React.ChangeEvent<{ value: unknown }>, field: string, num?: boolean | string) => {
    let value = event.target.value as string;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    if (field === "cbm" || field === "mt") {
      value = validateFloatNumber(value, 2);
    }
    const newData = {
      ...freightItem,
      [field]: value,
    };
    if (field === "dest") {
      const valueForValidation = value !== "0" ? value : "";
      validateField(valueForValidation, destinationValid, setDestinationValid);
    }
    setFreightItem(newData);
  };

  const defineThClassName = (columnName: string): string => {
    if (sortedColumn.name === columnName) {
      return sortedColumn.type === "asc" ? "sort-icons active-desc" : "sort-icons active-asc";
    }
    return "sort-icons";
  };

  const sortFreightHandler = (name: string) => {
    let type = "asc";
    if (sortedColumn.name === name) {
      type = sortedColumn.type === "asc" ? "desc" : "asc";
    }
    const sortData = {
      type: type,
      name: name,
    };
    options.setSortedColumn(sortData);
    getAllFreight(false, sortData);
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Tier</p>
          <div>
            <TextField
              helperText={tierValid.error}
              error={tierValid.valid === false}
              value={freightItem.tier}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: tierValid.maxLength } }}
              type="text"
              placeholder="Tier"
              onChange={(event) => textChangeHandler(event, "tier", true)}
              onBlur={(e) => validateField(e.target.value, tierValid, setTierValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Destination</p>
          <div>
            <FormControl className="general-select">
              <Select
                value={freightItem.dest ? freightItem.dest : "0"}
                error={destinationValid.valid === false}
                onChange={(event) => textChangeHandler(event, "dest")}
              >
                <MenuItem className="no-value" value="0" disabled>
                  Choose what you need
                </MenuItem>
                {destOptions.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {destinationValid.valid === false && <div className="freight-err-text">This field cannot be empty</div>}
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit half">
          <p className="sub-title">Cbm</p>
          <div>
            <TextField
              helperText={cbmValid.error}
              error={cbmValid.valid === false}
              value={freightItem.cbm}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: cbmValid.maxLength } }}
              type="text"
              placeholder="Cbm"
              onChange={(event) => textChangeHandler(event, "cbm", "dec")}
              onBlur={(e) => validateField(e.target.value, cbmValid, setCbmValid)}
            />
          </div>
        </div>
        <div className="unit half">
          <p className="sub-title">Mt</p>
          <div>
            <TextField
              helperText={mtValid.error}
              error={mtValid.valid === false}
              value={freightItem.mt}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: mtValid.maxLength } }}
              type="text"
              placeholder="Mt"
              onChange={(event) => textChangeHandler(event, "mt", "dec")}
              onBlur={(e) => validateField(e.target.value, mtValid, setMtValid)}
            />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="freight" className="page-container">
        <TopPartPage title="Freight" buttonTitle="add new freight" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th tier sortable">
              <div style={{ display: "flex" }} onClick={() => sortFreightHandler("tier")}>
                <div className={defineThClassName("tier")}>&nbsp;</div>
                Tier
              </div>
              <TableSelect type={"tier"} selectOptions={tierOptions} />
            </div>
            <div className="th dest sortable">
              <div style={{ display: "flex" }} onClick={() => sortFreightHandler("dest")}>
                <div className={defineThClassName("dest")}>&nbsp;</div>
                Dest
              </div>
              <TableSelect type={"dest"} selectOptions={destFilterOptions} />
            </div>
            <div className="th normal">Cbm</div>
            <div className="th normal">Mt</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {freightList &&
            freightList.map((item: freightItemType, index) => {
              return (
                <li className="table-content-item" key={index}>
                  <div className="th first">{item.id}</div>
                  <div className="th tier">{item.tier}</div>
                  <div className="th normal">{item.dest}</div>
                  <div className="th normal">{item.cbm}</div>
                  <div className="th normal">{item.mt}</div>
                  <div className="th last">
                    <span onClick={() => openEditModal(item, index)}>Edit</span>
                  </div>
                </li>
              );
            })}
        </ul>
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add freight"
          classes="freight-modal"
          description="You can add new Freight"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit freight"
          classes="freight-modal"
          description="You can edit your Freight"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(FreightContainer);
