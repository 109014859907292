import React, { FC, SyntheticEvent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Styles
import "./style.scss";
import { FieldType } from "../../../../validator/types";
import { maxLengthInstance, regularInstance } from "../../../../validator/Fields/instantLCLQuote";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../../validator/Fields";

type orderDetailsType = {
  orderData: orderDataType;
};

const Shipment: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);

  /*Validation*/
  const [shipmentOrderVal, setShipmentOrderVal] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 90, "Invalid field. You can only use letter, number and dashes"),
    value: orderData.shipmentPurchase,
  });
  const [suppliersNumberVal, setSuppliersNumberVal] = useState<FieldType>({
    ...regularInstance("(^[a-zA-Z1-9-]d*)|(0$)!", 50, "Invalid field. You can only use letter, number and dashes"),
    value: orderData.suppliersInvoiceNumber,
  });
  const [shipDescriptionVal, setShipDescriptionVal] = useState<FieldType>({ ...maxLengthInstance(280, false) });
  const [otherReferenceVal, setOtherReferenceVal] = useState<FieldType>({
    ...maxLengthInstance(25, false),
    value: orderData.otherReferenceUsed,
  });
  const [dateValid, setDateValid] = useState<boolean>(true);

  const dispatch = useDispatch();

  function fromUnixToData(unixTime) {
    const a = new Date(unixTime * 1000);
    const year = a.getFullYear();
    const month = a.getMonth() + 1;
    const date = a.getDate();
    return `${month < 10 ? "0" + month : month}/${date < 10 ? "0" + date : date}/${year}`;
  }

  const handleDateChange = (date) => {
    const newData = {
      ...orderData,
      date: parseInt((new Date(date).getTime() / 1000).toFixed(0)).toString(),
    };
    if (date == "Invalid Date") {
      setDateValid(false);
    } else {
      setDateValid(true);
    }
    setOrderData(newData);
  };

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const allFields = [
    { validate: shipmentOrderVal, setValidate: setShipmentOrderVal },
    { validate: shipDescriptionVal, setValidate: setShipDescriptionVal },
  ];

  const canselHendler = () => {
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "" }));
      }
    });
    if (!dateValid) setDateValid(true);
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields(allFields)) {
      sendRequest(orderData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id: any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1) : setNotify(0);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="shipment">
      <div className="tab-header">
        <div className="tab-header-title">SHIPMENT</div>
        <div onClick={editHendler} className={!lineEditing ? "show" : "hide"}>
          Edit
        </div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="tab-item textarea-wrapper">
              <div className="title-summary">Description of the goods:</div>
              <div className="description-summary">
                {lineEditing ? (
                  <TextField
                    className="field-multiline"
                    helperText={shipDescriptionVal.error}
                    error={shipDescriptionVal.valid === false}
                    value={orderData.shipmentDescription}
                    ref={inputRef}
                    multiline
                    rows="4"
                    InputProps={{ disableUnderline: true, inputProps: { maxLength: shipDescriptionVal.maxLength } }}
                    type="text"
                    onChange={(event) => textChangeHandler(event, "shipmentDescription")}
                    onBlur={(e) => validateField(e.target.value, shipDescriptionVal, setShipDescriptionVal)}
                  />
                ) : (
                  <div className="value">{orderData.shipmentDescription}</div>
                )}
              </div>
            </div>
            <div className="tab-item">
              <div className="title-summary">
                PO number or other reference used during order placement with supplier:
              </div>
              <div className="description-summary">
                {lineEditing ? (
                  <TextField
                    helperText={shipmentOrderVal.error}
                    error={shipmentOrderVal.valid === false}
                    value={orderData.shipmentPurchase}
                    ref={inputRef}
                    InputProps={{ disableUnderline: true, inputProps: { maxLength: shipmentOrderVal.maxLength } }}
                    type="text"
                    onChange={(event) => textChangeHandler(event, "shipmentPurchase")}
                    onBlur={(e) => validateField(e.target.value, shipmentOrderVal, setShipmentOrderVal)}
                  />
                ) : (
                  <div className="value">{orderData.shipmentPurchase}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
            control={
              <Checkbox checked={notify === 1} onClick={() => handleToggleNotify(notify)} className="checkbox-notify" />
            }
            label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(Shipment);
