import store from "../redux/store";
import { app as appApi } from "../api/app";

import { appActionType } from "../types/actions/app";
import {toastr} from "react-redux-toastr";

export const app: appActionType = {
  setIsValidToken(value) {
    store.dispatch({
      type: "VALID_TOKEN",
      payload: {
        isValidToken: value,
      },
    });
  },

  setIsLoadedPage(value) {
    store.dispatch({
      type: "LOADED_PAGE",
      payload: {
        isLoadedPage: value,
      },
    });
  },

  setIsOpenMenu(value) {
    store.dispatch({
      type: "OPEN_MENU",
      payload: {
        isOpenMenu: value,
      },
    });
  },

  setActiveLink(value) {
    store.dispatch({
      type: "ACTIVE_LINK",
      payload: {
        activeLink: value,
      },
    });
  },
  setCurrentTheme(value) {
    store.dispatch({
      type: "THEME_SWITCHER",
      payload: {
        currentTheme: value,
      },
    });
  },

  async uploadImage(data) {
    const token = localStorage.getItem("token");
    try {
      const response = await appApi.uploadImage(token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async getEditorImages() {
    const token = localStorage.getItem("token");
    try {
      const response = await appApi.getEditorImages(token);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async changeArticlesOrder(data) {
    const token = localStorage.getItem("token");
    try {
      const response = await appApi.changeArticlesOrder(token, data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }
  },
  async openLivePreview(setter, content) {
    setter({
      status: true,
      content: "",
    });
    const response = await this.getLivePreviewContent(content);
    if (response.status === 200) {
      setter({
        status: true,
        content: response.data,
      });
    } else {
      toastr.error("Error", response.message);
    }
  },
  async getLivePreviewContent(data) {
    const token = localStorage.getItem("token");
    try {
      const response = await appApi.getLivePreviewContent(token, data);
      if (response.status === 200) {
        return response;
      }
    } catch (e) {
      return e.response.data;
    }
  },
};
