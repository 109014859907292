import React, { FC, SyntheticEvent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Styles
import "./style.scss";
import {FieldType} from "../../../../validator/types";
import {maxLengthInstance} from "../../../../validator/Fields/instantLCLQuote";
import {validateAllFields, validateField} from "../../../../validator/Fields";

type orderDetailsType = {
  orderData: orderDataType;
};

const AdditionalInformation: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);

  /*Validate*/
  const [additionalInfoValid, setAdditionalInfoValid] = useState<FieldType>({...maxLengthInstance(255, false), value: orderData.additionalInformation});

  const dispatch = useDispatch();

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };


  const canselHendler = () => {
    if (!additionalInfoValid.valid) {
      setAdditionalInfoValid(prevState => ({...prevState, valid: null, error: ""}))
    }
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields([{validate: additionalInfoValid, setValidate: setAdditionalInfoValid}])) {
      sendRequest(orderData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id:any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1 ) : setNotify(0 );
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="additional-information">
      <div className="tab-header">
        <div className="tab-header-title">ADDITIONAL INFORMATION OR REQUESTS</div>
        <div onClick={editHendler} className={!lineEditing ? "show": "hide"}>Edit</div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item textarea-wrapper">
            <div className="title-summary">Additional Information:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={additionalInfoValid.error}
                  error={additionalInfoValid.valid === false}
                  id="standard-multiline-static"
                  multiline
                  rows={6}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: additionalInfoValid.maxLength} }}
                  ref={inputRef}
                  value={orderData.additionalInformation}
                  onChange={(event) => textChangeHandler(event, "additionalInformation")}
                  onBlur={(e) => validateField(e.target.value, additionalInfoValid, setAdditionalInfoValid)}
                />
              ) : (
                <div className="value">{orderData.additionalInformation}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
              control={<Checkbox
                  checked={notify === 1}
                  onClick={() => handleToggleNotify(notify)}
                  className="checkbox-notify"
              />}
              label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(AdditionalInformation);
