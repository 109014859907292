import {FieldType} from "../types";
import {defaultValidationProperties} from "./index";


export const maxLengthInstance = (length: number, required: boolean = true): FieldType => {
    if (required) {
        return {
            ...defaultValidationProperties,
            maxLength: length,
            dependencies: [{depCase: 'required'}, {depCase: 'maxLength', attr: {maxLength: length}}],
        }
    }
    return {
        ...defaultValidationProperties,
        maxLength: length,
        dependencies: [{depCase: 'maxLength', attr: {maxLength: length}}],
    }
}

export const requireInstance: FieldType = {
    ...defaultValidationProperties,
    dependencies: [{depCase: 'required'}],
}

export const regularInstance = (regex: string, length: number, text: string, required: boolean = false): FieldType => {
    if (required) {
        return {
            ...defaultValidationProperties,
            maxLength: length,
            dependencies: [{depCase: 'required'}, {depCase: 'regular', attr: {regEx: regex, customError: text}}, {depCase: 'maxLength', attr: {maxLength: length}}]
        }
    } else {
        return {
            ...defaultValidationProperties,
            maxLength: length,
            dependencies: [{depCase: 'regular', attr: {regEx: regex, customError: text}}, {depCase: 'maxLength', attr: {maxLength: length}}]
        }
    }
}

