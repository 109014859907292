import React, { FC, SyntheticEvent, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { generalCRUD } from "../../../../actions/generalCRUD";

// Components
import { orderDataType } from "../../../../types/actions/orders";
import { toastr } from "react-redux-toastr";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Styles
import "./style.scss";
import {FieldType} from "../../../../validator/types";
import {maxLengthInstance} from "../../../../validator/Fields/instantLCLQuote";
import {emailField} from "../../../../validator/Fields/quoteRequest";
import {validateAllFields, validateField} from "../../../../validator/Fields";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

type orderDetailsType = {
  orderData: orderDataType;
};

const Shipper: FC<any> = (props: orderDetailsType) => {
  const [lineEditing, setLineEditing] = useState<boolean>(false);
  const [notify, setNotify] = useState(0);
  const [orderData, setOrderData] = useState<orderDataType>(props.orderData);

  /*Validation*/
  const [companyNameValid, setCompanyNameValid] = useState<FieldType>({...maxLengthInstance(100), value: orderData.companyNameShipper});
  const [companyAddrValid, setCompanyAddrValid] = useState<FieldType>({...maxLengthInstance(100), value: orderData.companyAddressShipper});
  const [personCharge, setPersonCharge] = useState<FieldType>({...maxLengthInstance(50), value: orderData.namePersonCharge});
  const [emailValid, setEmailValid] = useState<FieldType>({...emailField, value: orderData.emailShipper});
  const [phoneValid, setPhoneValid] = useState<FieldType>({...maxLengthInstance(25), value: orderData.phoneShipper});
  const [addInfoValid, setAddInfoValid] = useState<FieldType>({...maxLengthInstance(100, false), value: orderData.additionalInformationShipper});

  const dispatch = useDispatch();

  const editHendler = () => {
    if (lineEditing) {
      setOrderData(props.orderData);
    }
    setLineEditing(!lineEditing);
  };

  const allFields = [
    {validate: companyNameValid, setValidate: setCompanyNameValid},
    {validate: companyAddrValid, setValidate: setCompanyAddrValid},
    {validate: personCharge, setValidate: setPersonCharge},
    {validate: emailValid, setValidate: setEmailValid},
    {validate: phoneValid, setValidate: setPhoneValid},
    {validate: addInfoValid, setValidate: setAddInfoValid},
  ]

  const canselHendler = () => {
    allFields.forEach(field => {
      if (!field.validate.valid) {
        field.setValidate(prevState => ({...prevState, valid: null, error: ""}))
      }
    })
    setOrderData(props.orderData);
    setLineEditing(!lineEditing);
  };

  const saveEntity = async () => {
    if (validateAllFields(allFields)) {
      const newData = {
        ...orderData,
        phoneShipper: orderData.phoneShipper.replace("+", ""),
      };
      sendRequest(newData, orderData.id + "?notify=" + notify);
    }
  };

  const sendRequest = async (data: any, id:any) => {
    const response = await generalCRUD.editUserData("request/booking/edit/", data, id);
    if (response.code === 200) {
      setLineEditing(!lineEditing);
      toastr.success("Shipment information was edited successfully", "");
      dispatch({
        type: "ORDER_DATA",
        payload: {
          orderData: response.data,
        },
      });
    } else {
      return toastr.error("Error", response.message);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;
    const newData = {
      ...orderData,
      [key]: value,
    };
    setOrderData(newData);
  };

  const handleToggleNotify = (notify: number): void => {
    notify === 0 ? setNotify(1 ) : setNotify(0 );
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div id="shipper">
      <div className="tab-header">
        <div className="tab-header-title">SHIPPER</div>
        <div onClick={editHendler} className={!lineEditing ? "show": "hide"}>Edit</div>
      </div>
      <div className="border">
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">Company name:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={companyNameValid.error}
                  error={companyNameValid.valid === false}
                  value={orderData.companyNameShipper}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: companyNameValid.maxLength} }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "companyNameShipper")}
                  onBlur={(e) => validateField(e.target.value, companyNameValid, setCompanyNameValid)}
                />
              ) : (
                <div className="value">{orderData.companyNameShipper}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Company address:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={companyAddrValid.error}
                  error={companyAddrValid.valid === false}
                  value={orderData.companyAddressShipper}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: companyAddrValid.maxLength} }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "companyAddressShipper")}

                  onBlur={(e) => validateField(e.target.value, companyAddrValid, setCompanyAddrValid)}
                />
              ) : (
                <div className="value">{orderData.companyAddressShipper}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Name of the person in charge:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={personCharge.error}
                  error={personCharge.valid === false}
                  value={orderData.namePersonCharge}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: personCharge.maxLength} }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "namePersonCharge")}
                  onBlur={(e) => validateField(e.target.value, personCharge, setPersonCharge)}
                />
              ) : (
                <div className="value">{orderData.namePersonCharge}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-wrapper active" : "tab-wrapper"}>
          <div className="tab-item">
            <div className="title-summary">E-mail:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={emailValid.error}
                  error={emailValid.valid === false}
                  value={orderData.emailShipper}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: emailValid.maxLength} }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "emailShipper")}
                  onBlur={(e) => validateField(e.target.value, emailValid, setEmailValid)}
                />
              ) : (
                <div className="value">{orderData.emailShipper}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Phone:</div>
            <div className="description-summary">
              {lineEditing ? (
                <NumberFormat
                  customInput={TextField}
                  prefix={'+'}
                  helperText={phoneValid.error}
                  error={phoneValid.valid === false}
                  value={orderData.phoneShipper}
                  className="base-input"
                  inputProps={{ maxLength: phoneValid.maxLength }}
                  placeholder="e.g. + 86 10 6532 9985"
                  allowNegative={false}
                  onChange={(event) => textChangeHandler(event, "phoneShipper")}
                  onBlur={(e) => validateField(e.target.value, phoneValid, setPhoneValid)}
                />
              ) : (
                <div className="value">{orderData.phoneShipper.substr(0, 1) === '+' ? '': '+'}{orderData.phoneShipper}</div>
              )}
            </div>
          </div>
          <div className="tab-item">
            <div className="title-summary">Additional information:</div>
            <div className="description-summary">
              {lineEditing ? (
                <TextField
                  helperText={addInfoValid.error}
                  error={addInfoValid.valid === false}
                  value={orderData.additionalInformationShipper}
                  ref={inputRef}
                  InputProps={{ disableUnderline: true, inputProps: {maxLength: emailValid.maxLength} }}
                  type="text"
                  onChange={(event) => textChangeHandler(event, "additionalInformationShipper")}
                  onBlur={(e) => validateField(e.target.value, addInfoValid, setAddInfoValid)}
                />
              ) : (
                <div className="value">{orderData.additionalInformationShipper}</div>
              )}
            </div>
          </div>
        </div>
        <div className={lineEditing ? "tab-footer show" : "tab-footer"}>
          <FormControlLabel
              control={<Checkbox
                  checked={notify === 1}
                  onClick={() => handleToggleNotify(notify)}
                  className="checkbox-notify"
              />}
              label="Notify user"
          />
          <button className="main-btn cancel" onClick={() => canselHendler()}>
            Cancel
          </button>
          <button className="main-btn submit" onClick={() => saveEntity()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(Shipper);
