import React, { useEffect, useState, FC } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { generalCRUD } from "../../../actions/generalCRUD";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import "./style.scss";

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
});

type cartageItemType = {
  cartageId: number;
  cartageName: string;
};
const cartageItemInitial = {
  cartageId: 0,
  cartageName: "",
};

type depotItemType = {
  depotId: number;
  depotName: string;
};
const depotItemInitial = {
  depotId: 0,
  depotName: "",
};

interface DeliveryList {
  [key: string]: {
    [index: string]: number;
  };
}

const DeliveryTimeContainer: FC = (props) => {
  const [cartageList, setCartageList] = useState<[cartageItemType]>([cartageItemInitial]);

  const [deliveryList, setDeliveryList] = useState<DeliveryList>({});
  const [tempDeliveryList, setTempDeliveryList] = useState<DeliveryList>({});

  const [depotList, setDepotList] = useState<[depotItemType]>([depotItemInitial]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const classes = useStyles();
  useEffect(() => {
    getAllDelivery();
  }, []);

  useEffect(() => {
    changeBtnStatus();
  }, [tempDeliveryList]);

  const saveTime = () => {
    setIsLoading(true);

    const collection: any = document.getElementsByClassName("input-delivery");
    const arrTime: any = [];
    const objTime: any = {
      data: arrTime,
    };

    for (const item of collection) {
      const value = (item as HTMLInputElement).value;
      if (value) {
        const obj: any = {};
        obj["depot"] = item.dataset.depot;
        obj["cartage"] = item.dataset.cartage;
        obj["delivery"] = value;
        arrTime.push(obj);
      }
    }
    sendRequest(JSON.stringify(objTime));
    setIsLoading(false);
  };
  const changeBtnStatus = () => {
    const initialDeliveryList = JSON.stringify(deliveryList);
    const currentDeliveryList = JSON.stringify(tempDeliveryList);
    if (initialDeliveryList !== currentDeliveryList) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };
  const getAllDelivery = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("delivery");
    if (response.code === 200) {
      setDeliveryList(response.data.delivery);
      setCartageList(response.data.cartage);
      setDepotList(response.data.depot);
    }
    setIsLoading(false);
  };

  const sendRequest = async (data: any) => {
    const response = await generalCRUD.editDeliveryData("delivery", data);
    if (response.code === 200) {
      toastr.success(" Delivery time was edited successfully", "");
      setDeliveryList(response.data.delivery);
      setDisableButton(true);
    } else {
      return toastr.error("Error", response.message);
    }
  };
  const activityCheck = (event: React.FormEvent<HTMLInputElement>) => {
    const cartage = event.currentTarget.getAttribute("data-cartage");
    const depot = event.currentTarget.getAttribute("data-depot");
    const value = Number(event.currentTarget.value);
    let tempList;
    if (Object.keys(tempDeliveryList).length !== 0) {
      tempList = {
        ...tempDeliveryList,
        [depot]: {
          ...tempDeliveryList[depot],
          [cartage]: value,
        },
      };
    } else {
      tempList = {
        ...deliveryList,
        [depot]: {
          ...deliveryList[depot],
          [cartage]: value,
        },
      };
    }
    if (value === 0 || !value) {
      delete tempList[depot][cartage];
      if (Object.keys(tempList[depot]).length === 0) {
        delete tempList[depot];
      }
    }
    setTempDeliveryList(tempList);
  };

  const deliveryInputHandler = (event) => {
    if (event.currentTarget.value > 1000) {
      event.currentTarget.value = "1000";
    }
  };

  const buildRow = () => {
    const table: any = [];
    for (let d = 0; d < depotList.length; d++) {
      const row: any = [];
      for (let c = 0; c < cartageList.length + 1; c++) {
        if (c === 0) {
          row.push(<TableCell key={depotList[d].depotName}>{depotList[d].depotName}</TableCell>);
        } else {
          let flag = false;
          for (const key in deliveryList) {
            for (const id in deliveryList[key]) {
              // @ts-ignore
              if (depotList[d]?.depotId === Number(key) && cartageList[c - 1]?.cartageId === Number(id)) {
                flag = true;
                row.push(
                  <TableCell key={d + c}>
                    <input
                      data-cartage={cartageList[c - 1]?.cartageId}
                      data-depot={depotList[d].depotId}
                      type="number"
                      step="1"
                      min="0"
                      className="input-delivery"
                      defaultValue={deliveryList[depotList[d].depotId][cartageList[c - 1]?.cartageId]}
                      onInput={(e) => deliveryInputHandler(e)}
                      onChange={(event: React.FormEvent<HTMLInputElement>) => activityCheck(event)}
                    />
                  </TableCell>,
                );
              }
            }
          }
          if (!flag) {
            row.push(
              <TableCell key={c + depotList[d].depotName + d}>
                <input
                  data-cartage={cartageList[c - 1]?.cartageId}
                  data-depot={depotList[d].depotId}
                  type="number"
                  step="1"
                  min="0"
                  className="input-delivery"
                  onChange={(event: React.FormEvent<HTMLInputElement>) => activityCheck(event)}
                />
              </TableCell>,
            );
          }
          flag = false;
        }
      }
      table.push(<TableRow key={depotList[d].depotName + d}>{row}</TableRow>);
    }
    return table;
  };

  return (
    <MainTemplate>
      <div id="deliveryTime" className="page-container">
        <TopPartPage
          classes={disableButton ? "disable" : ""}
          title="DELIVERY TIME, IN DAYS"
          buttonTitle="Apply"
          handleClick={() => saveTime()}
        />
        {
          <TableContainer className="custom-table-wrapper">
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="car">Cartages</div>
                    <div className="dep">Depots</div>
                  </TableCell>
                  {cartageList.map((item: cartageItemType, index) => {
                    return (
                      <TableCell key={index} data-cartage-id={item.cartageId}>
                        {item.cartageName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="delivery-time-list">{buildRow()}</TableBody>
            </Table>
            {isLoading && <LoaderLocal />}
          </TableContainer>
        }
      </div>
    </MainTemplate>
  );
};

const mapStateToProps = function (state: any) {
  return {
    app: state.app,
  };
};

export default connect(mapStateToProps)(withRouter(DeliveryTimeContainer));
