/*
  Action on api
  https://github.com/public-apis/public-apis
*/
import axios from "axios";
import { CONSTS } from "../config/objectConst";

import { ordersApiType } from "../types/api/orders";

export const orders: ordersApiType = {
  searchOrder: async (code) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/search?tracking_code=${code}`,
      method: "get",
    });
  },
  saveColumnSettings: async (data, token) => {
    return await axios({
      url: CONSTS.API.url + `admin/settings/column/orders`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getOrderInfo: async (id) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/get/${id}`,
      method: "get",
    });
  },
  editOrderInfo: async (id, token, data) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/edit/${id}`,
      method: "put",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  getInstantQuotes: async (id, token) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/get/user/${id}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  setNextEventStatus: async (token, data) => {
    return await axios({
      url: CONSTS.API.url + `request/event/data/add`,
      method: "post",
      headers: {
        "user-token": token,
      },
      data: data,
    });
  },
  resendRequest: async (id, token) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/resend/${id}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  getPreviewContent: async (id, booking_request_id, token) => {
    return await axios({
      url: CONSTS.API.url + `template/email/${id}/preview/${booking_request_id}`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
  uploadFiles: async (id, token, data) => {
    return await axios({
      url: CONSTS.API.url + `admin/document/upload/${id}`,
      method: "post",
      headers: {
        "user-token": token,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  },
  getDocExchange: async (id, token) => {
    return await axios({
      url: CONSTS.API.url + `request/booking/${id}/document_exchange`,
      method: "get",
      headers: {
        "user-token": token,
      },
    });
  },
};
