import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import { useSelector } from "react-redux";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import CustomPagination from "../../organisms/CustomPagination";

// Styles
import "./style.scss";

import { rootReducerType } from "../../../redux/reducers";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../validator/Fields";

export type portsItemType = {
  id: number;
  code: string;
  name: string;
  [key: string]: string | number;
};

export const portsItemInitial = {
  id: 1,
  code: "",
  name: "",
};

type editModalType = {
  status: boolean;
  index: number;
};

const PortsContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [portsList, setPortsList] = useState<Array<portsItemType>>([]);
  const [portsItem, setPortsItem] = useState<portsItemType>(portsItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(150) });
  const [codeValid, setCodeValid] = useState<FieldType>({ ...maxLengthInstance(50) });

  // to get all entities
  useEffect(() => {
    getAllPorts(1);
  }, []);

  const openEditModal = (data: portsItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setPortsItem(data);
  };

  const allFields = [
    { validate: { ...nameValid, value: portsItem.name }, setValidate: setNameValid },
    { validate: { ...codeValid, value: portsItem.code }, setValidate: setCodeValid },
  ];

  const closeModal = (): void => {
    setPortsItem(portsItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      if (!field.validate.valid) {
        field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
      }
    });
  };

  const getAllPorts = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("port", page, undefined, quantity);
    if (response.code === 200) {
      setPortsList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllPorts(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    let valid = true;
    for (const key in portsItem) {
      if (key === "id") continue;
      if (!portsItem[key]) {
        valid = false;
        break;
      }
    }
    if (valid) {
      setIsLoading(true);
      closeModal();
      let response;
      if (forEdit) {
        response = await generalCRUD.editConcreteData("port", portsItem.id, portsItem);
      } else {
        response = await generalCRUD.addNewData("port", portsItem);
      }
      if (response.code === 200) {
        handleSuccess();
        return setIsLoading(false);
      }
      setIsLoading(false);
      return toastr.error(
        "Error",
        response.message === "Entity error." ? "Port with this code ID already exists" : response.error,
      );
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllPorts(paginationSettings.page);
      toastr.success("New Port was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<portsItemType> = [...portsList];
      newData[index] = { ...portsItem };
      setPortsList(newData);
      toastr.success("Port was edited successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess, true);
    }
  };

  const textChangeHandler = (event: SyntheticEvent, field: string, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...portsItem,
      [field]: value,
    };
    setPortsItem(newData);
  };
  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Port name</p>
          <p>
            <TextField
              helperText={nameValid.error}
              error={nameValid.valid === false}
              value={portsItem.name}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
              type="text"
              placeholder="Name"
              onChange={(event) => textChangeHandler(event, "name")}
              onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
            />
          </p>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Code ID</p>
          <p>
            <TextField
              helperText={codeValid.error}
              error={codeValid.valid === false}
              value={portsItem.code}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: codeValid.maxLength } }}
              type="text"
              placeholder="Code ID"
              onChange={(event) => textChangeHandler(event, "code")}
              onBlur={(e) => validateField(e.target.value, codeValid, setCodeValid)}
            />
          </p>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="ports" className="page-container">
        <TopPartPage title="ports" buttonTitle="add new port" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Code ID</div>
            <div className="th">Name</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {portsList.map((item: portsItemType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="middle-part">
                  <div className="th">{item.code}</div>
                  <div className="th">{item.name}</div>
                </div>
                <div className="th last">
                  <span onClick={() => openEditModal(item, index)}>Edit</span>
                </div>
              </li>
            );
          })}
        </ul>
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={portsList.length}
          handleClickPage={(value) => getAllPorts(value)}
          handleClickQuantity={(quantity) => getAllPorts(paginationSettings.page, quantity)}
        />
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          classes="ports-modal"
          title="Add new port"
          description="You can add new Port"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit port"
          classes="ports-modal"
          description="You can edit Port"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(PortsContainer);
