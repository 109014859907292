import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
// actions
import { options } from "../../../../actions/options";
// icons
import iconArrow from "../../../../assets/images/select-arrow-2.svg";
// types
import { rootReducerType } from "../../../../redux/reducers";

interface TableSelectType {
  type: string;
  selectOptions: Array<string>;
}

const TableSelect: React.FC<TableSelectType> = ({ type, selectOptions }: TableSelectType) => {
  const [nameValue, setNameValue] = useState<string>("");
  const filterParams = useSelector((state: rootReducerType) => state.clients.filterParams);

  const onChange: any = (event: any) => {
    setNameValue(event.target.value);
    if (event.target.value === "none") {
      const newState: any = Object.assign({}, filterParams);
      delete newState[type];
      return options.setFilterParams(newState);
    }
    options.setFilterParams({ ...filterParams, [type]: event.target.value });
  };

  return (
    <div className="table-select">
      <img className="table-select__img" src={iconArrow} alt="icon-arrow" />
      <Select
        className="table-select__select"
        value={"0"}
        disableUnderline
        onChange={onChange}
        IconComponent={() => null}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem className="no-value" value="0" disabled>
          Select{nameValue !== "none" && nameValue ? `: ${nameValue}` : ""}
        </MenuItem>
        <MenuItem value="none">None</MenuItem>
        {selectOptions &&
          selectOptions.map((item: any, index: number) => {
            return (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
};

export default TableSelect;
