import React, { useState, useEffect, FC } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch/Switch";

// Styles
import "./style.scss";

type mailAddressType = {
  id: number | null;
  name: string;
  email: string;
  send: boolean;
};

const mailItemInitial = {
  id: null,
  name: "",
  email: "",
  send: false,
};

type editModalType = {
  status: boolean;
  index: number;
};

const MailSettingsContainer: FC = () => {
  const [mailList, setMailList] = useState<Array<mailAddressType>>([]);
  const [mailItem, setMailItem] = useState<mailAddressType>(mailItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteMailId, setDeleteMailId] = useState<number>(0);
  const [anchorElMail, setAnchorElMail] = React.useState<Array<null | HTMLElement>>([null]);

  // to get all entities
  useEffect(() => {
    getAllMail();
  }, []);

  // to set menu anchors properly
  useEffect(() => {
    if (mailList.length) {
      const anchorArr = [];
      mailList.forEach(() => {
        anchorArr.push(null);
      });
      setAnchorElMail(anchorArr);
    }
  }, [mailList]);

  const getAllMail = async () => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("admin/email", 1, undefined, 1000);
    if (response.code === 200) {
      setMailList(response.data);
    }
    setIsLoading(false);
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    if (mailItem.email && !helpFunctions.validateEmail(mailItem.email))
      return toastr.error("Error", "Email address is not correct");
    setIsLoading(true);
    closeModal();
    let response;
    if (forEdit) {
      response = await generalCRUD.editConcreteData("admin/email", mailItem.id, mailItem);
    } else {
      response = await generalCRUD.addNewData("admin/email", mailItem);
    }
    if (response.code === 200) {
      handleSuccess();
      return setIsLoading(false);
    }
    setIsLoading(false);
    return toastr.error("Error", response.message);
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllMail();
      toastr.success("New Address was added successfully", "");
    };
    sendRequest(handleSuccess);
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<mailAddressType> = [...mailList];
      newData[index] = { ...mailItem };
      setMailList(newData);
      toastr.success("Address was edited successfully", "");
    };
    sendRequest(handleSuccess, true);
  };

  const deleteMail = async () => {
    const id = deleteMailId;
    setDeleteMailId(0);
    const response = await generalCRUD.deleteData("admin/email", id);
    if (response.code === 200) {
      toastr.success(`Address was deleted successfully`, "");
      const newDataList: Array<mailAddressType> = mailList.filter((item: mailAddressType) => {
        return item.id !== id;
      });
      setMailList(newDataList);
    }
  };

  const textChangeHandler = (value: string | boolean, field: string) => {
    const newData = {
      ...mailItem,
      [field]: value,
    };
    setMailItem(newData);
  };

  const changeSendSwitcher = async (value: boolean, id: number, index: number) => {
    setIsLoading(true);
    const newData = { ...mailList[index], send: value };
    const response = await generalCRUD.editConcreteData("admin/email", id, newData);
    if (response.code === 200) {
      toastr.success(`Address settings have been changed successfully`, "");
      const newList = [...mailList];
      newList[index] = newData;
      setMailList(newList);
    } else {
      toastr.error("Error", response.message);
    }
    setIsLoading(false);
  };

  const openEditModal = (data: mailAddressType, index: number): void => {
    handleActionMenu(null, index);
    setEditModal({
      status: true,
      index: index,
    });
    setMailItem(data);
  };

  const closeModal = (): void => {
    setMailItem(mailItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
  };

  const openDeleteModal = (id: number, index: number) => {
    handleActionMenu(null, index);
    setDeleteMailId(id);
  };

  const handleActionMenu = (value, index: number) => {
    const anch = [...anchorElMail];
    anch[index] = value;
    setAnchorElMail(anch);
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Name (optional)</p>
          <input
            type="text"
            placeholder="Name"
            value={mailItem.name || ""}
            onChange={(event) => textChangeHandler(event.target.value, "name")}
          />
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Email</p>
          <p>
            <input
              type="text"
              placeholder="Email"
              value={mailItem.email}
              onChange={(event) => textChangeHandler(event.target.value, "email")}
            />
          </p>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Send mails to</p>
          <Grid component="label" container alignItems="center">
            <Grid item>No</Grid>
            <Grid item>
              <Switch checked={mailItem.send} onChange={(event) => textChangeHandler(event.target.checked, "send")} />
            </Grid>
            <Grid item>Yes</Grid>
          </Grid>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="mail-settings" className="page-container">
        <TopPartPage title="Mail settings" buttonTitle="add new address" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th first">ID</div>
          <div className="middle-part">
            <div className="th">Name</div>
            <div className="th">Email</div>
            <div className="th send">Send mails to</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {mailList.map((item: mailAddressType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="th">{item.name}</div>
                <div className="th">{item.email}</div>
                <div className="th send">
                  <Grid component="label" container alignItems="center">
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        checked={item.send}
                        onChange={(event) => changeSendSwitcher(event.target.checked, item.id, index)}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </div>
                <div className="th actions last">
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleActionMenu(event.currentTarget, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElMail[index]}
                    keepMounted
                    className="action-menu"
                    open={!!anchorElMail[index]}
                    onClose={() => handleActionMenu(null, index)}
                  >
                    <MenuItem onClick={() => openEditModal(item, index)}>Edit</MenuItem>
                    <MenuItem className="delete" onClick={() => openDeleteModal(item.id, index)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
              </li>
            );
          })}
        </ul>
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          title="Add an address"
          description="You can add an address to receive copies of mails, that were sent to customer"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          title="Edit an address"
          description="You can edit to receive the copies of mails that was sent to customer"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={!!deleteMailId}
          isDeleting={true}
          handleClose={() => setDeleteMailId(0)}
          handleSubmit={deleteMail}
          title="Delete an address"
          description="You can delete address"
        >
          &nbsp;
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(MailSettingsContainer);
