import React, { useEffect, FC, useState, SyntheticEvent } from "react";
import { withRouter } from "react-router-dom";
import { generalCRUD } from "../../../actions/generalCRUD";
import helpFunctions from "../../../tools/helpFunctions";
import { useSelector } from "react-redux";

// Components
import MainTemplate from "../templates/MainTemplate";
import TopPartPage from "../../molecules/TopPartPage";
import ModalWindow from "../../molecules/ModalWindow";
import LoaderLocal from "../../atoms/LoaderLocal";
import { toastr } from "react-redux-toastr";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CustomPagination from "../../organisms/CustomPagination";

// Styles
import "./style.scss";

import { documentType, emailTemplateType } from "../SetEvent";
import { rootReducerType } from "../../../redux/reducers";
import TextField from "@material-ui/core/TextField";
import { validateAllFields, validateField } from "../../../validator/Fields";
import { FieldType } from "../../../validator/types";
import { maxLengthInstance } from "../../../validator/Fields/instantLCLQuote";

export type eventsItemType = {
  id: number;
  name: string;
  publicStatus: string;
  emailTemplate: emailTemplateType;
  document?: documentType;
  [key: string]: string | number | emailTemplateType;
};

export const eventsItemInitial = {
  id: 1,
  name: "",
  publicStatus: "",
  emailTemplate: {
    id: 0,
    title: "",
  },
};

type editModalType = {
  status: boolean;
  index: number;
};

export type templateOptionType = {
  id: string | number;
  title: string;
};

let templateOptions: Array<templateOptionType> = [{ id: "", title: "" }];

const EventsContainer: FC = () => {
  const quantityPerPage = useSelector((state: rootReducerType) => state.app.quantityPerPage);
  const [eventsList, setEventsList] = useState<Array<eventsItemType>>([]);
  const [eventsItem, setEventsItem] = useState<eventsItemType>(eventsItemInitial);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<editModalType>({
    status: false,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    maxPage: 1,
  });

  /*Validation*/
  const [nameValid, setNameValid] = useState<FieldType>({ ...maxLengthInstance(100), value: eventsItem.name });
  const [statusValid, setStatusValid] = useState<FieldType>({
    ...maxLengthInstance(150, false),
    value: eventsItem.publicStatus,
  });

  // to get all entities
  useEffect(() => {
    getAllEvents(1);
    getEmailTemplates();
  }, []);

  const openEditModal = (data: eventsItemType, index: number): void => {
    setEditModal({
      status: true,
      index: index,
    });
    setEventsItem(data);
  };

  const allFields = [
    { validate: nameValid, setValidate: setNameValid },
    { validate: statusValid, setValidate: setStatusValid },
  ];

  const closeModal = (): void => {
    setEventsItem(eventsItemInitial);
    isAddModalOpen
      ? setIsAddModalOpen(false)
      : setEditModal({
          ...editModal,
          status: false,
        });
    allFields.forEach((field) => {
      field.setValidate((prevState) => ({ ...prevState, valid: null, error: "", value: "" }));
    });
  };

  const getAllEvents = async (page: number, quantity = quantityPerPage) => {
    setIsLoading(true);
    const response = await generalCRUD.getAllData("request/event", page, undefined, quantity);
    if (response.code === 200) {
      setEventsList(response.data.items);
      const maxPage = Math.ceil(response.data.totalCount / response.data.numItemsPerPage);
      if (maxPage < page) return getAllEvents(maxPage, quantity);
      setPaginationSettings({
        page: response.data.currentPageNumber,
        maxPage: maxPage,
      });
    }
    setIsLoading(false);
  };

  const getEmailTemplates = async () => {
    const response = await generalCRUD.getAllData("template/email", null, null, 1000, "&system=false");
    if (response.code === 200) {
      templateOptions = response.data.items;
    }
  };

  const sendRequest = async (handleSuccess: () => void, forEdit?: boolean) => {
    if (eventsItem.name) {
      setIsLoading(true);
      closeModal();
      let response;
      if (forEdit) {
        response = await generalCRUD.editConcreteData("request/event", eventsItem.id, eventsItem);
      } else {
        response = await generalCRUD.addNewData("request/event", eventsItem);
      }
      if (response.code === 200) {
        handleSuccess();
        setIsLoading(false);
        return response;
      }
      setIsLoading(false);
      return toastr.error("Error", response.message);
    } else {
      toastr.error("Error", "Not all fields are filled in");
    }
  };

  const addEntity = async () => {
    const handleSuccess = () => {
      getAllEvents(paginationSettings.page);
      toastr.success("New Event was added successfully", "");
    };
    if (validateAllFields(allFields)) {
      sendRequest(handleSuccess);
    }
  };

  const editEntity = (index: number): void => {
    const handleSuccess = () => {
      const newData: Array<eventsItemType> = [...eventsList];
      newData[index] = { ...eventsItem };
      setEventsList(newData);
      toastr.success("Your Event was edited successfully", "");
    };
    sendRequest(handleSuccess, true);
  };

  const textChangeHandler = (event: SyntheticEvent, field: string, num?: boolean | string) => {
    const value = (event.target as HTMLInputElement).value;
    if (num && value.length && !helpFunctions.isNumber(value, num)) return false;
    const newData = {
      ...eventsItem,
      [field]: value,
    };
    setEventsItem(newData);
  };

  const templateHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    let title = "";
    templateOptions.forEach((item) => {
      if (item.id === +event.target.value) return (title = item.title);
    });
    const newData = {
      ...eventsItem,
      emailTemplate: +event.target.value
        ? {
            id: +event.target.value as number,
            title: title,
          }
        : null,
    };
    setEventsItem(newData);
  };

  const modalContent = (
    <>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Event name</p>
          <div>
            <TextField
              helperText={nameValid.error}
              error={nameValid.valid === false}
              value={eventsItem.name}
              className="base-input"
              InputProps={{ disableUnderline: true, inputProps: { maxLength: nameValid.maxLength } }}
              type="text"
              onChange={(event) => textChangeHandler(event, "name")}
              onBlur={(e) => validateField(e.target.value, nameValid, setNameValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Public status (Optional)</p>
          <div>
            <TextField
              helperText={statusValid.error}
              error={statusValid.valid === false}
              value={eventsItem.publicStatus}
              InputProps={{ disableUnderline: true, inputProps: { maxLength: statusValid.maxLength } }}
              type="text"
              className="base-input"
              onChange={(event) => textChangeHandler(event, "publicStatus")}
              onBlur={(e) => validateField(e.target.value, statusValid, setStatusValid)}
            />
          </div>
        </div>
      </div>
      <div className="unit-wrap">
        <div className="unit">
          <p className="sub-title">Email template</p>
          <div>
            <FormControl className="general-select">
              <Select
                value={
                  eventsItem.emailTemplate && eventsItem.emailTemplate.id ? eventsItem.emailTemplate.id.toString() : "0"
                }
                onChange={templateHandler}
              >
                <MenuItem className="no-value" value="0">
                  No email template
                </MenuItem>
                {templateOptions.map((item, index) => {
                  return (
                    <MenuItem value={item.id.toString()} key={index}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MainTemplate>
      <div id="events" className="page-container">
        <TopPartPage title="Events" buttonTitle="add new event" handleClick={() => setIsAddModalOpen(true)} />
        <div className="table-thead">
          <div className="th first">#</div>
          <div className="middle-part">
            <div className="th name">Event name</div>
            <div className="th public">Public status</div>
            <div className="th template">Email template</div>
          </div>
          <div className="th last">Action</div>
        </div>
        <ul className="table-content">
          {eventsList.map((item: eventsItemType, index) => {
            return (
              <li className="table-content-item" key={index}>
                <div className="th first">{item.id}</div>
                <div className="th name">{item.name}</div>
                <div className="th public">{item.publicStatus}</div>
                <div className="th template">{item.emailTemplate ? item.emailTemplate.title : ""}</div>
                <div className="th last">
                  <span onClick={() => openEditModal(item, index)}>Edit</span>
                </div>
              </li>
            );
          })}
        </ul>
        <CustomPagination
          page={paginationSettings.page}
          maxPage={paginationSettings.maxPage}
          isLoaded={!isLoading}
          itemsNumber={eventsList.length}
          handleClickPage={(value) => getAllEvents(value)}
          handleClickQuantity={(quantity) => getAllEvents(paginationSettings.page, quantity)}
        />
        {isLoading && <LoaderLocal />}
        <ModalWindow
          open={isAddModalOpen}
          handleClose={closeModal}
          handleSubmit={addEntity}
          classes="event-modal"
          title="Add event"
          description="You can add new Event"
        >
          {modalContent}
        </ModalWindow>
        <ModalWindow
          open={editModal.status}
          handleClose={closeModal}
          handleSubmit={() => editEntity(editModal.index)}
          classes="event-modal"
          title="Edit event"
          description="You can edit your Event"
        >
          {modalContent}
        </ModalWindow>
      </div>
    </MainTemplate>
  );
};

export default withRouter(EventsContainer);
