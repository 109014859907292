//*** for server path
// const apiUrlPath = "api.integrators.fmc-dev.com";
// const apiUrlPath = "localhost:8000";


// const apiUrlPath = "api.coloader.inboxgear.com";
// const apiUrlPath = "api.dev.coloader.inboxgear.com";

// export const apiUrlPath = "api.coloader.inboxgear.com";
export const apiUrlPath = "api.coloader.inboxgear.com";
// export const apiUrlPath = "api.co-loader.com";
// const apiUrlPath = "api.integrators.release.fmc-dev.com"; // pre-release
// const apiUrlPath = "api.precoloader.iage.site"; // pre-release-client

// const clientUrl = "http://localhost:3001/";
// const clientUrl = "https://dev.coloader.inboxgear.com/";
// const clientUrl = "https://co-loader.com/";
const clientUrl = "https://coloader.inboxgear.com/";
// const clientUrl = "https://integrators.fmc-dev.com/";
// const clientUrl = "https://integrators.release.fmc-dev.com/"; // pre-release
// const clientUrl = "https://precoloader.iage.site/"; // pre-release-client
//
export const CONSTS = {
  API: {
    // url: `https://${apiUrlPath}/api/`,
    url: `https://${apiUrlPath}/api/`,
  },
  IMG: {
    // url: `https://${apiUrlPath}/`,
    url: `https://${apiUrlPath}/`,
  },
  clientUrl: clientUrl,
};
