import React, { FC } from 'react';
import {connect} from "react-redux";

// components
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "../../../organisms/Menu";
import Header from "../../../organisms/Header";

// styles
import './style.scss';

type MainTemplateType = {
    children: React.ReactNode;
    isValidToken: boolean;
}

const MainTemplate: FC<MainTemplateType> = (props: MainTemplateType) => {
    const component = props.isValidToken ?
        props.children :
        <div className="loader-wrapper-g">
            <CircularProgress color="primary"/>
        </div>;

    return (
        <>
            <div className="wrapper">
                <Header />
                <Menu>
                    {component}
                </Menu>
            </div>
        </>
    );
};

const mapStateToProps = function(state: any) {
    return {
        isValidToken: state.app.isValidToken
    };
};

export default connect(mapStateToProps)(MainTemplate);
