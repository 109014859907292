import React, { useState, FC } from "react";
import { connect } from "react-redux";
import { orderDataType } from "../../../../types/actions/orders";

// Components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import QuoteSummary from "../../shipmentInfoTab/QuoteSummary";
import Contact from "../../shipmentInfoTab/Contact";
import AmazonFbaBooking from "../../shipmentInfoTab/AmazonFbaBooking";
import Shipment from "../../shipmentInfoTab/Shipment";
import Shipper from "../../shipmentInfoTab/Shipper";
import AdditionalInformation from "../../shipmentInfoTab/AdditionalInformation";
import BookingConfirmation from "../../shipmentInfoTab/BookingСonfirmation";

// Styles
import "./style.scss";

type shipmentInfoPropsType = {
  orderData: orderDataType;
};

const ShipmentInfo: FC<any> = (props: shipmentInfoPropsType) => {
  const [tabValue, setTabValue] = useState(0);

  const changeTabValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div id="shipment-info">
      <Tabs value={tabValue} orientation="vertical" onChange={changeTabValue}>
        <Tab label="QUOTE SUMMARY" />
        <Tab label="Сontact" />
        <Tab label="SHIPMENT" />
        <Tab label="SHIPPER" />
        <Tab label="AMAZON FBA BOOKING" />
        <Tab label="Additional information or request" />
        <Tab label="Booking Сonfirmation" />
      </Tabs>
      {tabValue === 0 && <QuoteSummary />}
      {tabValue === 1 && <Contact />}
      {tabValue === 2 && <Shipment />}
      {tabValue === 3 && <Shipper />}
      {tabValue === 4 && <AmazonFbaBooking />}
      {tabValue === 5 && <AdditionalInformation />}
      {tabValue === 6 && <BookingConfirmation />}
    </div>
  );
};

const mapStateToProps = function (state: any) {
  return {
    orderData: state.orders.orderData,
  };
};

export default connect(mapStateToProps)(ShipmentInfo);
